import type { XSdkSsoEventType } from '../../typings';
import type SsoSDK from '.';

export class SsoEvent extends Event {
  public type: XSdkSsoEventType;
  public target: SsoSDK;

  constructor(type: XSdkSsoEventType) {
    super(type);
    this.type = type;
  }
}
