import type { LoginOptions, XSdkSsoTokens } from '@hlx/sdk';
import { defineStore } from 'pinia';
import { computed, reactive, toRefs } from 'vue';
import { useProfileStore } from '@/storage/profile';
import { usePermitStore } from '@/storage/permits';
import { useSdk } from '@/storage/sso';

/**
 * Define the tokens storage definition
 */
export const useTokenStore = defineStore('tokens', () => {
  /**
   * Define tokens
   */
  const sdk = useSdk();
  const tokens = reactive(sdk.tokens);

  const authed = computed(() => !!tokens?.sysAccessToken);
  const flushable = computed(() => !!tokens?.sysRefreshToken);

  /**
   * 设置/保存授权和刷新令牌
   *
   * @param payload - 令牌信息
   */
  function save(payload: XSdkSsoTokens) {
    (sdk as any)._storeTokens(payload);
  }

  /**
   * 清除已保存到 Cookie 中的令牌
   */
  function clear() {
    useProfileStore().clear();
    usePermitStore().clear();
  }

  /**
   * 获取 access token 授权令牌
   *
   * @param options - 认证信息
   */
  async function oauth(options?: LoginOptions) {
    await sdk.login({ type: 'redirect', ...options });
  }

  /**
   * TODO：注销授权
   */
  async function deauth(options?: LoginOptions) {
    useProfileStore().clear();
    usePermitStore().clear();
    await sdk.logout({ type: 'redirect', ...options });
  }

  /**
   * 通过刷新令牌，刷新授权令牌
   */
  async function flush() {
    const result = await sdk.flush();
    if (result) save(result);
    return result || undefined;
  }

  sdk.addEventListener('authed', () => {
    tokens.sysAccessToken = sdk.tokens.sysAccessToken;
    tokens.sysRefreshToken = sdk.tokens.sysRefreshToken;
    tokens.autoRefreshToken = sdk.tokens.autoRefreshToken;
  });
  sdk.addEventListener('outdated', () => {
    tokens.sysAccessToken = '';
    tokens.sysRefreshToken = '';
    tokens.autoRefreshToken = false;
  });

  return { ...toRefs(tokens), authed, flushable, save, oauth, deauth, flush, clear };
});
