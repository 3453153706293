import { UAParser } from 'ua-parser-js';

/**
 * 检查当前打开的浏览器是否有潜在的不兼容性，若存在此情况，则跳转到 /bad-browser.html 页面
 */
(function useTrulyBrowser() {
  function goToBadBrowserTip() {
    window.location.href = '/bad-browser.html';
  }

  const cnTags = [
    'qihu', // 奇虎 360
    '360', // 奇虎 360
    'tencent', // 腾讯
    'qqbrowser', // 腾讯
    'sougou', // 搜狗
    'metasr', // 搜狗
    'souhu', // 搜狐
    'ubrowser', // uc
    'ucweb', // uc
    'maxthon', // 遨游
    'theworld',
  ];
  if (new RegExp(`(${cnTags.join('|')})`, 'gi').test(navigator.userAgent)) {
    return goToBadBrowserTip();
  }

  const ua_ = new UAParser();
  const browser_ = ua_.getBrowser().name?.toString().toLowerCase();
  const major_ = Number(ua_.getBrowser().major || null);

  if (!browser_ || !major_ || Number.isNaN(major_)) {
    // 非有效 ua 信息
    return goToBadBrowserTip();
  }

  if (/(chrome|edge)/.test(browser_)) {
    // 谷歌内核
    return major_ < 90 ? goToBadBrowserTip() : void 0;
  }

  if (/safari/.test(browser_)) {
    // safari 浏览器
    return major_ < 14 ? goToBadBrowserTip() : void 0;
  }

  if (/firefox/.test(browser_)) {
    // 火狐浏览器
    return major_ < 77 ? goToBadBrowserTip() : void 0;
  }

  // 其它情况
  return goToBadBrowserTip();
})();
