import type {
  XSdkNotifyConstructorOptions,
  XSdkNotifyData,
  XSdkEnv,
  XSdkNotifyLocalData,
  XSdkNotifyRemoteData,
  XSdkNotifyEventSourceEventMap,
} from '../../typings';
import { ENV_ALIAS_MAP, ENV_URLS_MAP } from '../const';
import { EventSource } from 'eventsource';
import type { FetchLikeInit } from 'eventsource';
import { omit, uid } from 'radash';

function parseApi(env: XSdkEnv) {
  const prefix = ENV_URLS_MAP[env];
  return `${prefix.api}/notify-center-service/notify/connect?notifyChannel=WEB`;
}

function useFetchLike(token: () => string, url: string | URL, init: FetchLikeInit) {
  const headers: Record<string, string> = {};
  const _token = token?.() ?? '';
  headers['Authorization'] = _token.startsWith('bearer ') ? _token : `bearer ${_token}`;
  return fetch(url, {
    ...init,
    headers: {
      ...omit(init.headers, ['last-event-id', 'Last-Event-Id']),
      ...headers,
    },
  });
}

const INIT_SINGAL = 'NOTIFY_BROADCAST_CHANNEL_INIT';
const EXCLUEDE_SINGAL = 'NOTIFY_BROADCAST_CHANNEL_EXCLUDE';
const DEAD_SINGAL = 'NOTIFY_SSE_RECEIVER_DEAD';

const NOTIFY_SINGAL_LIST = new Set([INIT_SINGAL, EXCLUEDE_SINGAL, DEAD_SINGAL]);

class NotifySDK extends EventTarget {
  private readonly _env: XSdkEnv;
  private readonly _token: () => string;
  private readonly _freshToken: () => Promise<void>;
  private readonly _eventFilter: (str: string) => boolean = (str) => ['HEARTBEAT'].includes(str);

  /**
   * 实例ID
   */
  private readonly _uid = uid(16);

  /**
   * 初始化时间
   *
   * @defaultValue Date.now()
   */
  private _time = Date.now();

  /**
   * 此 sdk 实例是否已经参与竞选收发实例
   */
  private _elected = false;
  /**
   * SSE 连接等待 clearTimeout 的 id
   */
  private _delay: number;

  /**
   * 此 sdk 实例是否为消息收发实例
   */
  private _isReceiver: boolean | undefined;
  /**
   * Eventsource
   */
  private _eventsource: EventSource;
  /**
   * BroadcastChannel
   */
  private _broadcastChannel: BroadcastChannel;

  constructor(options: XSdkNotifyConstructorOptions) {
    super();
    this._env = ENV_ALIAS_MAP[options.env];

    if (options.token) {
      this._token = options.token;
    }

    if (options.freshToken) {
      this._freshToken = options.freshToken;
    }

    if (options.eventFilter) {
      if (typeof options.eventFilter === 'function') {
        this._eventFilter = options.eventFilter;
      } else if (Array.isArray(options.eventFilter)) {
        this._eventFilter = (str: string) => (options.eventFilter as string[]).includes(str);
      }
    }

    // 接收来自其他窗口的广播
    this._broadcastChannel = new BroadcastChannel('HLX_NOTIFY_BROADCAST_CHANNEL');
    this._broadcastChannel.addEventListener('message', (evt: MessageEvent<XSdkNotifyLocalData>) => {
      const { type, uid, data } = evt.data;
      if (NOTIFY_SINGAL_LIST.has(type) && uid) {
        this._localHandler(evt.data);
      } else {
        this.dispatchEvent(new MessageEvent(type, { data: data, lastEventId: data.id }));
      }
    });

    this._init();

    window.addEventListener('beforeunload', () => {
      //  SSE连接窗口关闭的时候需要要发出信号
      if (this._isReceiver) {
        this._broadcastChannel.postMessage({ type: DEAD_SINGAL, uid: this._uid });
        this._closeConnect();
      }
      this._broadcastChannel.close();
    });
  }

  /**
   * 发起竞选信号
   */
  private _init() {
    if (!this._elected) {
      this._broadcastChannel.postMessage({
        type: INIT_SINGAL,
        uid: this._uid,
        data: this._time,
      });
      this._connectWait();
    }
  }

  private _resetInit() {
    this._elected = false;
    this._isReceiver = false;
    this._init();
  }

  private _localHandler({ type, uid, data }: XSdkNotifyLocalData) {
    switch (type) {
      case INIT_SINGAL: {
        if (this._elected) {
          return;
        }
        this._connectWait();
        this._compare(data as unknown as number, uid);
        break;
      }
      case EXCLUEDE_SINGAL: {
        if (this._elected) {
          return;
        }
        this._exclude(uid, data as unknown as number);
        break;
      }
      case DEAD_SINGAL: {
        this._resetInit();
        break;
      }
    }
  }
  /**
   * 毕业
   */
  private _exclude(uid: string, otherSDKInitTime: number) {
    if (this._uid === uid || this._time > otherSDKInitTime) {
      this._elected = true;
      this._isReceiver = false;
      this._delay && clearTimeout(this._delay);
    }
  }
  /**
   * 竞争上岗
   */
  private _compare(otherSDKInitTime: number, uid: string) {
    if (this._isReceiver) {
      // 排除广播来源SDK
      this._broadcastChannel.postMessage({
        type: EXCLUEDE_SINGAL,
        uid: uid,
        data: otherSDKInitTime,
      });
      return;
    }
    if (this._time < otherSDKInitTime) {
      // 排除广播来源SDK
      this._broadcastChannel.postMessage({
        type: EXCLUEDE_SINGAL,
        uid: uid,
        data: otherSDKInitTime,
      });
    } else if (this._time > otherSDKInitTime) {
      // 排除自己
      this._exclude(this._uid, 0);
    } else if (this._time === otherSDKInitTime) {
      this._time += Math.floor(Math.random() * 1000);
      this._resetInit();
    }
  }
  /**
   * brodcast 本地握手完成 与 SSE连接 之间的缓冲等待时间
   */
  private _connectWait() {
    if (this._isReceiver) {
      return;
    }
    this._delay && clearTimeout(this._delay);
    this._delay = window.setTimeout(() => {
      this._connect();
    }, 2000);
  }
  /**
   * brodcast 本地握手完成，准备SSE连接
   */
  private _connect() {
    this._isReceiver = true;
    this._eventsource = new EventSource(parseApi(this._env), {
      fetch: (url, init) => useFetchLike(this._token, url, init),
    });
    this._eventsource.addEventListener('open', () => {
      this.dispatchEvent(new Event('open'));
    });
    this._eventsource.addEventListener('error', async (e) => {
      if (e.code === 401 && this._freshToken) {
        try {
          await this._freshToken();
          setTimeout(() => {
            this._connect();
          }, 3000);
        } catch {
          this.dispatchEvent(new ErrorEvent('error', { message: e.message }));
        }
      } else {
        this.dispatchEvent(new ErrorEvent('error', { message: e.message }));
      }
    });
    this._eventsource.addEventListener('message', (evt: MessageEvent<string>) => {
      const remoteData = JSON.parse(evt.data) as XSdkNotifyRemoteData;
      const msgtype = remoteData.eventTypeCode || 'message';
      if (this._eventFilter?.(msgtype)) {
        return;
      }
      const data: XSdkNotifyData = {
        id: evt.lastEventId,
        ...remoteData,
        eventTypeCode: msgtype,
        eventSource: 'INTERFACE',
        eventOriginData: remoteData,
      };
      // 所有的消息都会触发 'message' 事件
      this.dispatchEvent(new MessageEvent('message', { data: data, lastEventId: data.id }));
      this._broadcastChannel.postMessage({
        type: 'message',
        data: { ...data, eventSource: 'BROADCAST' },
      });
      if (remoteData.eventTypeCode !== 'message') {
        this.dispatchEvent(new MessageEvent(msgtype, { data: data, lastEventId: data.id }));
        this._broadcastChannel.postMessage({
          type: msgtype,
          data: { ...data, eventSource: 'BROADCAST' },
        });
      }
    });
  }

  /**
   * 断开SSE连接
   */
  private _closeConnect() {
    if (this._eventsource && !this._eventsource.CLOSED) {
      this._eventsource.close();
    }
  }

  /**
   * 注册 SDK 的生命周期事件监听器
   *
   * @param type - 要监听的事件类型
   *
   * @param listener - 事件回调
   *
   * @param options - 监听器配置
   */
  override addEventListener<K extends keyof XSdkNotifyEventSourceEventMap>(
    type: K,
    listener: (this: NotifySDK, event: XSdkNotifyEventSourceEventMap[K]) => unknown,
    options?: boolean | AddEventListenerOptions,
  ): void;
  // eslint-disable-next-line no-dupe-class-members
  override addEventListener(
    type: 'message',
    listener: (this: NotifySDK, event: MessageEvent<XSdkNotifyData>) => unknown,
    options?: boolean | AddEventListenerOptions,
  ): void;
  // eslint-disable-next-line no-dupe-class-members
  override addEventListener(
    type: string,
    listener: (this: NotifySDK, event: MessageEvent<XSdkNotifyData>) => unknown,
    options?: boolean | AddEventListenerOptions,
  ): void;
  // eslint-disable-next-line no-dupe-class-members
  override addEventListener(
    type: string,
    listener:
      | ((this: NotifySDK, event: MessageEvent<XSdkNotifyData>) => unknown)
      | EventListenerOrEventListenerObject,
    options?: boolean | AddEventListenerOptions,
  ): void {
    const listen = listener as (this: EventSource, event: Event) => unknown;
    super.addEventListener(type, listen, options);
  }

  /**
   * 移除 SDK 的生命周期事件监听器
   *
   * @param type - 要监听的事件类型
   *
   * @param listener - 事件回调
   *
   * @param options - 监听器配置
   */
  override removeEventListener<K extends keyof XSdkNotifyEventSourceEventMap>(
    type: K,
    listener: (this: NotifySDK, event: XSdkNotifyEventSourceEventMap[K]) => unknown,
    options?: boolean | AddEventListenerOptions,
  ): void;
  // eslint-disable-next-line no-dupe-class-members
  override removeEventListener(
    type: 'message',
    listener: (this: NotifySDK, event: MessageEvent<XSdkNotifyData>) => unknown,
    options?: boolean | AddEventListenerOptions,
  ): void;
  // eslint-disable-next-line no-dupe-class-members
  override removeEventListener(
    type: string,
    listener: (this: NotifySDK, event: MessageEvent<XSdkNotifyData>) => unknown,
    options?: boolean | AddEventListenerOptions,
  ): void;
  // eslint-disable-next-line no-dupe-class-members
  override removeEventListener(
    type: string,
    listener:
      | ((this: NotifySDK, event: MessageEvent<XSdkNotifyData>) => unknown)
      | EventListenerOrEventListenerObject,
    options?: boolean | AddEventListenerOptions,
  ): void {
    const listen = listener as (this: EventSource, event: Event) => unknown;
    super.removeEventListener(type, listen, options);
  }

  public dispatchEvent(event: MessageEvent<XSdkNotifyData> | Event | ErrorEvent): boolean {
    return super.dispatchEvent(event as Event);
  }
}

export { NotifySDK };

export default NotifySDK;
