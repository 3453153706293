import type { XSdkEnv, XSdkEnvAlias } from '../typings';
import { TOKENS_COOKIE } from '@ome/bases';

export const ENV_URLS_MAP: Record<XSdkEnv, { sso: string; api: string }> = {
  native: {
    sso: '//localhlxomemain.cqyqdc.com:18080',
    api: '//devhlxgateway.cqyqdc.com',
  },
  development: {
    sso: '//devhlxsso.cqyqdc.com',
    api: '//devhlxgateway.cqyqdc.com',
  },
  testing: {
    sso: '//testhlxsso.cqyqdc.com',
    api: '//testhlxgateway.cqyqdc.com',
  },
  prerelease: {
    sso: '//prehlx.cqnews.net',
    api: '//prehlxapi.cqnews.net',
  },
  reserve: {
    sso: '//hlx2.cqnews.net',
    api: '//hlxapi2.cqnews.net',
  },
  zjproduction: {
    sso: '//cms.bauhk.com',
    api: '//gwapi.bauhk.com',
  },
  jiyuehua: {
    sso: '//23.99.13.145',
    api: '//23.99.13.145:8000',
    // api: '//23.99.13.145:8000',
  },
  production: {
    sso: '//hlx.cqnews.net',
    api: '//hlxapi.cqnews.net',
  },
};

export const ENV_ALIAS_MAP: Record<XSdkEnvAlias, XSdkEnv> = {
  local: 'native',
  native: 'native',
  dev: 'development',
  development: 'development',
  test: 'testing',
  testing: 'testing',
  pre: 'prerelease',
  prerelease: 'prerelease',
  disaster: 'reserve',
  recovery: 'reserve',
  reserve: 'reserve',
  prod: 'production',
  zjprod: 'zjproduction',
  zjproduction: 'zjproduction',
  production: 'production',
  jiyuehua: 'jiyuehua',
};

export const STORE_TOKEN_WITH_API_WRITTEN = 'user-tokens';

export const STORAGES_MAP = {
  local: localStorage,
  session: sessionStorage,
  cookie: {
    getItem: (key: string) => TOKENS_COOKIE.get(key),
    setItem: (key: string, value: string, expires?: Date) =>
      TOKENS_COOKIE.set(key, value, { expires }),
    removeItem: (key: string) => TOKENS_COOKIE.remove(key),
  },
};
