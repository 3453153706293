import type { ProSettableOption } from '@ome/components';
import type { VoFilthyProfiles, VoSiteProfile } from './typings';
import { API_SERVICE_SYS, SECRET_PARAMS } from '@ome/bases';
import { xhr } from '@/utils';
import { cleanupFilthyProfiles } from '@/apis/profile/utils';
import { enc, HmacSHA256 } from 'crypto-js';

/**
 * 登录后调用的第一个接口，拉取用户必要的配置信息
 */
export async function getProfiles() {
  const { data } = await xhr.get<VoFilthyProfiles>(`${API_SERVICE_SYS}/user/profiles`, {
    tooltip: false,
  });
  return cleanupFilthyProfiles(data);
}

export async function getFeaturesByDomain() {
  const params = { cmsDomain: location.host };
  // 随机字符串
  const nonce = Math.random().toString(36).slice(2).toString();
  // 毫秒时间戳
  const timestamp = Date.now().toString();
  const accessKey = SECRET_PARAMS;
  const secretParams =
    accessKey +
    nonce +
    timestamp +
    String(HmacSHA256(JSON.stringify(params), accessKey).toString()).toLowerCase();
  const signature = enc.Hex.stringify(HmacSHA256(secretParams, accessKey));
  const { data } = await xhr.get<VoSiteProfile[]>(`${API_SERVICE_SYS}/app/config/v2/cmsDomain`, {
    params: { cmsDomain: location.host, signature, nonce, timestamp },
    withoutToken: true,
    tooltip: false,
  });
  return data;
}

export async function putPreference(settings: ProSettableOption) {
  await xhr.post(
    `${API_SERVICE_SYS}/user_preference/save`,
    {
      preferenceKey: 'interactive_preference',
      preferenceValue: JSON.stringify(settings),
    },
    { tooltip: false, processor: false },
  );
}
