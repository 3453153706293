<script setup lang="ts">
import type { ProAvatarProps, ProAvatarSlots } from '../interfaces';
import { computed, ref } from 'vue';
import { pick } from 'radash';
import { bem } from '@hlx/use';
import { BEM_XOM_NS } from '@ome/bases';
import defaultMale from '@ome/assets/icons/xom-icon-male.svg';
import defaultFemale from '@ome/assets/icons/xom-icon-female.svg';

defineOptions({ name: 'XomProAvatar' });

const props = defineProps<ProAvatarProps>();
const emits = defineEmits<{ (evt: 'error'): void }>();
defineSlots<ProAvatarSlots>();

const ns = bem('pro-avatar', BEM_XOM_NS);

const isError = ref(false);
const avatarSrc = ref(props.src);

const antAvatarProps = computed(() => ({
  ...pick(props, ['size', 'alt', 'crossOrigin']),
  shape: props.shape || 'circle',
  src: avatarSrc.value,
  srcset: isError.value ? undefined : props.srcset,
}));

function enforceSrc() {
  isError.value = true;
  emits('error');
  return true;
}
</script>

<template>
  <a-avatar v-bind="antAvatarProps" :class="ns.b()" :load-error="enforceSrc">
    <template #icon>
      <slot name="avatar-placeholder">
        <component :is="gender === 'female' ? defaultFemale : defaultMale" />
      </slot>
    </template>

    {{ name }}
  </a-avatar>
</template>

<style scoped lang="scss">
@use '@ome/bases/styles/vars';
@use '@hlx/spec/mixins';

@include mixins.b(pro-avatar) {
  background-color: transparent;
}
</style>
