import type { VoApiResources } from '@/apis/permits/typings';
import dayjs from 'dayjs';
import { OPTS_MEMBER_TYPE } from '@ome/bases';

/**
 * 提取包含`name`字段的对象数据，用于展示
 */
export function joinDisplayNames<D extends BaseRelates>(data?: D | D[]): string {
  if (!data) {
    return '';
  }
  if (!Array.isArray(data)) {
    return data.name ?? '';
  }
  return data
    .filter((d) => d?.name)
    .map((d) => d.name)
    .join('、');
}

/**
 * 提取包含`label`字段的对象数据，用于展示
 */
export function joinDisplayLabels<D extends { label?: string }>(data?: D | D[]): string {
  if (!data) {
    return '';
  }
  if (!Array.isArray(data)) {
    return data?.label ?? '';
  }
  return data
    .filter((d) => !!d?.label)
    .map((d) => d.label)
    .join('、');
}

/**
 * 将`{ id, name }`数据格式转换为`{ value, label }`
 */
export function parseKeyNameToOption<D extends { key: string; name: string }>(
  data: D | D[],
): D extends { key: string; name: string } ? BaseOption : BaseOption[] {
  const _single = (d?: D) => (d?.key ? { value: d.key, label: d.name } : null);
  if (Array.isArray(data)) {
    return data.map(_single).filter((d) => !!d) as never;
  }
  return _single(data) as never;
}

/**
 * 将`{ value, label }`数据格式转换为`{ id, name }`
 */
export function parseOptionToRelate<D extends BaseOption>(
  data: D | D[],
): D extends BaseOption ? BaseRelates : BaseRelates[] {
  const _single = (o?: D) => (o?.value ? { id: o.value, name: o.label } : null);
  if (Array.isArray(data)) {
    return data.map(_single).filter((d) => !!d) as never;
  }
  return _single(data) as never;
}

export function parseApiDisplayName({ url, method, serviceName }: VoApiResources) {
  return `[${method?.toUpperCase()}]: /${serviceName}${url?.replace(/^\/?/, '/')}`;
}

export function parseApiToOption<T extends VoApiResources | VoApiResources[]>(
  data: T,
): T extends VoApiResources ? BaseOption : BaseOption[] {
  const _single = (d?: VoApiResources) =>
    d?.id ? { value: d.id, label: parseApiDisplayName(d) } : null;
  if (Array.isArray(data)) {
    return data.map(_single).filter((d) => !!d) as never;
  }
  return _single(data) as never;
}

/**
 * 时间戳数据格式字符串化，用于展示
 */
export function parseDate(timestamp: string | number, time?: boolean) {
  if (!timestamp || (typeof timestamp !== 'string' && typeof timestamp !== 'number')) {
    return '';
  }

  const str = timestamp.toString();
  let result = '';

  if (/^\d{13}$/.test(str)) {
    result = str;
  }
  if (/^\d{10}$/.test(str)) {
    result = `${str}000`;
  }

  if (!result) {
    return '';
  }

  // Numeric string must translate to pure Number before transfer to dayjs
  return dayjs(Number(result)).format(time ? 'YYYY-MM-DD HH:mm:ss' : 'YYYY-MM-DD');
}

export function parseMemberType(type: 'NORMAL' | 'SUPER' | 'SYSTEM' | null | undefined) {
  if (type === null || type === undefined) {
    return '错误账户';
  }
  return OPTS_MEMBER_TYPE.find((o) => o.value === type)?.label ?? '错误账户';
}
