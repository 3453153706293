<script setup lang="ts">
import type { ProMenuProps, ProMenuSlots } from '../interfaces';
import { bem } from '@hlx/use';
import { BEM_XOM_NS } from '@ome/bases';
import { useLayoutState } from '../composables';
import { XomProMenu } from '../pro-menu';
import { computed, h } from 'vue';

defineOptions({ name: 'XomProMenuDrawer' });

const open = defineModel<boolean>('open');
defineSlots<ProMenuSlots>();

const ns = bem('menu-drawer', BEM_XOM_NS);
const { isDark, menusSplitRoot, rootMenus, restMenus, menusPathFormatter } = useLayoutState();

const menuProps = computed<ProMenuProps>(() => ({
  direction: 'inline',
  menusPathFormatter,
  items: menusSplitRoot.value === 'sider' ? rootMenus.value : restMenus.value,
  onClick: () => (open.value = !open.value),
}));
</script>

<template>
  <a-button :class="ns.e('trigger')" shape="circle" @click="open = !open">
    <template #icon>
      <left-outlined v-if="open" />
      <right-outlined v-else />
    </template>
  </a-button>

  <a-drawer
    v-model:open="open"
    :class="[ns.b(), ns.is('dark', isDark)]"
    :placement="'left'"
    :closable="false"
    :width="300"
    :style="{ backgroundColor: 'var(--xom-color-bg-container)' }"
    :footer-style="{ padding: 'calc(var(--xom-padding-xxs) + 2px) var(--xom-padding-xxs)' }"
    :body-style="{ padding: 0 }"
    destroy-on-close
  >
    <template #footer>
      <a-button
        :class="[ns.e('trigger'), ns.when('inner')]"
        type="text"
        size="large"
        block
        @click="open = !open"
      >
        <template #icon>
          <left-outlined v-if="open" />
          <right-outlined v-else />
        </template>
      </a-button>
    </template>

    <x-scrollbar>
      <component :is="h(XomProMenu, menuProps, $slots)" />
    </x-scrollbar>
  </a-drawer>
</template>

<style scoped lang="scss">
@use '@ome/bases/styles/vars';
@use '@hlx/spec/mixins';

@include mixins.b(menu-drawer) {
  :deep(.#{vars.$ant-prefix}-drawer-footer) {
    padding: 0 var(--xom-padding-xxs) calc(var(--xom-padding-xxs) + 2px);
  }

  @include mixins.e(trigger) {
    position: absolute;
    top: calc(var(--xom-layout-header-height) - 16px);
    z-index: 1000;
    color: var(--xom-color-text-secondary);

    &:hover {
      color: var(--xom-color-text);
    }

    @include mixins.when(inner) {
      position: unset;
      width: 100%;
    }
  }
}
</style>
