import type { AxiosError } from 'axios';
import { computed, createVNode, reactive, toRefs } from 'vue';
import { getMyPermits, type VoPermitModule, type VoPermitSystem } from '@/apis/profile';
import {
  IS_DEV,
  IS_JYH_PROD,
  IS_RESERVE,
  STORE_PERMIT,
  SYS_MOUNT_POINT,
  useConfirm,
} from '@ome/bases';
import { defineStore } from 'pinia';
import { XomError } from '@/utils';
import { isEmpty, isMacroCase, isUrl } from '@hlx/use';
import { isArray, omit, pick } from 'radash';

const MICROS: XomStateSystem[] = [
  /**
   * 基座用户中心子系统注册配置
   */
  {
    key: 'USER_CENTER',
    activeRule: '/member',
    type: 'MAIN',
    container: SYS_MOUNT_POINT,
    label: '用户中心',
    entry: null,
  },
];

export const usePermitStore = defineStore(
  'permits',
  () => {
    const states = reactive<XomStatePermits>({
      matched: false,
      sites: [],
      systems: [],
      modules: [],
      features: [],
    });

    const authed = computed(() => !!states.sites?.length && !!states.systems?.length);

    /**
     * 标记权限已经完成校验，路由守卫的后续执行无需再请求权限
     */
    const markMatched = () => {
      states.matched = true;
    };

    /**
     * 配置某个子系统的开发入口
     *
     * @param payload - 子系统的权限点配置
     */
    const regDevEntry = (payload: VoPermitSystem) => {
      if (!IS_DEV) {
        return;
      }
      const oldIdx = states.systems.findIndex((s_) => s_.activeRule === payload.activeRule);
      const _old = states.systems[oldIdx];
      if (_old.type === 'MAIN') {
        return;
      }

      const parsed_ = parseVoToSystem({
        ...payload,
        path: _old ? _old.entry : payload.path,
        devEntry: payload.path,
        sort: _old?.sort || 9999,
      });
      if (~oldIdx) {
        states.systems.splice(oldIdx, 1, parsed_);
      } else {
        states.systems.push(parsed_);
      }
    };

    /**
     * 移除某个子系统的开发入口
     *
     * @param activeRule - 子系统的唯一标识
     */
    const rmvDevEntry = (activeRule: string) => {
      const idx = states.systems.findIndex((s_) => s_.activeRule === activeRule);
      if (~idx) {
        states.systems.splice(idx, 1, omit(states.systems[idx], ['devEntry']));
      }
    };

    /**
     * 刷新权限数据
     */
    const reload = async () => {
      let _res: Awaited<ReturnType<typeof getMyPermits>>;
      try {
        _res = await getMyPermits();
      } catch (error) {
        clear();
        throw new XomError(error as AxiosError);
      }
      const { pages, buttons: features, sites } = _res;

      if (!Array.isArray(sites) || sites.length === 0) {
        clear();
        throw new XomError({ code: 'err_invalid_profile' });
      }

      const systems = resolveSystems(_res.subSystems, states.systems);
      if (!systems.length || !pages.length) {
        clear();
        throw new XomError({ code: 'err_empty_permission' });
      }

      const modules = resolveModules(pages);
      systems.push(...MICROS);
      Object.assign(states, { sites, systems, modules, features });
    };

    const clear = () => {
      const systems = states.systems.filter((s) => !!s.devEntry);
      Object.assign(states, { matched: false, sites: [], systems, modules: [], features: [] });
    };

    return {
      ...toRefs(states),
      authed,
      reload,
      clear,
      markMatched,
      regDevEntry,
      rmvDevEntry,
    };
  },
  {
    persist: {
      key: STORE_PERMIT,
      pick: ['sites', 'systems', 'modules', 'features'],
    },
  },
);

function isSystemPermit<P extends VoPermitSystem>(payload: P): payload is P {
  const { key, path, activeRule, nodeName, systemType } = payload;
  if (!isMacroCase(key) || isEmpty(nodeName) || (systemType !== 'MAIN' && !isUrl(path))) {
    return false;
  }
  return systemType === 'EXTERNAL' || /^\/[\w-]+$/.test(activeRule);
}

function resolveModules(pages: VoPermitModule[]) {
  if (!isArray(pages) || !pages.length) {
    return [];
  }
  return pages.map(({ nodeName: label, pageType, isExternal, ...p }) => {
    const type = isExternal ? 'EXTERNAL' : pageType || 'SUBSYSTEM';
    return { ...p, type, label, sort: Number(p.sort) };
  });
}

function resolveSystems(vos: VoPermitSystem[], olds: XomStateSystem[]): XomStateSystem[] {
  if (!isArray(vos) || !vos.length) {
    return [];
  }

  const invalid: VoPermitSystem[] = [];
  const truly: XomStateSystem[] = [];

  for (const vo of vos) {
    const raw = parseVoToSystem(vo);
    const old = olds.find((o_) => o_.activeRule === raw.activeRule);
    if (old) {
      Object.assign(raw, { devEntry: old.devEntry });
    }
    if (raw) {
      truly.push(raw);
    } else {
      invalid.push(vo);
    }
  }

  if (invalid.length) {
    useSystemInvalidTip(invalid);
  }

  return truly;
}

/**
 * 将接口获取到的子系统配置转换为可渲染配置
 *
 * @param vo - 子系统值
 */
function parseVoToSystem(vo: VoPermitSystem): XomStateSystem {
  if (!isSystemPermit(vo)) {
    return;
  }
  if (!vo.devEntry && /^((https?:)\/{2}((?:\d+\.){3}\d+)(:\d+)?)/.test(vo.path) && !IS_JYH_PROD) {
    return;
  }

  const { nodeName: label } = vo;
  const type = vo.isExternal ? 'EXTERNAL' : vo.systemType || 'SUBSYSTEM';
  const container = `#${SYS_MOUNT_POINT}`;
  const entry = parsePrereleaseEntries(vo.path);

  return { ...pick(vo, ['key', 'activeRule', 'devEntry']), type, container, label, entry };
}

function useSystemInvalidTip(systems: VoPermitSystem[]) {
  const title = createVNode('p', null, `下列子系统含无效配置，它们将被基座忽略:`);
  const list = systems.map((info) =>
    createVNode(
      'span',
      { style: 'display: inline-block' },
      `${info.nodeName}(${info.key}@"${info.activeRule}"): ${info.path}`,
    ),
  );

  useConfirm('error', {
    title: '子系统配置无效',
    content: createVNode('div', null, [title, createVNode('p', null, list)]),
  });
}

/**
 * 预发布环境替换子系统入口 hlxmain.cqnews.net 为 hlxmain2.cqnews.net
 *
 * TODO: 搭建配置中心，移除此转换函数
 */
function parsePrereleaseEntries(url: string) {
  if (!IS_RESERVE) {
    return url;
  }
  return url.replace(/^(https?:\/\/)(hlx|hlxmain)(\.cqnews\.net\/sub-systems-.*)/, '$1$22$3');
}
