<script setup lang="ts">
import Notify from './notify.vue';

defineOptions({ name: 'XomNotify' });

const emits = defineEmits<{ (evt: 'close', visible?: boolean): void; (evt: 'ok'): void }>();
</script>

<template>
  <div class="xom-notify">
    <a-tabs centered>
      <a-tab-pane key="1" tab="未读消息列表">
        <notify @close="emits('close')" />
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<style scoped lang="scss">
@use '@ome/bases/styles/vars';

.xom-notify {
  width: 300px;
  height: 60vh;

  :deep(.#{vars.$ant-prefix}-tabs-nav) {
    margin: 0;
  }
}
</style>
