import type { ColorNeutralMapToken } from 'ant-design-vue/es/theme/interface/maps/colors';
import type { Ref } from 'vue';
import type { GlobalToken } from 'ant-design-vue/es/theme';
import type { ProLayoutProps } from '../interfaces';
import { pick } from 'radash';
import { kebabCase } from 'change-case';
import { useStyleTag, watchImmediate } from '@vueuse/core';
import { theme as antTheme } from 'ant-design-vue';
import { BEM_XOM_NS } from '@ome/bases';
import seedToken from 'ant-design-vue/es/theme/themes/seed';

export function useTokensInjector(props: ProLayoutProps, isDark: Ref<boolean>) {
  const { token: antTokens } = antTheme.useToken();
  const { css: cssVars } = useStyleTag('', { id: `__${BEM_XOM_NS}-themes-variables-injector__` });

  const writeCssVars = (tokens: GlobalToken) => {
    let base_ = `:root {\n${useTokensParser(tokens, props)}}\n`;
    if (isDark.value) {
      base_ += `:root { color-scheme: dark }`;
    } else {
      const mapping = antTheme.darkAlgorithm(seedToken);
      mapping.colorPrimary = tokens.colorPrimary;
      base_ += `.dark,.is-dark {\n${useTokensParser(mapping, props)}}`;
    }
    cssVars.value = base_;
  };

  watchImmediate(() => antTokens.value, writeCssVars, { deep: true });
}

/**
 * 根据 AntDesignVue 主题令牌生成 css 变量，并写入 dom 中
 *
 * @param tokens - AntV 公共主题令牌
 *
 * @param props - 组件选项，用于抓去用户配置中的扩展变量
 */
function useTokensParser(tokens: GlobalToken | ColorNeutralMapToken, props: ProLayoutProps) {
  // TODO: need responsive
  const layoutTokensKeys: (keyof ProLayoutProps)[] = [
    'headerHeight',
    'footerHeight',
    'sideWidth',
    'sideCollapsedWidth',
    'bodyFixedWidth',
  ];

  let val = '';
  for (const [_key, _val] of Object.entries({
    ...tokens,
    ...pick(props, layoutTokensKeys),
  })) {
    if (isParableToken(_key, _val) && _key !== 'Layout' && _key !== 'Menu') {
      const cssKey = (layoutTokensKeys as string[]).includes(_key)
        ? `--${BEM_XOM_NS}-layout-${kebabCase(_key)}`
        : `--${BEM_XOM_NS}-${kebabCase(_key)}`;
      val += `${cssKey}: ${isPixelToken(_key, _val) ? _val + 'px' : _val};\n`;
    }
  }
  return val;
}

function isParableToken(key: string, val: any) {
  return /^(?!(_|screen|size|motionbase|wirefame)).*/i.test(key) && typeof val !== 'boolean';
}
function isPixelToken(key: string, val: any) {
  return /(size|width|height|radius|margin|screen|padding)/gi.test(key) && typeof val === 'number';
}
