import type { AxiosError } from 'axios';
import { gotoException, gotoSignin } from './locations';
import { get } from 'radash';
import { XOM_ERRORS_DEFAULT_MSG, XOM_ERRORS_MSG_MAP, XOM_HTTP_CODE_MAPS } from '@ome/bases';
// import { signin } from '@/utils/signin';

/**
 * `XomError` 对象构造器
 */
export interface XomErrorOption {
  /** 错误代码 */
  code: XomErrorCode;

  /** 错误消息 */
  message?: string;
}

/**
 * 校验参数是不是一个 `XomError` 对象或其引用
 *
 * @param payload - 目标参数
 */
export function isXomError(payload: unknown): payload is XomError {
  if (payload === null || typeof payload !== 'object') {
    return false;
  }
  return 'isXomError' in payload && payload.isXomError === true;
}

/**
 * 校验参数是不是一个 `AxiosError` 对象或其引用
 *
 * @param payload - 目标参数
 */
export function isAxiosError(payload: unknown): payload is AxiosError {
  if (payload === null || typeof payload !== 'object') {
    return false;
  }
  return 'isAxiosError' in payload && payload.isAxiosError === true;
}

/**
 * 构建一个`XomError`实例，视情况进行页面跳转和实例返回
 *
 * @param type - exception: 抛出异常并跳转异常页; throw: 抛出异常; 返回异常实例
 *
 * @param payload - 实例选项或`AxiosError`实例
 */
export function useError<T extends 'generate' | 'throw' | 'exception' | 'signin'>(
  type: T,
  payload: XomErrorCode | XomErrorOption | AxiosError,
) {
  const options =
    typeof payload === 'string' || typeof payload === 'number' ? { code: payload } : payload;
  const error = new XomError(options as any);

  if (type === 'generate') {
    return error;
  }
  if (type === 'exception') {
    gotoException(error.code);
  } else if (type === 'signin') {
    gotoSignin();
    // signin();
  }
  throw error;
}

// /**
//  * 运行指定的可能抛出异常的代码，根据异常进行自动捕获和后续逻辑处理
//  *
//  * @param runner - 须要运行的代码
//  * @param onError - 捕获到异常后的处理
//  */
// export function useCatcher(runner: BaseFunc, onError?: 'throw' | 'exception') {}

/**
 * 根据参数转换一个`XomErrorCode`
 *
 * @param payload - 异常实例或错误码或其它东西
 */
export function useErrorCode(payload: unknown): XomErrorCode {
  if (!payload) {
    return 'err_not_found';
  }

  const get_ = (code_?: string) => {
    if (typeof code_ !== 'string') {
      return 'err_not_found';
    }
    const _ = code_.toLowerCase() as XomErrorCode;
    if (XOM_ERRORS_MSG_MAP.has(_)) {
      return _;
    }
    return XOM_HTTP_CODE_MAPS.get(_) || 'err_not_found';
  };

  if (typeof payload === 'string') {
    return get_(payload);
  }
  if (typeof payload !== 'object') {
    return 'err_not_found';
  }

  return get_(get<string>(payload, 'code'));
}

/**
 * 根据参数转换一个异常消息提示文本
 *
 * @param payload - 异常实例或错误消息或其它东西
 */
export function useErrorMsg(
  payload?: XomErrorOption | XomErrorCode | XomError | AxiosError,
): string {
  if (!payload) {
    return XOM_ERRORS_DEFAULT_MSG;
  }
  if (typeof payload === 'object' && 'message' in payload && typeof payload.message === 'string') {
    return payload.message;
  }
  if (typeof payload !== 'string') {
    return XOM_ERRORS_DEFAULT_MSG;
  }
  return XOM_ERRORS_MSG_MAP.get(payload) || XOM_ERRORS_DEFAULT_MSG;
}

/**
 * XomError 基座全局错误类，用于网络请求、Axios实例、权限数据、用户数据、网站配置数据、微前端子系统挂载等各种异常的处理
 */
export class XomError extends Error {
  /**
   * XomError 基座全局错误类构造器
   *
   * @param option - 基座全局错误代码等相关配置或`AxiosError`实例
   */
  constructor(option: XomErrorOption | AxiosError) {
    const code = useErrorCode(option);
    const message = useErrorMsg(option);
    super(message);

    this.code = code;
    this.name = 'XomError';
    this.isUnsignedError =
      code === 'err_unauthorized' ||
      code === 'err_empty_permission' ||
      code === 'err_invalid_permission';

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, this.constructor);
    } else {
      // eslint-disable-next-line unicorn/error-message
      this.stack = new Error().stack;
    }
  }

  /**
   * 当前错误代码
   */
  public readonly code: XomErrorCode = 'err_not_found';

  /**
   * 一个标记，标记当前错误代码是否表示当前登录的用户及其权限无效或已失效
   */
  public readonly isUnsignedError: boolean = false;

  /**
   * 一个标记，标记当前`Error`的实例是否是一个`XomError`实例
   */
  public readonly isXomError = true;

  /**
   * 校验参数是不是一个 `XomError` 对象或其引用
   *
   * @param payload - 目标参数
   */
  public static isXomError(payload: unknown): payload is XomError {
    return isXomError(payload);
  }
}
