import type { App } from 'vue';
import { createApp, h, reactive, render as vueRender } from 'vue';
import { globalConfigForApi } from 'ant-design-vue/es/config-provider';
import { ConfigProvider } from 'ant-design-vue';
import { default as signinModal } from '@/components/xom-signin-modal/index.vue';
import { BEM_ANT_NS } from '@ome/bases';

import { useTokenStore } from '@/storage/tokens';
import { useProfileStore } from '@/storage/profile';
import { usePermitStore } from '@/storage/permits';

export function useSignModal() {
  return new Promise<void>((resolve) => {
    const container = document.createDocumentFragment();
    const props = reactive({
      open: true,
      onDone: close,
    });
    let modal: App<Element> | null;

    const Wrapper = () => {
      const global = globalConfigForApi;
      const rootPrefixCls = global.prefixCls;
      const prefixCls = BEM_ANT_NS || `${rootPrefixCls}-modal`;
      const iconPrefixCls = global.iconPrefixCls;

      return h(ConfigProvider, { ...global, prefixCls: rootPrefixCls }, [
        h(signinModal, { ...props, rootPrefixCls, prefixCls, iconPrefixCls }),
      ]);
    };

    function destroy() {
      if (modal) {
        vueRender(null, container as any);
        modal.unmount();
        modal = null;
      }
    }

    function close() {
      Object.assign(props, { open: false, afterClose: () => destroy.apply(typeof close) });
      resolve();
    }

    function render() {
      return createApp(Wrapper, props);
    }

    modal = render();
    modal.mount(container as any);
  });
}

export async function signin() {
  await useTokenStore().oauth({
    originalUrl: `${location.origin}${location.pathname}`,
  });
}

export async function signout(url?: string) {
  try {
    await useTokenStore().deauth({
      originalUrl: url || `${location.origin}${location.pathname}`,
    });
  } catch {
    // TODO: 是否应该在捕获到异常的时候 清空 权限数据 和 profile
  } finally {
    debugger;
    await clearInfoToLogin();
  }
}

export async function clearInfoToLogin() {
  // const { pathname, search } = location;
  useTokenStore().clear();
  useProfileStore().clear();
  usePermitStore().clear();

  // NOTE: Qiankun 没有提供销毁的方法，在退出登录后，仍在工作，
  // 因此不能使用 vue-router 来处理登录页面的跳转
  // 必须得使用 `location.replace`，强制整个 HTML 重新渲染
  // location.replace(`/oauth/signin?redirect=${pathname + search}`);
  return signin();
}
