import type { Router } from 'vue-router';
import type { ProLayoutProps, ProMenusStates, ProRouteRaw, ProTabsOperate } from '../interfaces';
import { computed, ref } from 'vue';
import { useDebounceFn, useStorage, watchImmediate } from '@vueuse/core';

/**
 * TODO 状态数据全局化，提供 addMenu、removeMenu 等工具类函数
 */

/**
 * 菜单栏、标签栏数据初始化
 *
 * @param props - ProLayout 组件选项
 *
 * @param router - Router(vue-router) 实例
 */
export function useRouteStates(props: ProLayoutProps, router: Router): ProMenusStates {
  const { currentRoute: activeRoute } = router;

  const storage = props.storable === true ? localStorage : props.storable;
  const storageKey = props.storageKey?.tabs || 'xom_pro_layout_tabs';
  const homePath = computed(() => {
    const __ = props.homePath;
    return __ === false ? false : typeof __ === 'string' ? __ || '/' : '/';
  });

  // Note: Path '/a/b/c' to ['/a', '/a/b', '/a/b/c']
  const activePaths = computed(() => {
    const paths = activeRoute.value.fullPath.split('/').filter((p_) => !!p_);
    return paths.reduce((prev, p_) => [...prev, (prev.at(-1) || '') + '/' + p_], []);
  });

  const rootMenus = computed(() => props.menusData || []);
  const restMenus = computed(() => {
    return rootMenus.value.find((r_) => activePaths.value.includes(r_.path))?.children || [];
  });

  const innerTabs = storage
    ? useStorage<ProRouteRaw[]>(storageKey, [], storage)
    : ref<ProRouteRaw[]>([]);
  const cachedTabs = computed(() => innerTabs.value);

  function findMenu(menus: ProRouteRaw[], path: string): ProRouteRaw {
    for (const menu_ of menus) {
      if (menu_.path === path) {
        return menu_;
      }
      if (!menu_.children?.length) {
        continue;
      }
      const child_ = findMenu(menu_.children, path);
      if (child_) {
        return child_;
      }
    }
  }

  function forceTabRoute(path: string, idx: number, type: ProTabsOperate) {
    const __ = innerTabs.value;
    const current_ = router.currentRoute.value.path;
    if (type === 'closeSingle') {
      if (path !== current_) {
        return;
      }
      if (!~idx || (!homePath.value && __.length < 2)) {
        return router.replace('/');
      }
      return router.replace(__[idx === __.length - 1 ? idx - 1 : idx + 1].path);
    }
    if (path === current_) {
      return;
    }
    if (type === 'closeOthers') {
      return router.replace(path);
    }
    const currentIdx = __.findIndex((p_) => p_.path === path);
    if ((type === 'closeLeft' && idx > currentIdx) || (type === 'closeRight' && idx < currentIdx)) {
      return router.replace(path);
    }
  }

  const addTab = useDebounceFn(() => {
    const path = activeRoute.value.path;
    if (path === '' || path === '/' || path === homePath.value) {
      return;
    }
    if (innerTabs.value.some((t_) => t_.path === path)) {
      return;
    }
    const menu_ = findMenu(restMenus.value, path);
    if (!menu_) {
      return;
    }
    if (menu_.meta?.title && !menu_.meta?.hideInTabstrip) {
      innerTabs.value.push(menu_);
    }
  }, 50);

  const closeTab = (path: string) => {
    const idx = innerTabs.value.findIndex((t_) => t_.path === path);
    innerTabs.value.splice(idx, 1);
    if (path !== router.currentRoute.value.path) {
      return;
    }
  };

  const clearTabs = () => {
    innerTabs.value = [];
  };

  const handleTabs = async (operate: ProTabsOperate, path: string) => {
    if (operate === 'flush') {
      // const path = router.currentRoute.value.path;
      // return router.replace(`/redirect/${encodeURIComponent(path)}`);
      return router.go(0);
    }
    if (operate === 'switch') {
      return router.push(path);
    }
    const idx = innerTabs.value.findIndex((t_) => t_.path === path);
    await forceTabRoute(path, idx, operate);
    if (!~idx) {
      return;
    }
    if (operate === 'closeSingle') {
      return closeTab(path);
    }
    if (operate === 'closeOthers') {
      innerTabs.value = [innerTabs.value[idx]];
      return;
    }
    if (operate === 'closeLeft') {
      innerTabs.value.splice(0, idx);
    } else {
      innerTabs.value.splice(idx + 1);
    }
  };

  watchImmediate(() => activeRoute.value.path, addTab);
  watchImmediate(() => restMenus.value, addTab);

  return {
    homePath,
    activePaths,
    activeRoute,
    rootMenus,
    restMenus,
    cachedTabs,
    addTab,
    closeTab,
    handleTabs,
    clearTabs,
  };
}
