<script setup lang="ts">
import type { NotifyType, VoNotifyDetail } from '@/apis/notify/typings';
import BusinessModal from './business.vue';
import platformModal from './platform.vue';

defineOptions({ name: 'XomMessageModal' });

const open = defineModel<boolean>('open', { default: false });
defineProps<{
  type: NotifyType;
  msg: VoNotifyDetail;
}>();
</script>

<template>
  <business-modal v-if="type === 'BUSINESS'" v-model:open="open" :msg="msg" />
  <platform-modal v-if="type === 'SYSTEM'" v-model:open="open" :msg="msg" />
</template>
