<script setup lang="ts">
import { ref } from 'vue';
import { useLayoutState } from '@ome/components';
import { putPreference } from '@/apis/profile';

defineOptions({ name: 'XomProfileSaver' });

const { settings } = useLayoutState();
const settingsSubmitting = ref(false);
const status = ref<'ready' | 'failed' | 'succeed'>('ready');

async function savePreference() {
  try {
    settingsSubmitting.value = true;
    await putPreference({ ...settings });
    status.value = 'succeed';
  } catch {
    status.value = 'failed';
  } finally {
    setTimeout(() => (status.value = 'ready'), 3000);
    settingsSubmitting.value = false;
  }
}
</script>

<template>
  <div>
    <a-alert show-icon type="warning" style="margin-bottom: 1em">
      <template #icon>
        <notification-outlined />
      </template>
      <template #message>
        更改配置后也别忘了上传哦！上传后你的偏好不会因为更换电脑、清理缓存等原因丢失。
      </template>
    </a-alert>

    <a-button
      block
      :disabled="status !== 'ready'"
      :danger="status === 'failed'"
      :loading="settingsSubmitting"
      :style="
        status === 'succeed'
          ? { borderColor: 'var(--xom-color-success)', color: 'var(--xom-color-success)' }
          : {}
      "
      @click="savePreference"
    >
      <template #icon>
        <exclamation-circle-filled v-if="status === 'failed'" />
        <check-circle-filled v-else-if="status === 'succeed'" />
        <cloud-upload-outlined v-else />
      </template>
      {{ status === 'failed' ? '上传失败' : status === 'succeed' ? '上传成功' : '上传配置' }}
    </a-button>
  </div>
</template>
