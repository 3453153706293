<script setup lang="ts">
import { ROUTE_MOTIONS_PRESET, useLayoutState } from '../composables';
import { computed } from 'vue';
import { bem } from '@hlx/use';
import { BEM_XOM_NS } from '@ome/bases';

defineOptions({ name: 'XomProRouterView' });
defineProps<{ isNotRoute?: boolean }>();

const ns = bem('router-view', BEM_XOM_NS);

const { effective } = useLayoutState();

const motion = computed(() => {
  if (typeof effective.motion !== 'string' || !ROUTE_MOTIONS_PRESET.includes(effective.motion)) {
    return 'none';
  }
  return effective.motion;
});
</script>

<template>
  <router-view v-if="motion && motion !== 'none'" v-slot="{ Component }" :class="ns.b()">
    <transition appear :name="motion" mode="out-in">
      <component :is="Component" />
    </transition>
  </router-view>

  <router-view v-else :class="ns.b()" />
</template>
