import type { DtoSystem, VoSystems } from './typings';
import { useListVo, xhr } from '@/utils';
import { arraify } from '@hlx/use';
import { API_SERVICE_SYS } from '@ome/bases';

export async function getSystems(params: QoXList) {
  const { data } = await xhr.get<VoXList<VoSystems>>(`${API_SERVICE_SYS}/sub_system`, {
    params,
  });
  return useListVo(data);
}

// todo: 进行`角色`权限配置的时候，应该根据站点ID查询这个站点被赋予的系统列表
export async function getSystemOptions(params: { keyword?: string }) {
  const { data } = await xhr.get<VoXList<VoPermitRelate>>(
    `${API_SERVICE_SYS}/sub_system_select/v2`,
    {
      params: { ...params, size: 100 },
    },
  );
  return data.dataList ?? [];
}

export async function putSystemEcho(params: DtoSystem, id?: string) {
  return await xhr[id ? 'put' : 'post']<void>(
    `${API_SERVICE_SYS}/sub_system${id ? '/' + id : ''}`,
    params,
  );
}

export async function putSystemSort(newList: { id: string; sort: number }[]) {
  const data = newList.reduce(
    (previousValue, { id, sort }, index) => {
      const prevSort = Number(previousValue.at(-1)?.sort);
      const currSort = Number(sort);
      return [
        ...previousValue,
        Number.isNaN(prevSort)
          ? { id, sort: Number.isNaN(currSort) ? index + 1 : currSort }
          : { id, sort: prevSort + 1 },
      ];
    },
    [] as { id: string; sort: number }[],
  );

  return await xhr.put<void>(`${API_SERVICE_SYS}/sub_system/sort`, data);
}

export async function delSystems(ids: (string | number)[]) {
  return await xhr.delete<void>(`${API_SERVICE_SYS}/sub_system/${arraify(ids)}`);
}
