import './utils/check-browser';

import '@/styles';

import { createApp } from 'vue';
import { createPinia } from 'pinia';
import { createPersistedState } from 'pinia-plugin-persistedstate';
import { router } from './router';
import { IS_DEV, IS_TEST } from '@ome/bases';
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';

import XomApp from './app.vue';
dayjs.locale('zh-cn');

const app = createApp(XomApp);
const pinia = createPinia();
const persisted = createPersistedState({
  storage: localStorage,
  // todo: serializer and encodes
  debug: IS_DEV || IS_TEST,
});

pinia.use(persisted);
app.use(pinia).use(router).mount('#xom-apps-main');
app.config.performance = true;
