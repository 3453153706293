import type { FunctionalComponent } from 'vue';
import type { FormProps } from 'ant-design-vue';

/**
 * 粘贴板复制成功/失败的 Ant `MessageApi` 提示的 Key，以防一直点击时，页面出现过多提示
 */
export const TIPS_KEY_COPY = 'tips_copy_unique';

/**
 * 接口请求被中断时的 Ant `MessageApi` 提示的 Key，以防请求频繁时，页面出现过多提示
 */
export const TIPS_KEY_API_DISCONNECT = 'tips_api_disconnect_unique';

/**
 * 子系统加载失败时的 Ant `MessageApi` 提示的 Key，以防页面出现过多提示
 */
export const TIPS_KEY_SYS_LOAD = 'tips_sys_load_err';

/**
 * `AModal > AForm` 通用配置
 */
export const A_FORM_PROPS: FormProps = {
  labelCol: { span: 4 },
  wrapperCol: { span: 19 },
};

/**
 * AModal 宽度规范化别名
 */
export const A_MODAL_WIDTHS: Record<'xs' | 'sm' | 'md' | 'lg', number> = {
  xs: 416,
  sm: 520,
  md: 720,
  lg: 920,
};

/**
 * `@ant-design/icons-vue` 异步化加载
 */
export const A_ICONS_MODULES_MAP = Object.fromEntries(
  Object.entries(import.meta.glob?.('../node_modules/@ant-design/icons-vue/*.js') || []).map(
    ([path_, resolver_]) => [
      path_.replace(/.*\/(\w+).js/, '$1'),
      resolver_ as () => Promise<FunctionalComponent>,
    ],
  ),
);
