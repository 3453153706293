import JSEncrypt from 'jsencrypt';
import { ref } from 'vue';

const rsaPublicKey = ref<string>();
const RAS_ENCRYPT = new JSEncrypt();

export async function rsaEncrypt(
  str: string,
  publicKey: string | (() => string | Promise<string>),
): Promise<string> {
  if (!rsaPublicKey.value) {
    const _key = typeof publicKey === 'string' ? publicKey : await publicKey();
    RAS_ENCRYPT.setPublicKey(_key);
  }
  return RAS_ENCRYPT.encrypt(str) as string;
}
