import { API_BASE_URL, createXhr, IS_DEV, IS_TEST } from '@ome/bases';
import { pick } from 'radash';
import { useTokenStore } from '@/storage/tokens';
import ProgressBar from '@/components/xom-progress-bar';

if (IS_DEV || IS_TEST) {
  window.console.info(
    '%c%s%c%s',
    'background: #35495E; color: white; padding: 1px 6px;',
    'Api address at:',
    'background: #41B883; color: white; padding: 1px 6px;',
    `http:${API_BASE_URL}/doc.html`,
  );
}

export const xhr = createXhr({
  baseURL: API_BASE_URL,
  processor: ProgressBar,
  getTokens: () => pick(useTokenStore(), ['sysAccessToken', 'sysRefreshToken', 'autoRefreshToken']),
  refreshToken: () => useTokenStore().flush(),
});
