<script setup lang="ts">
import type { ProTabsItemProps, ProTabsSlots } from '../interfaces';
import { computed, h, inject } from 'vue';
import { PRO_TABS_CTX_TOKEN } from './context';
import { useLayoutState } from '../composables';
import XomProTabsActions from './actions.vue';

defineOptions({ name: 'XomProTabsItem' });

const props = defineProps<ProTabsItemProps>();

defineSlots<ProTabsSlots>();

const { ns, home, items } = inject(PRO_TABS_CTX_TOKEN)!;

const { isMobile, isPad, handleTabs } = useLayoutState();

const metas = computed(() => props.meta);
</script>

<template>
  <span v-if="isHome && home" :class="ns.e('title')">
    <span :class="ns.e('icon')">
      <slot name="tabs-home-icon">{{ h(metas?.icon) }}</slot>
    </span>
  </span>

  <xom-pro-tabs-actions v-else :trigger="'contextmenu'" :disabled="isMobile || isPad">
    <span :class="ns.e('title')">
      <slot name="tabs-title" :text="metas?.title">{{ metas?.title }}</slot>

      <span :class="ns.e('button')" @click.stop="() => handleTabs('flush', path)">
        <slot name="tabs-flush-icon">
          <reload-outlined />
        </slot>
      </span>

      <span
        v-if="items.length > 1"
        :class="ns.e('button')"
        @click.stop="() => handleTabs('closeSingle', path)"
      >
        <slot name="tabs-close-icon">
          <close-outlined />
        </slot>
      </span>
    </span>
  </xom-pro-tabs-actions>
</template>

<style scoped lang="scss">
@use '@ome/bases/styles/vars';
@use '@hlx/spec/mixins';

@include mixins.b(pro-tabs) {
  @include mixins.e(title) {
    display: inline-flex;
    gap: var(--xom-padding-xs);
    align-items: center;
    padding: 8px 16px;

    :deep(.#{vars.$ant-icon-prefix}) {
      margin: 0;
    }
  }

  @include mixins.e(button) {
    display: inline-block;
    width: 1em;
    height: 1em;
    font-size: var(--xom-font-size-sm);
    line-height: 1;
    color: var(--xom-color-text-secondary);

    &:hover {
      color: var(--xom-color-primary-hover);
    }
  }
}
</style>
