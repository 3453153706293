<script setup lang="ts">
import type { ProRouteRaw, ProTabsOperate, ProTabsProps, ProTabsSlots } from '../interfaces';
import type { ProTabsContext } from './context';
import { computed, h, provide } from 'vue';
import { bem } from '@hlx/use';
import { BEM_XOM_NS } from '@ome/bases';
import { useLayoutState } from '../composables';
import { PRO_TABS_CTX_TOKEN } from './context';
import XomProTabsItem from './item.vue';
import XomProTabsActions from './actions.vue';

defineOptions({ name: 'XomProTabs' });

const items = defineModel<ProRouteRaw[]>('items');
const activeTab = defineModel<string>('activeTab');
const props = defineProps<ProTabsProps>();
defineSlots<ProTabsSlots>();

const { isMobile, isPad, isDark, effective, activePaths, cachedTabs, handleTabs } =
  useLayoutState();

const ns = bem('pro-tabs', BEM_XOM_NS);

const theme = computed(() => {
  return props.theme || (isDark.value ? 'dark' : 'light');
});
const home = computed(() => {
  if (!props.homeTab) {
    return '';
  }
  return typeof props.homeTab === 'boolean' ? '/' : props.homeTab;
});
const tabsItems = computed(() => (props.items?.length ? props.items : cachedTabs.value));
const activeKey = computed(() => activeTab.value || activePaths.value.at(-1) || '/');

function operates(operate: ProTabsOperate, path: string) {
  handleTabs(operate, path);
  if (operate === 'switch') {
    // activeTab.value =
    return;
  }
  if (operate !== 'flush') {
    items.value = [...cachedTabs.value];
  }
}

provide<ProTabsContext>(PRO_TABS_CTX_TOKEN, {
  ns,
  home,
  activeTab: activeKey,
  items: tabsItems,
  operates,
});
</script>

<template>
  <div v-if="effective.fixedTabs" :class="[ns.b(), ns.when('fixed-holder')]" />

  <a-tabs
    :active-key="activeKey"
    :class="[
      ns.b(),
      ns.when(theme),
      ns.is('fixed', effective.fixedTabs),
      ns.is('sider-wrapped', effective.showSide),
      ns.is('sider-collapsed', effective.showSide && effective.sideCollapsed),
      ns.is('header-wrapped', effective.showHead),
    ]"
    :destroy-inactive-tab-pane="true"
    :type="'card'"
    @change="(key_: any) => operates('switch', key_.toString())"
  >
    <template v-if="isMobile || isPad" #rightExtra>
      <xom-pro-tabs-actions :trigger="'hover'">
        <a-button type="text">
          <template #icon><more-outlined /></template>
        </a-button>
      </xom-pro-tabs-actions>
    </template>

    <a-tab-pane v-if="home" :key="home">
      <template #tab>
        <component :is="h(XomProTabsItem, { isHome: true, path: home }, $slots)" />
      </template>
    </a-tab-pane>

    <a-tab-pane v-for="item of tabsItems" :key="item.path" :tab-key="item.path">
      <template #tab>
        <component :is="h(XomProTabsItem, item, $slots)" />
      </template>
    </a-tab-pane>
  </a-tabs>
</template>

<style scoped lang="scss">
@use '@ome/bases/styles/vars';
@use '@hlx/spec/mixins';

$tabs-wrapper-height: 54px;
$tabs-padding-top: 4px;
$tabs-padding-bottom: 12px;
$tabs-item-height: $tabs-wrapper-height - $tabs-padding-top - $tabs-padding-bottom;

@include mixins.b(pro-tabs) {
  justify-content: flex-end;
  height: $tabs-wrapper-height;
  padding-block: $tabs-padding-top $tabs-padding-bottom;
  background-color: var(--xom-color-bg-container);
  transition: width var(--xom-motion-duration-slow);

  :deep(.#{vars.$ant-prefix}-tabs-content-holder) {
    display: none;
  }

  :deep(.#{vars.$ant-prefix}-tabs-nav-list) {
    align-items: flex-end;
  }

  :deep(.#{vars.$ant-prefix}-tabs-nav) {
    // gap: var(--xom-margin-lg);
    align-items: flex-end;
    height: $tabs-item-height;
    padding-inline: var(--xom-padding-md);
    margin: 0;

    // &:before {
    //  content: none;
    // }
  }

  :deep(.#{vars.$ant-prefix}-tabs-tab) {
    height: $tabs-item-height;
    padding: 0;
    border-bottom-color: transparent;
    transition: color var(--xom-motion-duration-slow);

    // &.#{vars.$ant-prefix}-tabs-tab-active {
    //  background-color: var(--xom-color-primary-bg);
    //  border-color: var(--xom-color-primary-border);
    //  border-bottom-color: transparent;
    // }
  }

  :deep(.#{vars.$ant-prefix}-tabs-extra-content) {
    padding-bottom: 3px;
  }

  &.#{vars.$ant-prefix}-tabs-top {
    :deep(.#{vars.$ant-prefix}-tabs-tab + .#{vars.$ant-prefix}-tabs-tab) {
      margin-left: $tabs-padding-top;
    }
  }

  @include mixins.when(fixed-holder) {
    padding-block: 0 $tabs-wrapper-height;
    background-color: transparent;
    box-shadow: none;
  }

  @include mixins.m(card) {
    :deep(.#{vars.$ant-prefix}-tabs-nav) {
      height: $tabs-item-height;
    }
  }

  @include mixins.m(line) {
    :deep(.#{vars.$ant-prefix}-tabs-nav) {
      height: $tabs-wrapper-height;
    }
  }

  @include mixins.when(fixed) {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 15;
    width: 100%;
    box-shadow: var(--xom-box-shadow);

    @include mixins.when(sider-wrapped) {
      width: calc(100% - var(--xom-layout-side-width));

      @include mixins.when(sider-collapsed) {
        width: calc(100% - var(--xom-layout-side-collapsed-width));
      }
    }

    @include mixins.when(header-wrapped) {
      top: calc(var(--xom-layout-header-height) + 1px);
    }

    @include mixins.when(mix) {
      z-index: 16;
    }

    @include mixins.when(dark) {
      background-color: #161618;
      box-shadow: 0 3px 8px 0 rgba(13, 13, 13, 75%);
    }
  }
}
</style>
