import { ENV_NAME } from './env';

//
// 子系统、路由相关路由匹配规则和挂载节点

/**
 * 子系统挂载节点
 */
export const SYS_MOUNT_POINT = 'hlxSubSystems';

/**
 * 子系统内部路由校验名
 */
export const SYS_ROUTE_PATHS = 'routePath';
/**
 * 子系统内部路由校验名
 */
export const DEFAULT_TITLE_TEMPLATE =
  ENV_NAME === 'zjproduction' ? '{工作台}-融媒体中心' : '{融媒体中台} · 华龙芯';
