import { computed, ref } from 'vue';
import { useRouter } from 'vue-router';
import { defineStore } from 'pinia';
import { usePermitStore } from '@/storage/permits';
import { asyncRoutes } from '@/router/modules';
import { isArray } from 'radash';
import { isNotNil } from '@hlx/use';

type Micro = XomStateSystem;
type Module = XomStateModule;
type Route = XomRouteRaw;

export const useNavStore = defineStore(
  'navs',
  () => {
    const { currentRoute: activeRoute } = useRouter();

    const permits = usePermitStore();

    const menus = ref<Route[]>([]);

    const activeTop = computed(() => activeRoute.value.fullPath.replace(/(^\/[^/]+)(\/?.*)/, '$1'));
    const isActiveMain = computed(() =>
      permits.systems.some((s_) => s_.activeRule === activeTop.value && s_.type === 'MAIN'),
    );

    /**
     * 删除指定子系统或所有子系统的子菜单配置
     *
     * @param system - 指定的子系统
     */
    const clearMenus = (system?: Micro) => {
      for (const menu_ of menus.value) {
        if (!menu_.meta?.external && (!system || menu_.name === system.key)) {
          menu_.children = [];
        }
      }
    };
    /**
     * 删除菜单配置
     *
     */
    const emptyMenus = () => {
      menus.value = [];
    };

    /**
     * 根据提交的子系统配置注册根部路由菜单
     *
     * @param system - 子系统配置
     */
    const addMacroMenus = (system: Micro) => {
      const { type, activeRule, key } = system;
      const meta: XomRouteMetadata = {
        external: type === 'EXTERNAL',
        order: system.sort,
        title: system.label,
        devEntry: system.devEntry,
        entry: system.entry,
        hideInMenu: type === 'MAIN' || key === 'PREFERENCE',
      };
      const raw: Route = {
        path: meta.external ? system.entry : activeRule,
        name: system.key,
        meta,
      };

      if (type !== 'MAIN') {
        menus.value.push(raw);
        return;
      }
      const route_ = asyncRoutes.find(
        (r_) => r_.path.startsWith(activeRule) && !!r_.children?.length,
      );
      if (!route_) {
        return;
      }
      const children = parseNavs(permits.modules, activeRule, route_.children);
      if (children.length) {
        Object.assign(raw, { children });
      }
      menus.value.push(raw);
    };

    /**
     * 注册子系统提交的路由，路由规则必须为 `/^(https?:)?\/\//`
     *
     * @param routes - 子系统提交的路由
     *
     * @param asset - 子系统静态资源根路径
     */
    const addMicroMenus = (routes?: Route[], asset?: unknown) => {
      if (!routes?.length || !activeTop.value || !permits.modules?.length) {
        return;
      }

      const menu_ = menus.value.find((m) => m.path === activeTop.value);
      if (!menu_ || menu_.meta?.external || menu_.children?.length) {
        return;
      }
      const publicPath = typeof asset === 'string' ? asset : (menu_.meta?.entry as string) || '';
      const _ = parseNavs(permits.modules, activeTop.value, routes, publicPath);
      if (_?.length > 0) {
        menu_.children = _;
      }
    };

    return {
      isActiveMain,
      activeTop,
      menus,
      addMacroMenus,
      addMicroMenus,
      clearMenus,
      emptyMenus,
    };
  },
  {
    persist: false,
  },
);

/**
 * 解析子系统提交的路由
 *
 * @param permits - 路由权限列表
 *
 * @param root - 每个子系统独有的，基于乾坤`activeRule`的根部路径
 *
 * @param routes - 子系统提交的路由
 *
 * @param asset - 子系统静态资源路径
 */
function parseNavs(permits: Module[], root: string, routes?: Route[], asset?: string): Route[] {
  if (!isArray(routes) || !routes.length) {
    return [];
  }

  return routes.reduce<Route[]>((prev, raw) => {
    const basePath = raw.path;

    const name = raw.name?.toString();

    const meta: XomRouteMetadata = {
      ...raw.meta,
      external: !!raw.meta?.isExternal || false,
      icon: parseIcon(raw.meta?.icon, asset),
    };
    const path = basePath.replace(new RegExp(String.raw`^(${root})?(\/.*)`), `${root}$2`);

    const children = parseNavs(permits, root, raw.children, asset);
    if (children.length) {
      return [...prev, { name, path, meta, children }];
    }

    if (!name) {
      return prev;
    }

    const _permit = permits.find((m) => m.key === name);
    if (!meta.safe && !_permit) {
      return prev;
    }

    _permit &&
      Object.assign(meta, {
        order: Number(isNotNil(_permit?.sort) ? _permit.sort : meta.order),
        title: _permit?.label || meta.title,
        external: _permit?.type === 'EXTERNAL' || false, //以接口返回的页面是否外链优先
      });
    return [
      ...prev,
      { name, path: meta.external ? (_permit ? _permit.path : basePath) : path, meta },
    ];
  }, []);
}

function parseIcon<T>(icon: T, base: string | undefined) {
  if (!icon || typeof icon !== 'string' || !/\.(svg|jpe?g|png|bmp|ico|webp)$/.test(icon)) {
    return icon;
  }
  const baseURL = base?.replace(/\/$/, '') || '';
  return `${baseURL}/${icon.replace(/^\//, '')}`;
}
