<script setup lang="ts">
import type { ProSettingsDrawerSlots } from '../interfaces';
import { bem } from '@hlx/use';
import { BEM_XOM_NS } from '@ome/bases';
import XomProSettings from './setting.vue';

defineOptions({ name: 'XomProSettingDrawer' });

const open = defineModel<boolean>('open');

const props = defineProps<{ beforeClose?: () => any; afterClose?: () => any }>();

defineSlots<ProSettingsDrawerSlots>();

const ns = bem('pro-setting-drawer', BEM_XOM_NS);

const toggle = () => {
  if (!open.value) {
    open.value = true;
    return;
  }

  const _ = props.beforeClose?.();
  if (_ && _ instanceof Promise) {
    _.then(() => (open.value = false)).catch(() => (open.value = false));
  } else {
    open.value = false;
  }
};

const afterHook = () => {
  if (!open.value) {
    props.afterClose?.();
  }
};
</script>

<template>
  <div :class="ns.e('trigger')" @click="toggle">
    <close-outlined v-if="open" />
    <setting-outlined v-else />
  </div>

  <a-drawer
    v-model:open="open"
    :width="300"
    :placement="'right'"
    :closable="false"
    :mask-closable="!props.beforeClose"
    :class="ns.b"
    :style="{ backgroundColor: 'var(--xom-color-bg-container)' }"
    destroy-on-close
    @after-open-change="afterHook"
  >
    <!-- @click="(evt) => console.info(evt)" -->
    <template #handle>
      <div :class="[ns.e('trigger'), ns.em('trigger', 'inner')]" @click="toggle">
        <close-outlined v-if="open" />
        <setting-outlined v-else />
      </div>
    </template>

    <div v-if="$slots['setting-drawer-header']" :class="ns.e('header')">
      <slot name="setting-drawer-header" />
      <a-divider v-if="$slots['setting-drawer-header']" />
    </div>

    <xom-pro-settings />

    <div v-if="$slots['setting-drawer-footer']" :class="ns.e('footer')">
      <a-divider v-if="$slots['setting-drawer-footer']" />
      <slot name="setting-drawer-footer" />
    </div>
  </a-drawer>
</template>

<style scoped lang="scss">
@use '@ome/bases/styles/vars';
@use '@hlx/spec/mixins';

@include mixins.b(pro-setting-drawer) {
  @include mixins.e(trigger) {
    position: fixed;
    inset-block-start: 240px;
    inset-inline-end: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.4em;
    height: 2.4em;
    font-size: var(--xom-font-size-heading4);
    color: var(--xom-color-white);
    pointer-events: auto;
    cursor: pointer;
    background-color: var(--xom-color-primary);
    border-start-start-radius: var(--xom-border-radius);
    border-end-start-radius: var(--xom-border-radius);
    box-shadow: var(--xom-box-shadow-drawer-right);
    transition: background-color var(--xom-motion-duration-mid);

    &:hover {
      background-color: var(--xom-color-primary-hover);
    }

    @include mixins.m(inner) {
      position: absolute;
      top: 240px;
      right: 300px;
    }
  }

  @include mixins.e(header) {
    margin-bottom: var(--xom-margin-lg);
  }

  @include mixins.e(footer) {
    margin-bottom: var(--xom-margin-lg);
  }
}
</style>
