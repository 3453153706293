//
// CSS class BEM 规则配置

/**
 * "AntDesign" 命名空间，附加主要版本的版本号以隔离不同版本样式
 */
export const BEM_ANT_NS = 'av4';

/**
 * "HlxUis" 命名空间，附加主要版本的版本号以隔离不同版本样式
 */
export const BEM_UIS_NS = 'xv5';

/**
 * `Xom` 华龙芯微前端基座命名空间 (x-ome-main)
 */
export const BEM_XOM_NS = 'xom';

/**
 * "状态"命名空间
 */
export const BEM_STATUS = 'is';

/**
 * "块级"类名分隔符
 */
export const BEM_B_SEPARATOR = '-';

/**
 * "元素"类名分隔符
 */
export const BEM_E_SEPARATOR = '--';

/**
 * "修饰"类名分隔符
 */
export const BEM_M_SEPARATOR = '__';
