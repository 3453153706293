<script setup lang="ts">
import type { ProMenuItemSlots, ProRouteRaw } from '../interfaces';
import { computed, inject } from 'vue';
import { useLayoutState } from '../composables';
import XomProIcon from '../pro-icon/icon.vue';
import TopRight from '@ome/assets/icons/xom-icon-top-right.svg';
import { PRO_MENUS_CTX_TOKEN } from './context';

defineOptions({ name: 'XomProMenuItem' });

const props = defineProps<ProRouteRaw>();
defineSlots<ProMenuItemSlots>();

const { isDark } = useLayoutState();

const ctx = inject(PRO_MENUS_CTX_TOKEN)!;
const { direction } = ctx;

const parsePath = (path_: string) => {
  return ctx.menusPathFormatter?.(path_) || path_;
};

const metas = computed(() => {
  return props.meta || {};
});

const link_ = computed(() => {
  return parsePath(props.path);
});

const isExternal = computed(() => {
  return metas.value.external || /^(https?:)?\/\//.test(link_.value);
});
const filterHideChildren = computed(() => {
  return props.children?.filter((item) => !item.meta?.hideInMenu) || [];
});
</script>

<template>
  <template v-if="children?.length && !ctx.ignoreChildren">
    <a-sub-menu
      v-if="filterHideChildren.length"
      :key="link_"
      :popup-class-name="ctx.popupClass"
      :theme="ctx.submenuTheme || (isDark ? 'dark' : 'light')"
    >
      <template v-if="$slots['menu-expand-icon']" #expandIcon>
        <slot name="menu-expand-icon" v-bind="$props" />
      </template>

      <template v-if="metas?.icon" #icon>
        <slot name="menu-icon" v-bind="$props">
          <xom-pro-icon :icon="metas?.icon" />
        </slot>
      </template>

      <template #title>
        <slot name="menu-title" v-bind="$props">{{ metas?.title }}</slot>
      </template>

      <template v-for="(subs, idx) in children" :key="parsePath(subs.path) || idx">
        <xom-pro-menu-item v-if="!subs.meta?.hideInMenu" v-bind="subs" />
      </template>
    </a-sub-menu>
  </template>

  <template v-else>
    <a-menu-divider v-if="metas?.dividerBefore" />

    <a-menu-item :key="link_" @click="onClick">
      <template v-if="metas?.icon" #icon>
        <slot name="menu-icon" v-bind="$props">
          <xom-pro-icon :icon="metas?.icon" />
        </slot>
      </template>

      <template v-if="!link_">
        <slot name="menu-title" v-bind="$props">{{ metas?.title }} </slot>
        <slot v-if="metas.externalIcon" name="menu-external-icon" v-bind="$props">
          <xom-pro-icon
            :icon="metas.externalIcon || TopRight"
            :style="{
              marginLeft: direction === 'horizontal' ? 0 : '1em',
              color: 'var(--xom-color-text-tertiary)',
            }"
          />
        </slot>
      </template>

      <a v-else-if="isExternal" :href="link_" :target="metas?.target || '_blank'">
        <slot name="menu-title" v-bind="$props">{{ metas?.title }} </slot>
        <slot v-if="!ctx.hideExternalIcon" name="menu-external-icon" v-bind="$props">
          <xom-pro-icon
            :icon="ctx.externalIcon || TopRight"
            :style="{
              marginLeft: direction === 'horizontal' ? 0 : '1em',
              color: 'var(--xom-color-text-tertiary)',
            }"
          />
        </slot>
      </a>

      <router-link v-else :to="link_">
        <slot name="menu-title" v-bind="$props">{{ metas?.title }}</slot>
      </router-link>
    </a-menu-item>
  </template>
</template>
