import type { Bem } from '@hlx/use';
import type { ComputedRef, InjectionKey, Ref } from 'vue';
import type { ProRouteRaw, ProTabsOperate } from '../interfaces';

export interface ProTabsContext {
  ns: Bem;
  home: ComputedRef<string>;
  activeTab: Ref<string>;
  items: Ref<ProRouteRaw[]>;
  operates: (operate: ProTabsOperate, path: string) => any;
}

export const PRO_TABS_CTX_TOKEN: InjectionKey<ProTabsContext> = Symbol('__xom_pro_tabs_ctx');
