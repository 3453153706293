import type { AxiosInstance } from 'axios';
import type {
  LoginOptions,
  XSdkSsoAuthType,
  XSdkSsoConstructorOptions,
  XSdkEnv,
  XSdkSsoEventType,
  XSdkSsoTokenEncryptor,
  XSdkSsoTokens,
  XSdkSsoTokenStorageLike,
} from '../../typings';

import {
  isAccessToken,
  isSystemToken,
  createXhr,
  STORE_ACCESS_TOKEN,
  STORE_FLUSH_TOKEN,
  TOKENS_COOKIE,
  API_SERVICE_AUTH,
  API_OAUTH_BASES,
} from '@ome/bases';

import { isObject, isString, pick } from 'radash';
import { AES, SHA256, lib, enc, mode, pad } from 'crypto-js';
import { stringify as qsStringify } from 'qs';
import { STORE_TOKEN_WITH_API_WRITTEN, ENV_ALIAS_MAP, ENV_URLS_MAP, STORAGES_MAP } from '../const';
import { closeIcon } from '../assets';
import { SsoEvent } from './event';

/**
 * 华龙芯单点登录相关服务的开发工具包 (XOM SSO Development Kit)，用于辅助完成华龙芯全媒体采编微前端平台的基座及其衍生子系统项目的单点登录功能。
 * 内部集成功能如下：
 *
 * - 通过页面跳转完成系统登录
 * - 通过弹出浏览器独立窗口完成单点登录
 * - 通过弹出页面内嵌的 iframe 弹框完成单点登录
 * - 自带且可自定义的登录授权令牌的持久化存储和存储加密功能
 * - 遵循常规 html 文档流的事件循环机制
 * - 支持所有华龙芯平台现已部署的各种环境
 */
export default class SsoSDK extends EventTarget {
  /**
   * SDK 运行的上下文 window 环境
   *
   * @private
   */
  private readonly _global: Window;

  /**
   * 全局的登录方式定义
   *
   * @private
   */
  private readonly _type: XSdkSsoAuthType;

  /**
   * 持久化工具
   *
   * @private
   */
  private readonly _storage: XSdkSsoTokenStorageLike | null;

  /**
   * 持久化读写钥匙
   *
   * @private
   */
  private readonly _storageKey: string;
  private readonly _storageFlushKey: string | false;

  /**
   * 令牌加密工具
   *
   * @private
   */
  private readonly _encryptor: XSdkSsoTokenEncryptor | null;
  private readonly _encrypt_key: lib.WordArray;
  private readonly _encrypt_iv: lib.WordArray;

  // /**
  //  * 生命周期事件管理工具
  //  *
  //  * @private
  //  */
  // private readonly _eventTarget: EventTarget;

  /**
   * 向 body 插入的登录弹框元素，通过向弹框元素插入 `<iframe>` 标签，并嵌入华龙芯单点登录页面实现。
   *
   * @remarks 仅当 [{@link XSdkSsoConstructorOptions.type}='popup'] 或 [{@link LoginOptions.type}='popup'] 时存在。
   * 定义此变量的目的是为省略 dom 查找的步骤，提升效率
   *
   * @private
   */
  private _popupEl: HTMLElement | null;
  private _popupWindow: Window | null;

  /**
   * 10 位随机字符串，在向华龙芯单点登录前端服务页面获取登录成功的令牌数据时，用以校验当次登录的唯一性
   *
   * @private
   */
  private _generateKey!: string;

  /**
   * 华龙芯单点登录服务部署的各种环境的别名
   *
   * @private
   */
  private readonly _env: XSdkEnv = 'production';

  /**
   * 单点登录部署地址
   *
   * @private
   */
  private readonly _authAddress: string = '';

  /**
   * 当前已保存的 token 信息
   *
   * @private
   */
  private _tokens: XSdkSsoTokens | null;

  /**
   * 内部私有的，用于请求登录授权/令牌刷新的 XMLHttpRequest 实例
   *
   * fixme: 暂定策略，要使用 xhr，必须明确允许 sdk 直接调用后端接口，这牵涉单点登录的整个策略
   *
   * @private
   */
  private readonly _xhr: AxiosInstance;
  /**
   * 当前已刷新token的promise
   *
   * @private
   */
  private _freshTokenPromise: Promise<any> | null;

  constructor(options: XSdkSsoConstructorOptions = {}) {
    super();

    this._global = options.global || window;

    this._type = options.type || 'redirect';
    this._env = ENV_ALIAS_MAP[options.env || 'production'];
    this._xhr = createXhr({ baseURL: ENV_URLS_MAP[this._env].api });
    this._authAddress = ENV_URLS_MAP[this._env].sso;

    this._encryptor = options.encryptor || null;
    this._encrypt_key = SHA256('x_sso_sdk_encryptor_secret_key');
    this._encrypt_iv = lib.WordArray.random(16); // todo 此处不能直接随机，每次刷新的时候会造成token解析不到
    this._storageKey = options.storageKey || STORE_ACCESS_TOKEN;
    this._storageFlushKey =
      options.refreshTokenStorageKey === false || typeof options.refreshTokenStorageKey === 'string'
        ? options.refreshTokenStorageKey
        : STORE_FLUSH_TOKEN;

    let { storage: _storeType } = options;
    if (_storeType !== false && !(_storeType in STORAGES_MAP)) {
      _storeType = 'cookie';
    }
    if (_storeType !== false) {
      this._storage = STORAGES_MAP[_storeType];
    }

    this._initTokens(_storeType === 'cookie');
  }

  /**
   * 获取当前正在使用中的令牌数据
   */
  public get tokens() {
    return this._tokens ? { ...this._tokens } : null;
  }

  /**
   * 指示当前是否已获得一个有效的授权
   */
  public get authed() {
    return !!this.tokens?.sysAccessToken;
  }

  /**
   * 指示当前主动行为的刷新令牌是否可用
   */
  public get flushable() {
    return this.tokens?.autoRefreshToken !== true && !!this.tokens?.sysRefreshToken;
  }

  /**
   * 指示当前是否处于登录中状态，即弹出了登录弹框或打开了登录窗口。
   *
   * @remarks 仅在`[type="popup"]`或`[type="window"]`时有效，`[type="redirect"]`时，页面已离开，实例已销毁，内存已回收，进入了登录页面
   */
  public get authDoing() {
    return !!this._popupEl || !!this._popupWindow;
  }

  /**
   * 登录系统
   *
   * @param options - 登录方式等配置，配置详情查看 {@link LoginOptions}
   */
  public login(options?: LoginOptions) {
    const _type = options?.type || this._type;

    if (_type === 'popup') {
      return this._loginByPopup({ ...options, type: 'popup' }).then(this._storeTokens);
    }
    if (_type === 'window') {
      return this._loginByWindow({ ...options, type: 'window' }).then(this._storeTokens);
    }
    return this._loginByRedirect({ ...options, type: 'redirect' });
  }

  /**
   * TODO: 主动刷新授权令牌
   */
  public async flush() {
    if (!this.flushable) {
      this._storeTokens(null);
      // 刷新失败，清除所有授权信息，重新登录
      this.login();
      return null;
      // todo: 重定向登录页或打开登录弹框，具体怎么做，由消费端通过事件自己决定，或这里内置
    }

    try {
      if (!this._freshTokenPromise) {
        //确保并发进来的刷新token操作只会执行一次，在等待刷新token返回期间直接返回上一次的promise
        this._freshTokenPromise = this._xhr
          .post(
            `${API_SERVICE_AUTH}/oauth/token`,
            qsStringify({
              ...API_OAUTH_BASES,
              grant_type: 'refresh_token',
              refresh_token: this.tokens.sysRefreshToken,
            }),
            { withoutToken: true, refreshToken: false },
          )
          .then((res) => {
            const { data } = res;
            const {
              refresh_token: sysRefreshToken,
              expires_in: expiresIn,
              auto_refresh_token: autoRefreshToken,
            } = data as { refresh_token: string; expires_in?: number; auto_refresh_token: boolean };

            const sysAccessToken = `${data.token_type} ${data.access_token}`;
            this._setTokens({ sysAccessToken, sysRefreshToken, autoRefreshToken }, true);
            this._storeTokens({ sysAccessToken, sysRefreshToken, autoRefreshToken, expiresIn });
            this._setDefaultCookie({ sysAccessToken, sysRefreshToken, autoRefreshToken });
            this.dispatchEvent(new SsoEvent('authed'));
            return { sysAccessToken, sysRefreshToken, autoRefreshToken, expiresIn };
          });
      }
      const data = await this._freshTokenPromise;
      //用完即删，确保下一次的刷新操作能正常返回
      this._freshTokenPromise = null;
      return data;
    } catch {
      this._storeTokens(null);
      this._setDefaultCookie();
      this.login();
      return null;
    }
  }

  /**
   * 设置默认cookie
   *
   * @param options - 登录信息
   *
   * @param options.sysAccessToken - 登录token
   *
   * @param options.sysRefreshToken - 刷新token
   *
   * @param options.autoRefreshToken - 是否后端自动刷新
   */
  private _setDefaultCookie(options?: {
    sysAccessToken: string;
    sysRefreshToken: string;
    autoRefreshToken: boolean;
  }) {
    if (options) {
      const { sysAccessToken, sysRefreshToken, autoRefreshToken } = options;
      const _apiWrite: Record<string, any> = TOKENS_COOKIE.get(STORE_TOKEN_WITH_API_WRITTEN);
      const _expiresIn = this._parseExpires(30 * 24);
      TOKENS_COOKIE.set(
        STORE_TOKEN_WITH_API_WRITTEN,
        {
          ..._apiWrite,
          accessToken: sysAccessToken,
          refreshToken: sysRefreshToken,
          auto_refresh_token: autoRefreshToken,
        },
        { expires: _expiresIn },
      );
    } else {
      TOKENS_COOKIE.remove(STORE_TOKEN_WITH_API_WRITTEN);
    }
  }

  /**
   * 登出系统
   *
   * @param options - 登出方式等配置，配置详情查看 {@link LoginOptions}
   */
  public logout(options?: LoginOptions) {
    this._storage?.removeItem(this._storageKey);
    return this.login({ ...options, isLogout: 'true' });
  }

  /**
   * 注册 SDK 的生命周期事件监听器
   *
   * @param type - 要监听的事件类型
   *
   * @param callback - 事件回调
   *
   * @param options - 监听器配置
   */
  public addEventListener(
    type: XSdkSsoEventType,
    callback: EventListenerOrEventListenerObject | null,
    options?: AddEventListenerOptions | boolean,
  ) {
    super.addEventListener(type, callback, options);
  }

  /**
   * 移除 SDK 的生命周期事件监听器
   *
   * @param type - 要监听的事件类型
   *
   * @param callback - 事件回调
   *
   * @param options - 监听器配置
   */
  public removeEventListener(
    type: XSdkSsoEventType,
    callback: EventListenerOrEventListenerObject | null,
    options?: AddEventListenerOptions | boolean,
  ) {
    super.removeEventListener(type, callback, options);
  }

  public dispatchEvent(event: SsoEvent): boolean {
    return super.dispatchEvent(event);
  }

  /**
   * 令牌加密工具函数
   *
   * @param payload - 未加密的令牌数据
   *
   * @private
   */
  private _encrypt(payload: unknown): string | null {
    if (!this._isUsefulTokens(payload)) {
      return null;
    }
    const _custom = this._encryptor?.encrypt(payload);
    if (typeof _custom === 'string' && _custom) {
      return _custom;
    }

    const _str = JSON.stringify(payload);
    // const _hex = enc.Utf8.parse(_str);
    const _encrypted = AES.encrypt(_str, this._encrypt_key, {
      // iv: this._encrypt_iv,
      mode: mode.ECB,
      padding: pad.Pkcs7,
    });
    return enc.Base64.stringify(_encrypted.ciphertext);
  }

  /**
   * 令牌解密工具函数
   *
   * @param payload - 加密的令牌数据
   *
   * @private
   */
  private _decrypt(payload: unknown): XSdkSsoTokens | null {
    if (typeof payload !== 'string') {
      return null;
    }
    const _custom = this._encryptor?.decrypt(payload);
    if (this._isUsefulTokens(_custom)) {
      return _custom;
    }

    try {
      // const _hex = enc.Hex.parse(payload);
      // const _bytes = enc.Base64.str(payload);
      const _decrypted = AES.decrypt(payload, this._encrypt_key, {
        // iv: this._encrypt_iv,
        mode: mode.ECB,
        padding: pad.Pkcs7,
      });
      const _parsed = JSON.parse(enc.Utf8.stringify(_decrypted));
      return this._isUsefulTokens(_parsed) ? _parsed : null;
    } catch {
      return null;
    }
  }

  /**
   * 通过向单点登录页面进行路由重定向完成单点登录。
   *
   * @param option - 登录方式等配置
   *
   * @private
   */
  private _loginByRedirect(option: LoginOptions<'redirect'>) {
    // NOTE: 登录成功时，是由后端进行反向重定向并向 cookie 写入的一个名为`user-tokens`的数据，所以没有回调
    // 但需要在构造函数里面去检查这条 cookie 有的话需要即用即删
    this._global.location.replace(this._parseSsoUrl(option));
  }

  /**
   * 通过弹出「使用 iframe 嵌入单点登录页面的弹框元素」完成单点登录
   *
   * @param option - 登录方式等配置
   *
   * @private
   */
  private _loginByPopup(option: LoginOptions<'popup'>) {
    return new Promise<XSdkSsoTokens>((resolve) => {
      this._randomGenerateKey();

      this._renderPopup(this._parseSsoUrl(option));
      this._persistWindowMessage().then((data) => {
        this._closePopup();
        resolve(data);
      });
    });
  }

  /**
   * 通过弹出「路径为单点登录页面的浏览器独立窗口」完成单点登录
   *
   * @param option - 登录方式等配置
   *
   * @private
   */
  private _loginByWindow(option: LoginOptions<'window'>) {
    return new Promise<XSdkSsoTokens>((resolve) => {
      this._randomGenerateKey();

      const _screen = this._global.screen;
      const _windowFeatures = qsStringify({
        width: 500,
        height: 500,
        popup: 'yes',
        'z-look': 'yes',
        alwaysRaised: 'yes',
        location: 'no',
        top: _screen.height / 2 - 250 + (_screen.availTop || 0),
        left: _screen.width / 2 - 250 + (_screen.availLeft || 0),
      }).replace(/&/g, ',');

      this._popupWindow = this._global.open(this._parseSsoUrl(option), '_blank', _windowFeatures);
      this._persistWindowMessage().then((data) => {
        this._popupWindow.close();
        this._popupWindow = null;
        resolve(data);
      });
    });
  }

  /**
   * 获取单点登录页面回传的授权令牌
   *
   * @remarks 当 [{@link LoginOptions.type}='window'] 或 [{@link LoginOptions.type}='popup'] 时，嵌入/弹出的单点登录页面会使用 window.postMessage api 与 SDK 的消费页面进行授权令牌的传输通信
   *
   * @private
   */
  private _persistWindowMessage(): Promise<XSdkSsoTokens | null> {
    return new Promise((resolve) => {
      const _msgEvtCb = (event: MessageEvent) => {
        if (event.origin.replace(/^https?:/, '') !== this._authAddress) {
          return;
        }

        if (!this._isUsefulMessageData(event.data)) {
          this._global.removeEventListener('message', _msgEvtCb);
          return resolve(null);
        }

        try {
          const _parsed = JSON.parse(event.data.data);
          this._global.removeEventListener('message', _msgEvtCb);
          resolve(_parsed || null);
        } catch {
          this._global.removeEventListener('message', _msgEvtCb);
          resolve(null);
        }
      };

      this._global.addEventListener('message', _msgEvtCb);
    });
  }

  /**
   * 渲染 iframe 嵌入单点登录页面的弹框元素
   *
   * @param src - 单点登录地址
   *
   * @private
   */
  private _renderPopup(src: string) {
    this._popupEl = this._createDom('div', 'sdk-login-modal', {
      position: 'fixed',
      left: '0',
      top: '0',
      right: '0',
      bottom: '0',
      backgroundColor: 'rgba(0,0,0,0.5)',
      zIndex: '9999',
    });
    const content = this._createDom('div', 'sdk-login-modal-content', {
      width: '500px',
      height: '500px',
      position: 'relative',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: '#fff',
    });

    const close = this._createDom('img', 'sdk-login-close', {
      width: '20px',
      cursor: 'pointer',
      position: 'absolute',
      right: '10px',
      top: '10px',
    });
    close.src = closeIcon;
    close.addEventListener('click', () => {
      this.dispatchEvent(new SsoEvent('cancel'));
      this._closePopup();
      this.dispatchEvent(new SsoEvent('closed'));
    });

    const iframe = this._createDom('iframe', 'sdk-login-iframe', {
      width: '100%',
      height: '100%',
      overflow: 'hidden',
      border: 'none',
    });
    iframe.className = 'sdk-login-iframe';
    iframe.src = src;
    iframe.setAttribute('frameborder', '0');

    content.append(close);
    content.append(iframe);
    this._popupEl.append(content);
    document.body.append(this._popupEl);
  }

  /**
   * 关闭并从文档流中移除 iframe 嵌入单点登录页面的弹框元素
   *
   * @private
   */
  private _closePopup() {
    this._popupEl?.remove();
    this._popupEl = null;
  }

  /**
   * 创建一个 html 元素
   *
   * @param tagName - 元素名
   *
   * @param klass - 元素的样式类名
   *
   * @param style - 元素的样式
   *
   * @private
   */
  private _createDom<K extends keyof HTMLElementTagNameMap>(
    tagName: K,
    klass: string,
    style: Partial<CSSStyleDeclaration>,
  ): HTMLElementTagNameMap[K] {
    const el = document.createElement(tagName);
    el.className = klass;
    this._setDomStyle(el, style);
    return el;
  }

  private _setDomStyle = (el: HTMLElement, style: Partial<CSSStyleDeclaration>) => {
    for (const key in style) {
      el.style[key] = style[key] as string;
    }
  };

  /**
   * 随机生成一个SDK和单点登录页面的令牌交换密钥
   *
   * @private
   */
  private _randomGenerateKey() {
    this._generateKey = Math.random().toString(36).slice(2);
  }

  /**
   * 校验和转换一个有效的令牌过期时间
   *
   * @param hours - 预期需要的过期时间，单位：小时
   *
   * @private
   */
  private _parseExpires(hours: unknown) {
    const _ = typeof hours === 'number' ? hours : 30 * 24;
    return new Date(Date.now() + _ * 3600 * 1000);
  }

  /**
   * 判断目标参数是否是一个正常返回的，可解析为令牌数据的 postMessage api 的交互数据
   *
   * @param data - 要判断的数据
   *
   * @private
   */
  private _isUsefulMessageData(data: Record<string, any>) {
    return (
      isObject(data) &&
      data.type === 'jsonString' &&
      data.generateKey === this._generateKey &&
      isString(data.data)
    );
  }

  /**
   * 负责向单点登录服务 url 地址拼接 UrlSearchParams 的辅助函数
   *
   * @param options - 登录配置
   *
   * @private
   */
  private _parseSsoUrl(options: LoginOptions) {
    const _base = `${this._authAddress}/oauth/signin`;
    const _queries = pick(options, ['isLogout']);
    let redirect = this._global.location.href;

    if (options.type === 'redirect') {
      redirect = options.originalUrl || redirect;
    } else {
      Object.assign(_queries, { loginType: options.type, generateKey: this._generateKey });
    }

    Object.assign(_queries, { redirect: redirect });
    return `${_base}?${qsStringify(_queries)}`;
  }

  /**
   * 判断目标参数是否是一个有用的令牌数据
   *
   * @param payload - 要判断的数据
   *
   * @private
   */
  private _isUsefulTokens(payload: Record<string, any>): payload is XSdkSsoTokens {
    return (
      isObject(payload) &&
      isAccessToken(payload.sysAccessToken) &&
      (payload.autoRefreshToken === true || isSystemToken(payload.sysRefreshToken))
    );
  }

  /**
   * 设置`_tokens`私有变量
   *
   * @param payload - 新的令牌数据
   *
   * @param isDecrypted - 标识 payload 直接可用，无需解密
   *
   * @private
   */
  private _setTokens(payload: unknown, isDecrypted?: boolean) {
    if (this._isUsefulTokens(payload) && isDecrypted) {
      this._tokens = { ...payload };
      this.dispatchEvent(new SsoEvent('authed'));
      return;
    }

    if (typeof payload === 'string' && !isDecrypted) {
      this._tokens = this._decrypt(payload);
      this.dispatchEvent(new SsoEvent('authed'));
      return;
    }

    this._tokens = null;
    this.dispatchEvent(new SsoEvent('outdated'));
  }

  /**
   * 设置令牌的持久化数据
   *
   * @param payload - 新的令牌数据
   *
   * @returns true: 存储成功, false: 存储失败，参数不是一个有效的令牌数据
   *
   * @private
   */
  private _storeTokens(payload: unknown) {
    if (!payload || !isObject(payload)) {
      // TODO: 返回为一个非令牌数据，须做报错或重新进行登录等处理
      this._storage.removeItem(this._storageKey);
      return false;
    }

    const _expires = Number((<any>payload).expiresIn);
    const expiresIn = this._parseExpires(
      (<any>payload).autoRefreshToken === true
        ? 30 * 24
        : Number.isNaN(_expires)
          ? 24 * 30
          : _expires,
    );

    const encryptedTokenString = this._encrypt({ ...payload, expiresIn: expiresIn.getTime() });

    if (encryptedTokenString) {
      this._storage.setItem(this._storageKey, encryptedTokenString, expiresIn);
    }

    return true;
  }

  /**
   * 在实例初始化时对令牌数据进行初始化，因其未做加密处理，须用完即删，初始化过程如下：
   *
   * 1. 首先读取 cookie 有没有前端写入的 token，若有，直接使用，若无则进行下一步
   * 2. 获取后端向 cookie 写入的令牌数据，cookie 读取 key 为 `user-tokens`，若有，则以该数据为准初始化令牌，同时移除后端写入的 cookie
   *
   * @todo - 因后端向 cookie 写入的 token 中，key 和 value 均满足旧平台的需求，暂不删除，但旧平台完全迁移并彻底下线后，须用完即删
   *
   * @private
   */
  private _initTokens(isCookie?: boolean) {
    if (isCookie) {
      TOKENS_COOKIE.addChangeListener(({ name, value }) => {
        if (name === this._storageKey) {
          this._setTokens(value);
        }
      });
    }

    const _stored = this._storage?.getItem(this._storageKey);
    if (_stored) {
      this._setTokens(_stored);
      return;
    }

    const _apiWrite: Record<string, any> = TOKENS_COOKIE.get(STORE_TOKEN_WITH_API_WRITTEN);
    let _apiParsed: Record<string, any> = {};

    if (isObject(_apiWrite)) {
      _apiParsed = {
        sysAccessToken: _apiWrite.accessToken,
        sysRefreshToken: _apiWrite.refreshToken,
        autoRefreshToken:
          _apiWrite.autoRefreshToken === true ||
          _apiWrite.autoRefreshToken === 'true' ||
          !_apiWrite.refreshToken,
      };
    }

    if (this._storeTokens(_apiParsed) && !isCookie) {
      this._setTokens(_apiParsed, true);
      return;
    }

    // TODO: 旧平台完成迁移后，用完即删！
    // TOKENS_COOKIE.remove(COOKIE_KEY_WITH_API_WRITTEN);
  }
}
