<script setup lang="ts">
import { bem } from '@hlx/use';
import { BEM_XOM_NS } from '@ome/bases';

defineOptions({ name: 'XomProSettingGroup' });

defineProps<{ title?: string }>();

const ns = bem('pro-setting-group', BEM_XOM_NS);
</script>

<template>
  <div :class="ns.b()">
    <h4 v-if="title" :class="ns.e('title')">{{ title }}</h4>
    <div :class="ns.e('content')">
      <slot />
    </div>
  </div>
</template>

<style scoped lang="scss">
@use '@ome/bases/styles/vars';
@use '@hlx/spec/mixins';

@include mixins.b(pro-setting-group) {
  & {
    margin: 0 0 var(--xom-margin-lg);
    color: var(--xom-color-text);
  }

  & > :deep(h2),
  & > :deep(h3),
  & > :deep(h4),
  & > :deep(h5) {
    margin: 0 0 var(--xom-margin-sm);
  }
}
</style>
