import { default as Cookies } from 'universal-cookie';

/**
 * 目前华龙芯平台的 Token 规则
 */
export const TOKENS_RE = String.raw`[\dA-Za-z]{8}(-[\dA-Za-z]{4}){3}-[\dA-Za-z]{12}`;

/**
 * Define cookies handler instance for tokens by `universal-cookie`
 */
export const TOKENS_COOKIE = new Cookies(null, {
  secure: location.protocol === 'https:',
  domain: /^(?:\d+\.){3}\d+$/.test(window.location.hostname)
    ? undefined
    : window.location.hostname.replace(/^(.*\.)?(\w+\.\w+)$/, '.$2'),
  path: '/',
});
