import type { RouteLocationNormalized, Router } from 'vue-router';
import type { XomError } from '@/utils';
import { useTokenStore } from '@/storage/tokens';
import { useProfileStore } from '@/storage/profile';
import { usePermitStore } from '@/storage/permits';
import { isExceptionPath, isWhitePath, parseQueriesToken } from '@/utils';
import { SYS_ROUTE_PATHS } from '@ome/bases';
import { useNavStore } from '@/storage/nav';
import { unidentified } from '@/router/bases';
import { signin } from '@/utils/signin';
import { debounce } from 'radash';

type Route = RouteLocationNormalized;

/**
 * 基座系统的路由守卫，由 VueRouter 对象实例的 `beforeEach` 钩子管控
 *
 * @param router - VueRouter 对象实例
 *
 * @param to - 路由跳转目标
 *
 * @param from - 路由跳转来源
 *
 * @param dynamics - 异步路由信息
 */

export async function useRouterGuard(router: Router, to: Route, from: Route, dynamics: BaseFunc[]) {
  const tokens = useTokenStore();
  const profile = useProfileStore();
  const permits = usePermitStore();

  // TODO: 站外跳转携带的 token 使用 sdk 进行单点登录交换，不再直接保存
  // Note: 从站外跳转进来，携带已授权的 token 信息时，进行必要的校验和保存处理
  // Note: 携带的 token 信息字段名既定，由于微前端系统的复杂度，一经上线无法更改
  const queries = parseQueriesToken(to.query);
  if (queries.has) {
    if (queries.valid) {
      tokens.save(queries.tokens);
      return { ...to, query: queries.rest };
    } else {
      await tokens.deauth();
      return '/exception/err_unauthorized';
    }
  }
  if (!tokens.authed) {
    if (tokens.flushable) {
      await tokens.flush();
    } else {
      profile.clear();
      permits.clear();
      for (const routeRemoveSelf of dynamics) {
        routeRemoveSelf?.();
      }
      if (isWhitePath(to.path)) {
        return '/';
      } else if (isExceptionPath(to.path)) {
        return;
      } else {
        await signin();
      }
      // return isWhitePath(to.path) || isExceptionPath(to.path) ? undefined : '/oauth/signin';
    }
  }

  if (isWhitePath(to.path)) {
    return '/';
  }
  if (isExceptionPath(to.path)) {
    return;
  }

  try {
    if (!profile.authed) {
      await profile.reload();
    }
    if (!permits.authed) {
      await permits.reload();
    }
  } catch (error) {
    if ('isUnsignedError' in (error as any) && (error as any).isUnsignedError === true) {
      tokens.clear();
    }
    const code = (error as XomError).code;
    const isUnsignedError =
      code === 'err_unauthorized' ||
      code === 'err_empty_permission' ||
      code === 'err_invalid_permission';
    if (isUnsignedError) await tokens.flush();
    return `/exception/${(error as XomError).code}`;
  }

  if (!permits.matched && permits.systems.length) {
    addSystemRoutes(router, permits.systems, dynamics);
    permits.markMatched();
    return to.fullPath;
  }

  // if (isToPermitOut(to, permits)) {
  //   return `/exception/err_forbidden`;
  // }

  if (to.path === '' || to.path === '/' || to.meta?.safe === true) {
    return;
  }

  // TODO: systems、modules 长度过大会影响页面跳转性能
  const [, top, rest] = to.path.match(/(^\/[^/]+)(\/.*)?/);
  if (!permits.systems.some((s_) => s_.type !== 'EXTERNAL' && s_.activeRule === top)) {
    return '/exception/err_forbidden';
  }
  if (rest && rest !== '/' && !permits.modules.some((m_) => rest.startsWith(m_.path))) {
    return '/exception/err_forbidden';
  }

  cleanupBrowserHistory(to, from);
}

/**
 * 动态添加路由，仅局限于动态添加子系统入口路由
 *
 * @param router - 路由实例
 *
 * @param systems - 子系统配置列表
 *
 * @param dynamics - 动态路由缓存表，用于移除
 */
export function addSystemRoutes(router: Router, systems: XomStateSystem[], dynamics: BaseFunc[]) {
  const navs = useNavStore();
  let firstActiveRule: string = '';

  for (const system of systems) {
    if (system.type === 'EXTERNAL') {
      navs.addMacroMenus(system);
      continue;
    }
    const activeRule = system.activeRule?.replace?.(/^\/?(.*)\/?$/, '$1');
    if (!activeRule) {
      continue;
    }
    if (!firstActiveRule) {
      firstActiveRule = activeRule;
    }
    if (system.type !== 'MAIN') {
      const path = `/${activeRule}:${SYS_ROUTE_PATHS}(/?.*)`;
      const component = () => import('@/pages/manages/index.vue');
      dynamics.push(router.addRoute({ path, meta: { page: 'manage' }, component }));
    }
    navs.addMacroMenus(system);
  }

  if (firstActiveRule) {
    dynamics.unshift(
      router.addRoute({ path: '/', name: 'XomRouteRoot', redirect: `/${firstActiveRule}` }),
    );
  } /* TODO: 空的子系统权限表须要基座让`/`路径定位基座的首页 else {
    dynamics.unshift(router.addRoute({ path: '/', name: 'XomRouteRoot' }));
  } */

  dynamics.push(router.addRoute(unidentified));
}

/**
 * 清理浏览器历史记录中的无效路由，确保历史记录正常。此处为临时处理办法，仅能保证绝大部分历史记录正常
 *
 * todo: Remove me!
 *
 * @remarks 基座、子系统均为 vue3 + vue-router4，在基座中进行同一子系统下的路由跳转，会导致：
 *
 * `vue-router 丢失子系统的 activeRule，触发基座路由钩子，自动追加 activeRule 后再次重定向，导致浏览器历史记录混乱，前进/后退异常等等问题`
 *
 * @param to - 路由守卫当中的 to 参数
 *
 * @param from - 路由守卫当中的 from 参数
 *
 * @see https://github.com/umijs/qiankun/issues/1865#issuecomment-1652834410
 */
function cleanupBrowserHistory(to: Route, from: Route) {
  const paths = window.location.pathname.split('/');
  const historyState = { back: from.path, current: window.location.pathname };
  if (paths[1] === paths[2]) {
    const subPaths = paths.slice(2);
    let current = subPaths.join('/');
    current = current.startsWith('/') ? current : '/' + current;
    history.replaceState(historyState, to.name?.toString() || '', current);
  } else {
    history.replaceState(historyState, to.name?.toString() || '', '');
  }
}

/**
 * 基座系统的路由守卫，由 VueRouter 对象实例的 `afterEach` 钩子管控
 *
 * @param router - VueRouter 对象实例
 *
 * @param to - 路由跳转目标
 */

export const useAfterRouterGuard = debounce({ delay: 100 }, (to: Route) => {
  const profile = useProfileStore();
  profile.setTitle(to);
});
