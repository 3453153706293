<script setup lang="ts">
import { type CSSProperties, h } from 'vue';
import type { ProHeadSlots } from '../interfaces';
import { bem } from '@hlx/use';
import { BEM_XOM_NS } from '@ome/bases';
import { computed } from 'vue';
import { useLayoutState } from '../composables';
import { XomProBrand } from '../pro-brand';
import { XomProMenu } from '../pro-menu';

defineOptions({ name: 'XomProLayoutHeader', inheritAttrs: false });
defineSlots<ProHeadSlots>();

const {
  isMobile,
  sideCollapsedWidth,
  sideWidth,
  isDarkHead,
  menusSplitRoot,
  rootMenus,
  restMenus,
  effective,
  menusPathFormatter,
} = useLayoutState();

const ns = bem('layout-header', BEM_XOM_NS);

const styles = computed<CSSProperties>(() => {
  if (!effective.showSide || effective.layout === 'mix') {
    return;
  }
  const _basic: CSSProperties = { zIndex: 15 };
  if (!isMobile.value && effective.fixedHead) {
    const width = effective.sideCollapsed ? sideCollapsedWidth.value : sideWidth.value;
    Object.assign(_basic, { width: `calc(100% - ${width}px)` });
  }
  return _basic;
});
</script>

<template>
  <a-layout-header
    v-if="effective.fixedHead"
    :class="[ns.b(), ns.when('placeholder-only')]"
    :style="styles"
  />

  <a-layout-header
    :class="[
      ns.b(),
      ns.when(effective.layout),
      ns.is('fixed', effective.fixedHead),
      ns.is('tabs-wrapped', effective.fixedTabs && effective.showTabs),
      ns.is('dark', isDarkHead),
    ]"
    :style="styles"
  >
    <component
      :is="h(XomProBrand, null, $slots)"
      v-if="isMobile || !effective.showSide || effective.layout !== 'side'"
    />

    <div v-if="$slots['header-before']" :class="ns.e('before')">
      <slot name="header-before" />
    </div>

    <component
      :is="h(XomProMenu, null, $slots)"
      v-if="menusSplitRoot === 'header' || effective.layout === 'top'"
      :class="ns.e('menus')"
      :direction="'horizontal'"
      :menus-path-formatter="menusPathFormatter"
      :theme="isDarkHead ? 'dark' : 'light'"
      :items="effective.layout === 'top' || menusSplitRoot === 'header' ? rootMenus : restMenus"
      :ignore-children="effective.layout !== 'top'"
    />

    <div v-else :class="ns.e('content')">
      <slot name="header" />
    </div>

    <div v-if="$slots['header-after']" :class="ns.e('after')">
      <slot name="header-after" />
    </div>
  </a-layout-header>
</template>

<style scoped lang="scss">
@use '@ome/bases/styles/vars';
@use '@hlx/spec/mixins';

@include mixins.b(layout-header) {
  display: flex;
  align-items: center;
  height: calc(var(--xom-layout-header-height) + 1px);
  padding: 0;
  line-height: var(--xom-layout-header-height);

  // z-index: 16;
  background-color: var(--xom-color-bg-container);
  border-bottom: solid 1px var(--xom-color-border-secondary);
  transition: width var(--xom-motion-duration-slow);

  @include mixins.e(menus) {
    flex: 1;
    min-width: 0;
    height: 100%;
    padding-inline-end: var(--xom-padding-lg);

    &.#{vars.$ant-prefix}-menu {
      border-block-end: 0 none;
      border-inline-end: 0 none;
    }
  }

  @include mixins.e(content) {
    flex: 1;
    min-width: 0;
    height: 100%;
    padding-inline-end: var(--xom-padding-lg);
    border: 0 none;
  }

  @include mixins.e(after) {
    display: flex;
    align-items: center;
    height: 100%;
    padding-inline: var(--xom-padding-lg);
  }

  @include mixins.when(placeholder-only) {
    background-color: transparent;
    border-bottom-color: transparent;
  }

  @include mixins.when(fixed) {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 17;
    width: 100%;
    border-bottom-color: transparent;
    box-shadow: var(--xom-box-shadow);

    @include mixins.when(dark) {
      box-shadow: 0 3px 8px 0 rgba(13, 13, 13, 75%);
    }

    @include mixins.when(tabs-wrapped) {
      border-bottom-color: var(--xom-color-border-secondary);
      box-shadow: none;
    }
  }

  @include mixins.when(mix) {
    z-index: 18;
  }

  @include mixins.when(dark) {
    background-color: #161618;
  }
}
</style>
