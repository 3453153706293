<script setup lang="ts">
import type { ProTabsOperate } from '../interfaces';
import { computed, inject } from 'vue';
import { PRO_TABS_CTX_TOKEN } from './context';

type Context = {
  key: ProTabsOperate;
  label: string;
  divided?: boolean;
  disabled?: boolean;
};

defineOptions({ name: 'XomProTabsActions' });

defineProps<{ trigger?: 'hover' | 'contextmenu'; disabled?: boolean }>();

const { activeTab, items, operates } = inject(PRO_TABS_CTX_TOKEN)!;

const activeIdx = computed(() => items.value.findIndex((t_) => t_.path === activeTab.value));
const contexts = computed<Context[]>(() => [
  { key: 'closeSingle', label: '关闭当前页', disabled: items.value.length < 2 },
  { key: 'closeOthers', label: '关闭其它', disabled: items.value.length < 2 },
  { key: 'closeLeft', label: '关闭到左侧', disabled: activeIdx.value < 1 },
  { key: 'closeRight', label: '关闭到右侧', disabled: activeIdx.value === items.value.length - 1 },
  { key: 'flush', label: '重载当前页', divided: true },
]);
</script>

<template>
  <a-dropdown :trigger="[trigger]" :disabled="disabled">
    <slot />

    <template #overlay>
      <a-menu>
        <template v-for="ctx in contexts" :key="ctx.key">
          <a-menu-divider v-if="ctx.divided" />
          <a-menu-item :disabled="ctx.disabled" @click="() => operates(ctx.key, activeTab)">
            {{ ctx.label }}
          </a-menu-item>
        </template>
      </a-menu>
    </template>
  </a-dropdown>
</template>
