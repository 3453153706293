import { TOKENS_RE } from '../const';

/**
 * 判断目标值是不是一个有效的系统令牌
 *
 * @param payload - 目标值
 */
export function isSystemToken(payload: unknown): payload is string {
  if (typeof payload !== 'string') {
    return false;
  }
  return new RegExp(`^${TOKENS_RE}$`).test(payload);
}

/**
 * 判断目标值是不是一个授权令牌
 *
 * @param payload - 目标值
 */
export function isAccessToken(payload: unknown): payload is string {
  if (typeof payload !== 'string') {
    return false;
  }
  return new RegExp(`^bearer ${TOKENS_RE}$`).test(payload);
}

/**
 * 判断目标值是不是一个自动刷新值
 *
 * @param payload - 目标值
 */
export function isAutoRefresh(payload: unknown): payload is string {
  if (typeof payload !== 'string') {
    return false;
  }
  return payload === 'true';
}
