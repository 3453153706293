<script setup lang="ts">
import type { ActionOption } from '@hlx/uis';

const open = defineModel<boolean>('open', { default: false });

defineProps<{
  actions?: ActionOption[];
  title?: string;
  isEmpty?: boolean;
}>();
</script>

<template>
  <a-modal v-model:open="open" centered wrap-class-name="full-modal" width="100%" :footer="null">
    <div class="xom-msg-modal">
      <div class="xom-msg-modal-header">
        <div class="xom-msg-modal-header-title">
          <file-outlined style="margin-right: 10px; font-size: 18px" />
          {{ title }}
        </div>
        <div class="xom-msg-modal-header-tabs" />
        <div class="xom-msg-modal-header-actions">
          <x-actionbar :options="actions" />
          <a-button @click="open = false">关闭</a-button>
        </div>
      </div>

      <div class="xom-msg-modal-content">
        <div :class="['xom-msg-modal-content-body', { 'is-empty': isEmpty }]">
          <x-defaults v-if="isEmpty" />

          <template v-else>
            <div class="xom-msg-modal-content-col is-main">
              <a-card :class="['xom-msg-modal-content-panel']">
                <slot />
              </a-card>
            </div>
          </template>
        </div>
      </div>
    </div>
  </a-modal>
</template>

<style scoped lang="scss">
@use './details';
</style>

<style lang="scss">
@use './full-modal';
</style>
