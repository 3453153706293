/**
 * 键盘字符表(小写)
 * 非shift键盘字符表
 */
const charTable1 = [
  ['`', '1', '2', '3', '4', '5', '6', '7', '8', '9', '0', '-', '=', ''],
  ['', 'q', 'w', 'e', 'r', 't', 'y', 'u', 'i', 'o', 'p', '[', ']', '\\'],
  ['', 'a', 's', 'd', 'f', 'g', 'h', 'j', 'k', 'l', ';', "'", '', ''],
  ['', 'z', 'x', 'c', 'v', 'b', 'n', 'm', ',', '.', '/', '', '', ''],
];
/**
 * shift键盘的字符表
 */
const charTable2 = [
  ['~', '!', '@', '#', '$', '%', '^', '&', '*', '(', ')', '_', '+', ''],
  ['', 'q', 'w', 'e', 'r', 't', 'y', 'u', 'i', 'o', 'p', '{', '}', '|'],
  ['', 'a', 's', 'd', 'f', 'g', 'h', 'j', 'k', 'l', ':', '"', '', ''],
  ['', 'z', 'x', 'c', 'v', 'b', 'n', 'm', '<', '>', '?', '', '', ''],
];

// 所有键盘组合值
let charAll: string[] = [];

function getCharAll() {
  charAll = [];
  // 横向所有的值(正序和反序)-
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  for (const [index, item] of [...charTable1, ...charTable2].entries()) {
    charAll.push(item.join(''), Object.assign([], item).reverse().join(''));
  }

  // 反斜杠纵向所有的值(正序和反序)\
  for (let i = 0; i < charTable1[0].length; i++) {
    const sb1 = [];
    const sb2 = [];
    for (const item of charTable1) {
      sb1.push(item[i]);
    }
    for (const item of charTable2) {
      sb2.push(item[i]);
    }
    // 去除长度小于3的值
    sb1.join('').length < 3 ||
      charAll.push(sb1.join(''), Object.assign([], sb1).reverse().join(''));
    sb1.join('').length < 3 ||
      charAll.push(sb2.join(''), Object.assign([], sb2).reverse().join(''));
  }

  // 正斜杠纵向所有的值(正序和反序)/
  for (let i = 0; i < charTable1[0].length; i++) {
    const sb1 = [];
    const sb2 = [];
    for (const [index, item] of charTable1.entries()) {
      i - index < 0 || sb1.push(item[i - index]);
    }
    for (const [index, item] of charTable2.entries()) {
      i - index < 0 || sb2.push(item[i - index]);
    }
    // 去除长度小于3的值
    sb1.join('').length < 3 ||
      charAll.push(sb1.join(''), Object.assign([], sb1).reverse().join(''));
    sb1.join('').length < 3 ||
      charAll.push(sb2.join(''), Object.assign([], sb2).reverse().join(''));
  }
  charAll.push('abcdefghijklmnopqrstuvwxyz');
  return charAll;
}

// 校验键盘连续性和连续重复
export function validatePasswordChar(val: string) {
  getCharAll();
  const password = val.toLowerCase();
  // 密码3位比对
  for (let i = 0; i < password.length - 2; i++) {
    const n1 = password[i];
    const n2 = password[i + 1];
    const n3 = password[i + 2];
    // 判断重复字符
    if (n1 === n2 && n1 === n3) {
      return false;
      // 判断键盘连续字符
    } else if (
      charAll.some((item) => {
        return item.includes(n1 + n2 + n3);
      })
    ) {
      return false;
    }
  }
  return true;
}
