<script setup lang="ts">
import type { ProSettingItemProps } from '../interfaces';
import { computed } from 'vue';
import { SELECTABLE_ITEM_OPTIONS, THEME_COLORS_PRESET, useLayoutState } from '../composables';
import { bem } from '@hlx/use';
import { BEM_XOM_NS } from '@ome/bases';

defineOptions({ name: 'XomProSettingItem' });

const props = defineProps<ProSettingItemProps>();

const ns = bem('pro-setting-item', BEM_XOM_NS);

const { isDark, effective, disables, colorPreset, toggleSettings } = useLayoutState();

const options = computed(() => {
  if (props.field === 'colorPrimary') {
    return colorPreset.value || THEME_COLORS_PRESET;
  }
  if (props.field === 'body') {
    return [
      { label: '流式', value: 'fluid' },
      { label: '定宽', value: 'fixed', disabled: effective.layout === 'mix' },
    ];
  }
  return ['theme', 'layout', 'motion'].includes(props.field)
    ? SELECTABLE_ITEM_OPTIONS.filter((o_) => o_.type === props.field)
    : [];
});
</script>

<template>
  <div :class="[ns.b(), ns.m(type), ns.is('dark', isDark), ns.is('disabled', !!disables[field])]">
    <span v-if="label && (type === 'select' || type === 'switch')">
      {{ label }}
    </span>

    <a-select
      v-if="type === 'select'"
      :value="effective[field] as string"
      :size="'small'"
      :options="options"
      :disabled="disables[field]"
      @change="(val_: any) => toggleSettings(field, val_)"
    />

    <a-switch
      v-else-if="type === 'switch'"
      :checked="effective[field] as boolean"
      :disabled="disables[field]"
      :size="'small'"
      @change="(val_: any) => toggleSettings(field, val_)"
    />

    <template v-else-if="type === 'style'">
      <a-tooltip v-for="style_ of options" :key="style_.value" :title="style_.label">
        <div
          :class="[ns.e('style'), style_.value]"
          @click="() => toggleSettings(field, style_.value)"
        >
          <check-outlined v-if="effective[field] === style_.value" />
        </div>
      </a-tooltip>
    </template>

    <template v-else-if="type === 'color'">
      <a-tooltip v-for="color_ of options" :key="color_.value" :title="color_.label">
        <div
          :class="[ns.e('color'), color_.value]"
          :style="{ backgroundColor: color_.value }"
          @click="() => toggleSettings(field, color_.value)"
        >
          <check-outlined v-if="effective[field] === color_.value" />
        </div>
      </a-tooltip>
    </template>
  </div>
</template>

<style scoped lang="scss">
@use '@ome/bases/styles/vars';
@use '@hlx/spec/mixins';

@include mixins.b(pro-setting-item) {
  & {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 0;
    line-height: 1.6667;
  }

  @include mixins.m(color) {
    display: grid;
    grid-template-columns: repeat(auto-fit, 20px);
    gap: var(--xom-margin-xs);
    font-size: var(--xom-font-size);
    color: var(--xom-color-white);
  }

  @include mixins.m(style) {
    gap: var(--xom-padding-md);
    justify-content: flex-start;
    font-size: var(--xom-font-size);
  }

  @include mixins.e(color) {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    font-weight: 700;
    cursor: pointer;
    border-radius: 2px;
  }

  @include mixins.e(style) {
    position: relative;
    width: 44px;
    height: 36px;
    overflow: hidden;
    color: var(--xom-color-text-tertiary);
    cursor: pointer;
    background-color: #f0f2f5;
    border-radius: 4px;
    box-shadow: 0 1px 2.5px 0 rgba(0, 0, 0, 20%);

    > :deep(.#{vars.$ant-icon-prefix}) {
      position: absolute;
      right: 6px;
      bottom: 4px;
      font-size: var(--xom-font-size-lg);
      color: var(--xom-color-primary);
      pointer-events: none;
    }

    &::before,
    &::after {
      position: absolute;
      top: 0;
      left: 0;
      content: '';
      background-color: var(--xom-color-white);
    }

    &::before {
      width: 33%;
      height: 100%;
    }

    &::after {
      width: 100%;
      height: 33%;
    }

    /* theme */

    &.dark {
      background-color: rgba(0, 21, 41, 85%);

      &::before,
      &::after {
        background-color: rgba(0, 21, 41, 65%);
      }
    }

    &.auto {
      &::before {
        z-index: 1;
        background-color: #001529;
      }
      &::after {
        background-color: var(--xom-color-white);
      }
    }

    /* layout */

    &.side {
      &::before {
        z-index: 1;
        background-color: #001529;
      }
      &::after {
        background-color: var(--xom-color-white);
      }
    }

    &.top {
      &::before {
        background-color: transparent;
      }
      &::after {
        background-color: #001529;
      }
    }

    &.mix {
      &::before {
        background-color: var(--xom-color-white);
      }
      &::after {
        background-color: #001529;
      }
    }
  }

  @include mixins.when(disabled) {
    & > :deep(span:first-child) {
      opacity: 0.5;
    }
  }

  @include mixins.when(dark) {
    @include mixins.e(style) {
      background: rgb(42, 44, 44);
      box-shadow: rgba(13, 13, 13, 18%) 0 1px 2.5px 0;

      &::before,
      &::after {
        background-color: rgba(0, 21, 41, 65%);
      }

      &.light {
        &::before,
        &::after {
          background-color: rgb(36, 37, 37);
        }
      }

      &.dark {
        &::before,
        &::after {
          background-color: rgba(15, 28, 41, 65%);
        }
      }

      &.side::before,
      &.inverted::before {
        background-color: rgb(15, 28, 41);
      }

      &.side::after,
      &.inverted::after {
        background-color: rgb(36, 37, 37);
      }

      &.top {
        &::before {
          background-color: transparent;
        }
      }

      &.mix::before {
        background-color: rgb(36, 37, 37);
      }
    }
  }
}
</style>
