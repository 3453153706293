/**
 * 环境变量所属环境别名，对应`.env(.local)?.<xxx>`
 */
export const ENV_NAME = import.meta.env?.MODE || 'production';

/**
 * 是否为dev环境
 */
export const IS_DEV = ENV_NAME === 'development' || ENV_NAME === 'native';

/**
 * 是否为测试环境
 */
export const IS_TEST = ENV_NAME === 'testing';

/**
 * 是否为预发布环境
 */
export const IS_PRERELEASE = ENV_NAME === 'prerelease';

/**
 * 是否为灾备环境
 */
export const IS_RESERVE = ENV_NAME === 'reserve';

/**
 * 是否为紫荆正式环境
 */
export const IS_ZJ_PROD = ENV_NAME === 'zjproduction';

/**
 * 是否为集约化正式环境
 */
export const IS_JYH_PROD = ENV_NAME === 'jiyuehua';

/**
 * 是否为正式环境
 */
export const IS_PROD = ENV_NAME === 'production';

/**
 * 登陆、密码重置等授权接口是否可绕过验证码校验功能
 */
export const IS_CAPTCHA_SKIPPED = IS_DEV || IS_TEST || IS_ZJ_PROD || IS_JYH_PROD;
