<script setup lang="ts">
import { computed, type CSSProperties, h, ref, toRef } from 'vue';
import {
  CopyOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
  CheckOutlined,
} from '@ant-design/icons-vue';
import { useClipboard } from '@vueuse/core';

defineOptions({ name: 'XomPwdResult' });

const props = defineProps<{ value: string }>();

const emits = defineEmits<{
  (evt: 'success'): void;
}>();

const { isSupported, copied, copy } = useClipboard({
  source: toRef(props, 'value'),
  legacy: true,
  copiedDuring: 5000,
  read: true,
});

const holding = ref(false);

const isValidPwd = computed(() => typeof props.value === 'string' && !/^\s*$/g.test(props.value));
const isCopyReady = computed(() => isSupported.value && isValidPwd.value);

const viewsStyle = computed<CSSProperties>(() => ({
  'background-color': '#f2f2f2',
  'border-radius': '6px',
  padding: `8px 8px 8px ${isCopyReady.value ? 88 : 48}px`,
  margin: 0,
  'text-security': holding.value ? 'none' : 'disc',
}));

const successTrigger = () => {
  emits('success');
};
</script>

<template>
  <a-result
    class="xom-password-result"
    status="success"
    title="密码修改成功"
    :sub-title="
      isValidPwd ? '此页关闭后将不再可见，请牢记您的新密码，切勿外泄：' : '请牢记您的新密码!'
    "
  >
    <template #extra>
      <a-flex v-if="isValidPwd" :align="'center'" :gap="8" :style="viewsStyle">
        <span style="flex: 1">{{ props.value || 'invalid password' }}</span>

        <a-button
          type="link"
          ghost
          :icon="h(holding ? EyeOutlined : EyeInvisibleOutlined)"
          @mousedown="holding = true"
          @mouseup="holding = false"
        />

        <a-button
          v-if="isCopyReady"
          type="link"
          ghost
          :icon="h(copied ? CheckOutlined : CopyOutlined)"
          @click="() => copy(props.value)"
        />
      </a-flex>

      <br />

      <a-button type="primary" @click="successTrigger">知道了，去登录 ...</a-button>
    </template>
  </a-result>
</template>
