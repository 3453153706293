<script setup lang="ts">
import type { ProRouteRaw } from '@ome/components';
import { useLayoutState, XomProActionItem, XomProActions, XomProAvatar } from '@ome/components';
import { computed, ref, h, createVNode } from 'vue';
import { usePermitStore } from '@/storage/permits';
import { useProfileStore } from '@/storage/profile';
import { useRoute, useRouter } from 'vue-router';
import { IS_DEV, IS_PRERELEASE, useConfirm } from '@ome/bases';
import {
  GlobalOutlined,
  LockOutlined,
  BellOutlined,
  LogoutOutlined,
  RobotOutlined,
  SecurityScanOutlined,
  SyncOutlined,
  UserOutlined,
  SwapOutlined,
  NotificationOutlined,
  EnvironmentOutlined,
} from '@ant-design/icons-vue';
import type { XSdkEnvAlias } from '@hlx/sdk';
import { signout } from '@/utils/signin';
import { NotifySDK } from '@hlx/sdk';
import { ENV_NAME } from '@ome/bases';
import { useTokenStore } from '@/storage/tokens';
import { usePermitChangeConfirm } from '@/utils';
import { useNavStore } from '@/storage/nav';
import { addSystemRoutes } from '@/router/guard';
import { useNotifyStore } from '@/storage/notify';
import { notification } from 'ant-design-vue';
import { confirmNotify } from '@/apis/notify';
import NotifyContent from './../xom-message-modal/notify-content.vue';

defineOptions({ name: 'XomHeadActions' });

const router = useRouter();
const profile = useProfileStore();
const permits = usePermitStore();
const nav = useNavStore();
const notify = useNotifyStore();

const { rootMenus, effective } = useLayoutState();
const warnPwdChange = new Set(['DEFAULT_RESET', 'OUTDATED_RESET']);
const forcePwdChange = new Set(['DEFAULT_FORCE_RESET', 'OUTDATED_FORCE_RESET']);
const isWarnPwdChange = computed(() => warnPwdChange.has(profile.passwordResetType));
const isForcePwdChange = computed(() => forcePwdChange.has(profile.passwordResetType));
const isFirstTime = computed(() =>
  ['DEFAULT_RESET', 'DEFAULT_FORCE_RESET'].includes(profile.passwordResetType),
);

const systemFormOpen = ref(false);
const notifyVisible = ref(false);
const siteSwitcherOpen = ref(false);
const pwdResetModalOpen = ref(isWarnPwdChange.value || isForcePwdChange.value);

notify.getEventType();
function showNotify() {
  notifyVisible.value = !notifyVisible.value;
  notify.getUnreadNotify();
}

const preferenceMenus = computed(() => {
  return effective.layout === 'top'
    ? rootMenus.value.find((m_) => m_.path.startsWith('/preference'))?.children || []
    : [];
});

const profileMenus = computed<Partial<ProRouteRaw>[]>(() => {
  function getAvatar(userType: (typeof profile)['type']) {
    const icons = { SYSTEM: RobotOutlined, SUPER: GlobalOutlined, NORMAL: UserOutlined };
    const classes = { SYSTEM: 'text-warning', SUPER: 'text-success', NORMAL: '' };
    return () => h(icons[userType], { class: classes[userType] });
  }
  return [
    {
      meta: { icon: getAvatar(profile.type), title: profile.nickname },
    },
    {
      meta: { icon: LockOutlined, title: '修改密码', dividerBefore: true },
      onClick: handlePwdReset,
    },
    {
      meta: { icon: SyncOutlined, title: '刷新个人信息' },
      onClick: refresh,
    },
    {
      meta: { icon: SecurityScanOutlined, title: '重载权限' },
      onClick: reauth,
    },
    ...(profile.site?.nickName && siteOptions.value.length
      ? [
          {
            meta: { icon: EnvironmentOutlined, title: profile.site?.name },
            onClick: () => (siteSwitcherOpen.value = true),
          },
        ]
      : []),
    {
      meta: { icon: LogoutOutlined, title: '退出登录', dividerBefore: true },
      onClick: signout,
    },
  ];
});

const siteOptions = computed(
  () =>
    permits.sites?.reduce<{ label: string; value: string }[]>((prev, s: BaseRelates) => {
      return s.id === profile.site?.id ? prev : [...prev, { label: s.name, value: s.id }];
    }, []) ?? [],
);

function handlePwdReset() {
  pwdResetModalOpen.value = !pwdResetModalOpen.value;
}

async function refresh() {
  await profile.reload();
}

async function reauth() {
  await permits.reload();
  router.go(0);
}

const notifysdk = new NotifySDK({
  env: ENV_NAME as XSdkEnvAlias,
  token: () => useTokenStore().sysAccessToken,
  freshToken: () => useTokenStore().flush(),
});

notifysdk.addEventListener('USER-PERMISSION-UPDATE', async () => {
  const permits = usePermitStore();
  const profile = useProfileStore();
  await profile.reload();
  await permits.reload();
  if (IS_PRERELEASE) {
    usePermitChangeConfirm('sse', () => {
      router.go(0);
    });
  } else {
    //做了一次静默刷新菜单的操作
    nav.emptyMenus();
    addSystemRoutes(router, permits.systems, []);
  }
});
notifysdk.addEventListener('message', async (data) => {
  const notifyData = data.data;
  notify.setUnreadNum(notifyData.unConfirmCount);
  if (notifyData.eventNotifyType === 'DISPLAY') {
    if (notifyData.forcePublish) {
      useConfirm('info', {
        title: notifyData.eventName ?? '消息通知',
        width: 'md',
        content: createVNode(NotifyContent, { contentHtml: notifyData.eventContent }),
        okText: '我已知晓',
        onOk: async () => {
          notifyData.id && (await confirmNotify(notifyData.eventUserMessageId));
        },
      });
    } else {
      notification.open({
        message: '消息通知',
        description:
          notifyData.eventBusinessType === 'SYSTEM'
            ? '您收到1条新的平台消息'
            : `您收到1条新的${notifyData.eventName}`,
        icon: () => h(NotificationOutlined, { class: 'notify-icon' }),
      });
    }
  }
});

function isActiveClass(url: string) {
  const isActive = useRoute().path.startsWith(url);
  return isActive ? 'is-active' : '';
}
</script>

<template>
  <xom-pro-actions>
    <!-- “本地开发注册”入口 -->
    <xom-pro-action-item
      v-if="IS_DEV"
      title="注册本地开发环境子系统"
      @click="systemFormOpen = !systemFormOpen"
    >
      <template #icon>
        <plus-circle-outlined />
      </template>
    </xom-pro-action-item>

    <!-- 消息通知 -->
    <xom-pro-action-item @click="showNotify">
      <template #icon>
        <bell-outlined :class="isActiveClass('/member')" />
        <a-badge :count="notify.unreadNum" :offset="[5, 0]">
          <div class="anticon" />
        </a-badge>
        <a-popover v-model:open="notifyVisible" trigger="click" overlay-class-name="xom-popover">
          <div class="blank-element" />
          <template #content>
            <xom-notify v-model:count="notify.unreadNum" @close="notifyVisible = !notifyVisible" />
          </template>
        </a-popover>
      </template>
    </xom-pro-action-item>

    <!-- “系统配置”导航菜单 -->
    <xom-pro-action-item
      v-if="permits.systems.some((s_) => s_.activeRule?.startsWith('/preference'))"
      href="/preference"
      :dropdown-menus="preferenceMenus"
    >
      <template #icon><setting-outlined /></template>
    </xom-pro-action-item>

    <!-- “站点切换”入口 -->
    <!-- 有简称 -->
    <!--    <xom-pro-action-item v-if="profile.site?.nickName" :title="profile.site?.name"> -->
    <!--      <template #icon /> -->
    <!--    </xom-pro-action-item> -->
    <!-- “站点切换”入口 -->
    <xom-pro-action-item
      :title="profile.site?.name"
      :is-hide-icon="true"
      @click="siteSwitcherOpen = true"
    >
      <template #default>
        <div style="display: flex; align-items: center; font-size: 12px">
          <span v-if="profile.site?.nickName" class="xom-pro-actions_nickname">
            <span>
              {{ profile.site?.nickName }}
            </span>
          </span>
          <swap-outlined
            v-if="profile.site?.nickName && siteOptions.length"
            class="site-mark-point"
          />
          <environment-outlined v-else class="site-mark-point" />
        </div>
      </template>
    </xom-pro-action-item>

    <!-- 用户头像 -->
    <xom-pro-action-item
      :tooltip="profile.authed ? undefined : '未登录'"
      :disabled="!profile.authed"
      :dropdown-menus="profileMenus"
      dropdown-trigger="click"
      style="margin-left: 12px"
    >
      <template #icon>
        <xom-pro-avatar
          v-if="profile.authed"
          :src="profile.avatar"
          :name="profile.nickname"
          :gender="profile.gender?.toLowerCase() as any"
        />
        <login-outlined v-else />
      </template>
    </xom-pro-action-item>

    <xom-system-form-modal v-if="IS_DEV" v-model:open="systemFormOpen" type="develop" />

    <xom-site-switch-modal v-model:open="siteSwitcherOpen" />

    <xom-pwd-reset
      v-model:visible="pwdResetModalOpen"
      :is-show-tip="isWarnPwdChange || isForcePwdChange"
      :is-hide-close="isForcePwdChange"
      :is-first-time="isFirstTime"
      @ok="signout"
    />
  </xom-pro-actions>
</template>

<style lang="scss">
@use '@ome/bases/styles/vars';

.xom-pro-actions_nickname {
  display: inline-block;
  max-width: 6em;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  white-space: nowrap;
}
.site-mark-point {
  font-size: 14px;
}

.xom-popover {
  .#{vars.$ant-prefix}-popover-inner {
    padding: 0;
  }
}

.notify-icon {
  color: var(--xom-color-info);
}
.blank-element {
  position: relative;
}
.is-active {
  color: var(--xom-color-primary);
}
</style>
