<script setup lang="ts">
import type { ProIconProps } from '../interfaces';
import { defineAsyncComponent, type FunctionalComponent, h, isVNode, markRaw } from 'vue';
import { asyncComputed } from '@vueuse/core';
import { isPascalCase, isUrl } from '@hlx/use';
import { default as BaseIcon, createFromIconfontCN as createIconfont } from '@ant-design/icons-vue';
defineOptions({ name: 'XomProIcon' });

const props = defineProps<ProIconProps>();

const A_ICONS_MODULES_MAP = Object.fromEntries(
  Object.entries(import.meta.glob('../node_modules/@ant-design/icons-vue/*.js') || []).map(
    ([path_, resolver_]) => [
      path_.replace(/.*\/(\w+).js/, '$1'),
      resolver_ as () => Promise<FunctionalComponent>,
    ],
  ),
);

const baseAttrs = markRaw({ role: 'img', width: '1em', height: '1em', fill: 'currentColor' });

const resolveDefaultIcon = () => {
  return defineAsyncComponent<FunctionalComponent>(A_ICONS_MODULES_MAP['AppstoreOutlined']);
};
const resolveAntIconModule = (icon: string) => {
  if (icon in A_ICONS_MODULES_MAP) {
    return defineAsyncComponent<FunctionalComponent>(A_ICONS_MODULES_MAP[icon]);
  }
  return resolveDefaultIcon();
};

const IconComponent = asyncComputed(() => {
  if (typeof props.icon === 'function' || isVNode(props.icon)) {
    return props.icon;
  }

  if (typeof props.icon === 'object' && 'render' in props.icon) {
    return h(BaseIcon, null, {
      component: () => h(props.icon, baseAttrs),
    });
  }

  if (typeof props.icon !== 'string' || /^(base64|\/([\w-]+)+|(https?:)\/\/)/.test(props.icon)) {
    return () =>
      h('span', { class: 'anticon', ...baseAttrs }, [
        h('img', {
          src: props.icon,
        }),
      ]);
  }

  if (/^<svg.*<\/svg>$/.test(props.icon)) {
    return () => h('span', { class: 'anticon', innerHTML: props.icon, ...baseAttrs });
  }

  if (props.iconfont && !isUrl(props.icon)) {
    return h(createIconfont({ scriptUrl: props.iconfont }), {
      type: props.icon,
      ...baseAttrs,
    });
  }
  if (isPascalCase(props.icon) && /(TwoTone|Outlined|Filled)$/.test(props.icon)) {
    return resolveAntIconModule(props.icon);
  }

  return resolveDefaultIcon();
});
</script>

<template>
  <component :is="IconComponent" />
</template>
