import type { MessageArgsProps } from 'ant-design-vue/lib/message';
import type { ModalFuncProps, ModalProps } from 'ant-design-vue';
import type { UseConfirmProps, UseMessageType, UseModalProps } from '../interfaces';
import { ConfigProvider, message, Modal } from 'ant-design-vue';
import { A_MODAL_WIDTHS, BEM_ANT_NS } from '../const';

// FIXME: need to config by dynamic settings
ConfigProvider.config({ prefixCls: BEM_ANT_NS });
message.config({ top: '68px', duration: 5 });

export function useMessage(type: UseMessageType, conf: MessageArgsProps) {
  message[type]?.(conf);
}

export function useModal(props?: UseModalProps): ModalProps {
  const {
    loading,
    isForm,
    closable = true,
    width = 'sm',
    cancelButtonProps = {},
    ...rest
  } = props || {};
  const closable_ = loading ? false : !!closable;
  if (loading) {
    Object.assign(cancelButtonProps, { disabled: true });
  }

  return {
    width: A_MODAL_WIDTHS[width],
    closable: closable_,
    maskClosable: closable_,
    keyboard: closable_,
    destroyOnClose: true,
    centered: true,
    okText: isForm ? '提交' : '确定',
    cancelText: '取消',
    confirmLoading: loading,
    cancelButtonProps,
    ...rest,
  };
}

export function useConfirm(type: Required<ModalFuncProps>['type'], props: UseConfirmProps) {
  const { okButtonProps = {}, width = 'xs', cancelButton, ..._props } = props;

  if (type === 'error') {
    Object.assign(okButtonProps, { danger: true });
  }

  if (!('title' in _props)) {
    Object.assign(_props, {
      title: type === 'error' || type === 'warning' || type === 'warn' ? '警告' : '提示',
    });
  }
  return Modal[type]({
    centered: true,
    width: A_MODAL_WIDTHS[width],
    okText: '确定',
    cancelText: '取消',
    okCancel: cancelButton === false ? false : cancelButton || type === 'confirm',
    okButtonProps,
    ..._props,
  });
}

export function useDelConfirm(props: UseConfirmProps) {
  return useConfirm('error', {
    okText: '我确定!',
    cancelText: '点错了...',
    ...props,
    cancelButton: true,
  });
}
