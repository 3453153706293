<script setup lang="ts">
import type { FormInstance } from 'ant-design-vue';
import type { Rule } from 'ant-design-vue/es/form';
import type { DtoSystem, VoSystems } from '@/apis/permits';
import { putSystemEcho } from '@/apis/permits';
import { computed, markRaw, reactive, ref, watch } from 'vue';
import { omit, pick } from 'radash';
import { useFormRule } from '@/utils';
import { IS_DEV, useModal } from '@ome/bases';
import { usePermitStore } from '@/storage/permits';

defineOptions({ name: 'XomSystemFormModal' });

const open = defineModel<boolean>('open', { default: false });
const props = withDefaults(defineProps<{ dto?: VoSystems; type?: 'registry' | 'develop' }>(), {
  type: 'registry',
  dto: null,
});
const emits = defineEmits<{ (evt: 'update:visible', visible: boolean): void; (evt: 'ok'): void }>();

const permits = usePermitStore();
const formRef = ref<FormInstance>();
const submitting = ref(false);

const initializes = markRaw<DtoSystem>({
  systemType: 'SUBSYSTEM',
  key: '',
  name: '',
  nodeName: '',
  activeRule: '',
  path: '',
  homePath: '',
});

const isDev = computed(() => IS_DEV && props.type === 'develop');

const rules = computed<Partial<Record<keyof DtoSystem, Rule[]>>>(() => ({
  key: [useFormRule('macro', true)],
  name: [useFormRule(null, true)],
  nodeName: [useFormRule(null, true)],
  activeRule: [useFormRule('path', true)],
  path: [useFormRule(props.type === 'develop' ? 'url' : 'domainUrl', true)],
  homePath: [useFormRule('path', false)],
}));
const echos = reactive<DtoSystem>({ ...initializes });

const placeholders = computed<Partial<Record<keyof DtoSystem, string>>>(() => ({
  name: '测试子系统',
  nodeName: '测试子系统',
  key: props.type === 'develop' ? 'KEY_IN_DEV_MODE' : 'SYSTEM_UNIQUE_KEY',
  activeRule: '/top-routing-path',
  path:
    props.type === 'develop' ? 'https?://localhost:5173' : 'https?://hlx.cqnews.net/deployed-path',
}));

function parseDto() {
  return echos.systemType === 'EXTERNAL'
    ? omit(echos as any, ['activeRule', 'homePath'])
    : echos.systemType === 'MAIN'
      ? omit(echos as any, ['homePath', 'path'])
      : (echos as any);
}

async function validate(clear?: boolean) {
  if (clear) {
    formRef.value.clearValidate();
  }
  await formRef.value.validate();
}

async function saveLocal() {
  permits.regDevEntry(echos);

  if (!echos.systemType || echos.systemType === 'SUBSYSTEM') {
    location.href = location.origin + echos.activeRule;
  }
}

async function submit() {
  try {
    submitting.value = true;
    await validate();
    await (isDev.value ? saveLocal() : putSystemEcho(parseDto(), props.dto?.id));
    open.value = false;
    emits('ok');
  } catch (error) {
    console.error(error);
  } finally {
    submitting.value = false;
  }
}

async function reset() {
  if (!open.value || !props.dto) {
    Object.assign(echos, initializes);
    return;
  }

  Object.assign(
    echos,
    pick(props.dto, ['name', 'key', 'nodeName', 'path', 'activeRule', 'homePath', 'systemType']),
  );
}

watch(() => open.value, reset);
</script>

<template>
  <a-modal
    v-model:open="open"
    v-bind="useModal({ width: 'sm', onOk: submit, isForm: true, loading: submitting })"
  >
    <template #title>
      {{ `${dto?.id ? `配置子系统` : '注册子系统'}` }}

      <xom-dev-micro-marker v-if="isDev" tooltip />
    </template>

    <a-form ref="formRef" :model="echos" :rules="rules" :label-col="{ span: 4 }">
      <a-form-item label="系统类型" required>
        <a-radio-group v-model:value="echos.systemType" :disabled="!IS_DEV">
          <a-radio-button :value="'SUBSYSTEM'">内建系统</a-radio-button>
          <a-radio-button :value="'EXTERNAL'">外链系统</a-radio-button>
          <a-radio-button v-if="!isDev" :value="'MAIN'">基座系统</a-radio-button>
        </a-radio-group>
      </a-form-item>

      <a-form-item label="系统名称" name="name">
        <a-input v-model:value="echos.name" :placeholder="placeholders.name" />
      </a-form-item>

      <a-form-item label="显示名称" name="nodeName">
        <a-input v-model:value="echos.nodeName" :placeholder="placeholders.nodeName" />
      </a-form-item>

      <a-form-item label="唯一标识" name="key">
        <a-input v-model:value="echos.key" :disabled="!IS_DEV" :placeholder="placeholders.key" />
      </a-form-item>

      <a-form-item v-if="echos.systemType !== 'EXTERNAL'" label="挂载规则" name="activeRule">
        <a-input
          v-model:value="echos.activeRule"
          :disabled="!IS_DEV"
          :placeholder="placeholders.activeRule"
        />
      </a-form-item>

      <a-form-item v-if="echos.systemType === 'SUBSYSTEM'" label="首页路径" name="homePath">
        <a-input
          v-model:value="echos.homePath"
          :disabled="!IS_DEV"
          :placeholder="placeholders.homePath"
        />
      </a-form-item>

      <a-form-item v-if="echos.systemType !== 'MAIN'" label="部署地址" name="path">
        <a-input v-model:value="echos.path" :disabled="!IS_DEV" :placeholder="placeholders.path">
          <template v-if="echos.systemType === 'EXTERNAL'" #suffix>
            <a-tooltip title="需将共享状态作为链接的query参数？">
              <a
                href="http://hlxwebcli.cqyqdc.com/micros/access.html#%E6%B3%A8%E5%86%8C%E5%BA%94%E7%94%A8"
                target="_blank"
              >
                <question-outlined />
              </a>
            </a-tooltip>
          </template>
        </a-input>
      </a-form-item>
    </a-form>
  </a-modal>
</template>
