<script setup lang="ts">
import type { VoNotifyDetail } from '@/apis/notify/typings';
import { parseDate, handleHtmlImg } from '@/utils';
import FullModal from './full-modal.vue';

defineOptions({ name: 'XomMessagePlatformModal' });

const open = defineModel<boolean>('open', { default: false });
defineProps<{ msg: VoNotifyDetail }>();
</script>

<template>
  <full-modal v-model:open="open" title="消息详情">
    <div class="header">
      <h3>【{{ msg.eventName ?? `消息通知` }}】</h3>
      <div>发布时间：{{ parseDate(msg.publishTime, true) }}</div>
    </div>
    <div
      class="ck-content"
      @click="(e) => handleHtmlImg(e, msg.eventContent)"
      v-html="msg.eventContent"
    />
  </full-modal>
</template>

<style scoped lang="scss">
@use '@hlx/spec/vars';

:deep(.xv5-pro-sheet-header__filter) {
  display: none;
}
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: auto;
  margin-bottom: 20px;
  border-bottom: 1px solid vars.$border-color-base;
  div {
    width: 280px;
  }
}
.ck-content,
.header {
  max-width: 848px;
  margin-inline: auto;
}
</style>

<style lang="scss" src="./ck-content.scss"></style>
