import { IS_CAPTCHA_SKIPPED } from './env';

/**
 * Base api url
 */
export const API_BASE_URL = import.meta.env?.VITE_API_BASE || '';

/**
 * 后端 "登录、授权" 微服务地址
 */
export const API_SERVICE_AUTH = '/security-auth';

/**
 * 后端 "系统配置" 微服务地址
 */
export const API_SERVICE_SYS = '/system';

/**
 * 后端 “站点” 微服务地址
 */
export const API_SERVICE_SITE = '/website';

/**
 * 后端 "资源库" 微服务地址
 */
export const API_SERVICE_REPO = 'repository';

/**
 * 后端 "内容云" 微服务地址
 */
export const API_SERVICE_CLOUD = 'content-cloud-service';

/**
 * 后端 "政务云" 服务地址
 */
export const API_SERVICE_EGOV = 'cqliving-cloud-news';

/**
 * 后端 "消息通知" 服务地址
 */
export const API_NOTIFY = 'notify-center-service';
/**
 * 后端 "消息列表" 服务地址
 */
export const API_RETRIEVAL = 'retrieval';

/**
 * 华龙芯 "图片上传" 接口地址
 */
export const API_UPLOAD_OME = `${API_SERVICE_REPO}/files/image/v1`;

/**
 * 华龙芯 "站点LOGO上传" 接口地址
 */
export const API_UPLOAD_LOGO = `${API_SERVICE_CLOUD}/file/upload/logo`;

/**
 * 用户获取授权令牌，登录，登出时的通用参数
 */
export const API_OAUTH_BASES: RecordAny = {
  client_id: 'client_pc_browser',
  client_secret: 'client_pc_browser',
};

/**
 * 登陆、密码重置等授权接口绕过验证码校验校验时的默认验证码
 */
export const API_OAUTH_DEFAULT_CAPTCHA = IS_CAPTCHA_SKIPPED ? 'HLX_BACK_END' : '';

/**
 * `各类导航组件的数据缓存`于 LocalStorage 中读写的钥匙
 */
export const SECRET_PARAMS = 'bxoj7fpdcz976m8lwpsvamzr009gfkt7';

/**
 * Base api url
 */
export const OAUTH_BASE_URL = import.meta.env?.VITE_OAUTH_BASE || '';
