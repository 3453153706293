import { reactive, toRefs } from 'vue';
import { defineStore } from 'pinia';
import type { NotifyStore, VoNotifyDetail } from '@/apis/notify/typings';
import { confirmNotify, fetchEventType, fetchUnreadList } from '@/apis/notify';

export const useNotifyStore = defineStore(
  'notify',
  () => {
    const states = reactive<NotifyStore>({
      unreadNum: 0,
      notifyList: [],
      eventType: [],
    });

    const setUnreadNum = (num: number = 0) => {
      typeof num === 'number' && (states.unreadNum = num);
    };
    const setNotifyList = (list: VoNotifyDetail[]) => {
      states.notifyList = list;
    };

    const getUnreadNotify = async () => {
      const res = await fetchUnreadList();
      setNotifyList(res.data ?? []);
      setUnreadNum(res.data?.length ?? 0);
    };

    /**
     * 查询消息类型枚举
     */
    const getEventType = async (cache = true) => {
      if (cache && states.eventType.length) return states.eventType;
      const res = await fetchEventType();
      states.eventType = res.data;
    };

    /** 查看详情后端自动调用确认消息借口 */
    const confirm = async (id: string) => {
      const count = await confirmNotify(id);
      setUnreadNum(count ?? 0);
    };

    return {
      ...toRefs(states),
      setUnreadNum,
      getUnreadNotify,
      getEventType,
      confirm,
    };
  },
  {
    persist: true,
  },
);
