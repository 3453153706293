<script setup lang="ts">
import type { ProActionItemProps, ProActionsSlots } from '../interfaces';
import { bem } from '@hlx/use';
import { BEM_XOM_NS } from '@ome/bases';
import { XomProIcon } from '../pro-icon';
import { computed, toRef, useAttrs } from 'vue';
import { useRoute } from 'vue-router';

defineOptions({ name: 'XomProActionInner', inheritAttrs: false });

const props = defineProps<ProActionItemProps>();
defineSlots<ProActionsSlots>();

const attrs = useAttrs();

const ns = bem('pro-action-item', BEM_XOM_NS);

const isLink = computed(() => typeof props.href === 'string');
const pathRef = toRef(useRoute(), 'path');
const isActive = computed(() => isLink.value && pathRef.value.startsWith(props.href));
const isExternal = computed(() => isLink.value && /^(https?:)?\/\//.test(props.href));

const attributes = computed(() => {
  if (!isLink.value) {
    return attrs;
  }
  if (isExternal.value) {
    return { ...attrs, href: props.href, target: props.target || '_blank' };
  }
  return {
    ...attrs,
    class: (attrs.class || '') + `${isActive.value ? 'is-active' : ''}`,
    to: props.href,
  };
});
</script>

<template>
  <a-tooltip :title="title" destroy-tooltip-on-hide :mouse-enter-delay="0">
    <component
      :is="isLink ? (isExternal ? 'a' : 'router-link') : 'span'"
      :class="[ns.b(), ns.is('icon-only', !$slots.default), ns.is('disabled', disabled)]"
      v-bind="attributes"
    >
      <template v-if="!$props.isHideIcon">
        <span v-if="$slots.icon" :class="ns.e('icon')">
          <slot name="icon" v-bind="$props" />
        </span>
        <xom-pro-icon v-else :class="ns.e('icon')" :icon="icon" :iconfont="iconfont" />
      </template>
      <span v-if="$slots.default" :class="ns.e('title')">
        <slot />
      </span>
    </component>
  </a-tooltip>
</template>

<style scoped lang="scss">
@use '@ome/bases/styles/vars';
@use '@hlx/spec/mixins';

@include mixins.b(pro-action-item) {
  --action-icon-size: var(--xom-font-size-lg);
  --action-size: var(--xom-control-height-lg);
  --actions-gutter: calc((var(--xom-layout-header-height) - var(--action-size)) / 2);

  display: inline-flex;
  gap: var(--xom-margin-xs);
  align-items: center;
  justify-content: center;
  height: var(--action-size);
  padding-inline: calc((var(--action-size) - 1em) / 2);

  line-height: 1;
  color: var(--xom-color-text-secondary);
  cursor: pointer;
  user-select: none;
  border-radius: var(--xom-border-radius);
  transition: all var(--xom-motion-duration-fast);

  &:hover {
    background-color: var(--xom-color-fill-tertiary);
  }

  &:active {
    background-color: var(--xom-color-fill-secondary);
  }

  @include mixins.e(icon) {
    padding: 0;
    margin: 0;
    font-size: var(--action-icon-size);
    cursor: pointer;
  }

  @include mixins.when(icon-only) {
    width: var(--action-size);
    padding-inline: 0;
  }

  @include mixins.when(active) {
    color: var(--xom-color-primary);
  }

  @include mixins.when(disabled) {
    &:hover {
      background-color: transparent;
    }
  }
}
</style>
