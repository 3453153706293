/**
 * LocalStorage、SessionStorage、Cookie 等存储相关模块
 *
 * TODO: 所有浏览器存储相关的 key 全部加密
 */

/**
 * `授权令牌`于 Cookie 中读写的钥匙
 */
export const STORE_ACCESS_TOKEN = 'xom_token_access_key';

/**
 * `刷新令牌`于 Cookie 中读写的钥匙
 */
export const STORE_FLUSH_TOKEN = 'xom_token_flush_key';

/**
 * `旧平台授权/刷新令牌`于 Cookie 中读写的钥匙
 */
export const STORE_TOKENS_OLD = 'user-tokens';

/**
 * `系统偏好设置`于 LocalStorage 中读写的钥匙
 */
export const STORE_PREFER = 'xom_b05a3c';

/**
 * `用户配置`于 LocalStorage 中读写的钥匙
 */
export const STORE_PROFILE = 'xom_05a3c9';

/**
 * `系统权限配置`于 LocalStorage 中读写的钥匙
 */
export const STORE_PERMIT = 'xom_5a3c9c';

/**
 * `各类导航组件的数据缓存`于 LocalStorage 中读写的钥匙
 */
export const STORE_NAVIGATOR = 'xom_a3c9c3';
