<script setup lang="ts">
import type { ProSocialInfo } from '../interfaces';
import { bem } from '@hlx/use';
import { BEM_XOM_NS } from '@ome/bases';
import { useLayoutState } from '../composables';
import { computed, h, markRaw } from 'vue';
import { CopyrightCircleOutlined, SafetyCertificateOutlined } from '@ant-design/icons-vue';

defineOptions({ name: 'XomProLayoutCopyright' });
defineProps<{ direction?: 'horizontal' | 'vertical' }>();

const { page, copyright, icp } = useLayoutState();
const ns = bem('layout-copyright', BEM_XOM_NS);

const _copyright = markRaw<ProSocialInfo[]>([
  { text: h(CopyrightCircleOutlined) },
  { text: '2000-present' },
  { text: 'CQNEWS Corporation', link: 'https://cqnews.net' },
  { text: 'All Rights Reserved.' },
]);

const _icp = markRaw<ProSocialInfo[]>([
  { text: h(SafetyCertificateOutlined) },
  { text: '渝ICP备11000637号-1', link: 'https://beian.miit.gov.cn/' },
]);

const _c = computed(() => {
  const _ = copyright.value;
  return _ === false ? [] : _ === true ? _copyright : _ || _copyright;
});

const _i = computed(() => {
  return icp.value === false ? [] : icp.value === true ? _icp : icp.value || _icp;
});

function renderTxt(raw: ProSocialInfo) {
  return raw.link
    ? h('a', { target: '_blank', href: raw.link }, [raw.text])
    : h('span', [raw.text]);
}
</script>

<template>
  <div
    v-if="page === 'manage' || page === 'home'"
    :class="[ns.b(), ns.when(direction || 'vertical')]"
  >
    <div>
      <component :is="renderTxt(item)" v-for="item of _c" :key="item.text" />
    </div>

    <div>
      <component :is="renderTxt(item)" v-for="item of _i" :key="item.text" />
    </div>
  </div>
</template>

<style scoped lang="scss">
@use '@ome/bases/styles/vars';
@use '@hlx/spec/mixins';

@include mixins.b(layout-copyright) {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--xom-padding-md);
  font-size: 0.92em;
  color: var(--xom-color-text-tertiary);
  background-color: transparent;

  > div > span {
    margin-inline: 0.3em;
  }

  @include mixins.when(horizontal) {
    flex-direction: row;
    gap: var(--xom-padding-md);
  }

  @include mixins.when(vertical) {
    flex-direction: column;
    gap: var(--xom-padding-sm);
  }
}
</style>
