import type { ConfigProviderProps } from 'ant-design-vue';
import type { MappingAlgorithm } from 'ant-design-vue/es/config-provider/context';
import type {
  ProLayoutEmits,
  ProLayoutProps,
  ProSettingsStates,
  ProSettableStatus,
  ProSettableOption,
  ProPageType,
} from '../interfaces';
import { computed, markRaw, readonly, ref, type Ref, watchEffect } from 'vue';
import { theme as antTheme } from 'ant-design-vue';
import { toReactive, usePreferredDark, useStorage, watchDeep } from '@vueuse/core';
import { useRouter } from 'vue-router';
import { SETTABLE_KEYS, DEFAULT_SETTINGS, useQueryBreakpoints } from '../composables';
import { assign, shake } from 'radash';

type Keys = keyof ProSettableOption;

export function useLayoutStates(props: ProLayoutProps, emits: ProLayoutEmits): ProSettingsStates {
  const router = props.router || useRouter();

  function merge(old: ProSettableOption, newly: ProSettableOption): ProSettableOption {
    const mergedStaging: ProSettableOption = {
      ...old,
      ...shake(newly, (val) => val === null || val === undefined),
    };
    const un_ = props.unsettable;

    if (!un_ || typeof un_ !== 'object') {
      return mergedStaging;
    }

    const has = (key_: string, value_: unknown) => {
      return key_ in un_ ? un_[key_ as Keys] : value_;
    };
    return Object.fromEntries(
      Object.entries(mergedStaging).map<[Keys, any]>(([k_, v_]) => [k_ as Keys, has(k_, v_)]),
    );
  }

  const settable = computed(() => {
    const un_ = props.unsettable;
    if (!un_) {
      return SETTABLE_KEYS;
    }
    return typeof un_ === 'object' ? SETTABLE_KEYS.filter((key_) => !(key_ in un_)) : [];
  });

  const storage = typeof props.storable === 'boolean' ? localStorage : props.storable;
  const storageKey = props.storageKey?.settings || 'xom_pro_layout_states';

  const onlyOneOpeningKeys = markRaw(['colorGray', 'colorWeak'] as const);
  const defaultSettings = markRaw(merge(DEFAULT_SETTINGS, props.defaultSetting));

  const staging: Ref<ProSettableOption> = storage
    ? useStorage<ProSettableOption>(storageKey, defaultSettings, storage, {
        mergeDefaults(storageValue, defaults) {
          //判断是否在unsettable中
          return merge(defaults, storageValue);
        },
      })
    : ref<ProSettableOption>(defaultSettings);

  const { isMobile, isPad, isDesktop, isLarge } = useQueryBreakpoints();

  const isPreferredDark = usePreferredDark();
  const isDark = computed(() => {
    const theme_ = staging.value.theme;
    return !theme_ || theme_ === 'auto' ? isPreferredDark.value : theme_ === 'dark';
  });

  const page = computed(() => {
    return (router.currentRoute.value.meta?.page as ProPageType) || props.page || 'page';
  });

  const disables = computed<ProSettableStatus>(() => {
    const _s = staging.value;

    return {
      compact: _s.adaptive,
      colorInvert: isDark.value,
      showSide: _s.layout === 'top' || isMobile.value,
      fixedSide: _s.layout === 'top' || !_s.showSide || isMobile.value,
      fixedSideTrigger: _s.layout === 'top' || !_s.showSide || isMobile.value,
      fixedHead: _s.layout === 'mix' || !_s.showHead,
      fixedTabs: !_s.fixedHead,
      fixedFoot: !_s.fixedHead,
      menusSplit: _s.layout === 'top' || !_s.showSide || !_s.showHead,
      menusAccordion: _s.layout === 'top' || !_s.showSide,
      keepAlive: !_s.showTabs,
    };
  });

  const effective = computed(() => {
    const _s = staging.value;
    const _p = page.value;

    return {
      ...staging.value,
      compact: _s.adaptive ? !isLarge.value : _s.compact,
      showSide: !isMobile.value && _p === 'manage' && _s.layout !== 'top' && _s.showSide,
      showHead: _s.showHead && (_p === 'manage' || _p === 'home'),
      showFoot: _s.showFoot && (_p === 'manage' || _p === 'home'),
      showTabs: _s.showTabs && _p === 'manage',
      fixedSide: _s.layout === 'side' || _s.fixedSide,
      fixedTabs: _s.fixedHead && _s.fixedTabs,
      fixedFoot: _s.fixedHead && _s.fixedFoot,
      keepAlive: _s.showTabs && _p === 'manage' && _s.keepAlive,
      colorInvert: isDark.value ? false : _s.colorInvert,
      menusSplit: !disables.value.menusSplit && _s.menusSplit,
      menusAccordion: !disables.value.menusAccordion && _s.menusAccordion,
    };
  });

  const isDarkSide = computed(
    () => isDark.value || (effective.value.colorInvert && effective.value.layout === 'side'),
  );
  const isDarkHead = computed(
    // TODO: 还需要根据 page 是否为 `manage` 来判断，非 `manage` 页面可能还有透明头部的情况
    () => isDark.value || (effective.value.colorInvert && effective.value.layout !== 'side'),
  );

  const antAlgorithm = computed(() => {
    const __: Array<MappingAlgorithm> = [
      isDark.value ? antTheme.darkAlgorithm : antTheme.defaultAlgorithm,
    ];
    if (effective.value.compact) {
      __.push(antTheme.compactAlgorithm);
    }
    return __;
  });

  const antConfigs = computed<ConfigProviderProps>(() =>
    assign(props.antConfig, {
      theme: {
        token: {
          colorPrimary: effective.value.colorPrimary,
          green: '#4fbb15',
          lime: '#93c90c',
          colorBgContainer: isDark.value ? '#1b1b1f' : '#ffffff',
        },
        components: {
          Layout: { colorBgBody: isDark.value ? '#1b1b1f' : '#f0f2f5' },
          Menu: isDark.value ? { colorItemBg: '#161618', colorSubItemBg: '#161618' } : {},
        },
        hashed: true,
        algorithm: antAlgorithm.value,
      },
    }),
  );

  const setOnlyOneOpening = (key: 'colorGray' | 'colorWeak') => {
    for (const k_ of onlyOneOpeningKeys) {
      staging.value[k_] = false;
    }
    staging.value[key] = true;
  };

  const toggleSettings = (key: Keys, val: any) => {
    if (!settable.value.includes(key)) {
      console.error(`Cannot set unsettable option "${key}"`);
      return;
    }
    if (disables.value[key]) {
      return;
    }
    if (onlyOneOpeningKeys.includes(key as any) && val === true) {
      setOnlyOneOpening(key as any);
    } else {
      staging.value[key] = val as never;
    }

    emits('settings-change', key, effective.value[key]);
  };

  watchDeep(
    () => props.defaultSetting,
    (newValue) => (staging.value = merge(defaultSettings, newValue)),
  );

  watchEffect(() => {
    const doc_ = document.documentElement;

    if (isDark.value && !doc_.classList.contains('dark')) {
      doc_.classList.add('dark');
    } else {
      doc_.classList.remove('dark');
    }

    if (effective.value.compact && !doc_.classList.contains('compact')) {
      doc_.classList.add('compact');
    } else {
      doc_.classList.remove('compact');
    }

    if (effective.value.colorWeak) {
      doc_.style.filter = 'invert(80%)';
    } else if (effective.value.colorGray) {
      doc_.style.filter = 'grayscale(100%)';
    } else {
      doc_.style.filter = '';
    }
  });

  return {
    isDark,
    isMobile,
    isPad,
    isDesktop,
    isLarge,
    router,
    page,
    settable,
    settings: readonly(toReactive(staging)),
    effective: readonly(toReactive(effective)),
    isDarkSide,
    isDarkHead,
    disables: readonly(toReactive(disables)),
    antConfigs,
    toggleSettings,
  };
}
