<script setup lang="ts">
import type { ProSettingGroup, ProSettingItemProps } from '../interfaces';
import { bem } from '@hlx/use';
import { BEM_XOM_NS } from '@ome/bases';
import { computed } from 'vue';
import { useLayoutState } from '../composables';
import { group } from 'radash';
import XomProSettingGroup from './group.vue';
import XomProSettingItem from './item.vue';

defineOptions({ name: 'XomProSetting' });

const { isDark, settable, effective } = useLayoutState();

const ns = bem('pro-setting', BEM_XOM_NS);

const defaultOptions = computed<ProSettingItemProps[]>(() => [
  { col: 'theme', field: 'theme', type: 'style' },
  { col: 'color', field: 'colorPrimary', type: 'color' },
  { col: 'layout', field: 'layout', type: 'style' },
  { col: 'style', field: 'body', type: 'select', label: '内容区域宽度' },
  { col: 'style', field: 'adaptive', type: 'switch', label: '尺寸自适应' },
  { col: 'style', field: 'compact', type: 'switch', label: '紧凑模式' },
  {
    col: 'style',
    field: 'colorInvert',
    type: 'switch',
    label: `色调反转 (${effective.layout === 'side' ? '侧边栏' : '顶栏'})`,
  },
  { col: 'style', field: 'fixedSide', type: 'switch', label: '固定侧边栏' },
  { col: 'style', field: 'fixedHead', type: 'switch', label: '固定顶栏' },
  { col: 'style', field: 'fixedTabs', type: 'switch', label: '固定标签栏' },
  { col: 'style', field: 'fixedFoot', type: 'switch', label: '固定页脚' },
  { col: 'style', field: 'fixedSideTrigger', type: 'switch', label: '固定边栏收缩按钮' },
  { col: 'style', field: 'menusSplit', type: 'switch', label: '自动分割菜单' },
  { col: 'style', field: 'menusAccordion', type: 'switch', label: '手风琴菜单' },
  { col: 'feat', field: 'motion', type: 'select', label: '页面过渡效果' },
  { col: 'feat', field: 'keepAlive', type: 'switch', label: '历史菜单缓存' },
  { col: 'feat', field: 'showWatermark', type: 'switch', label: '页面水印' },
  { col: 'feat', field: 'showHead', type: 'switch', label: '显示顶栏' },
  { col: 'feat', field: 'showFoot', type: 'switch', label: '显示页脚' },
  { col: 'feat', field: 'showTabs', type: 'switch', label: '显示标签栏' },
  { col: 'feat', field: 'showSide', type: 'switch', label: '显示侧边栏' },
  { col: 'other', field: 'colorGray', type: 'switch', label: '灰度模式' },
  { col: 'other', field: 'colorWeak', type: 'switch', label: '色弱模式' },
]);

const entries = computed(() =>
  Object.entries(
    group<ProSettingItemProps, ProSettingGroup>(
      defaultOptions.value.filter((o_) => settable.value.includes(o_.field)),
      (opt) => opt['col'],
    ),
  ),
);

function titleParser(col: string) {
  return {
    theme: '主题风格',
    color: '主题颜色',
    layout: '布局模式',
    style: '交互方式',
    feat: '功能控制',
    other: '其它设置',
  }[col];
}
</script>

<template>
  <div :class="[ns.b(), ns.is('dark', isDark)]">
    <template v-for="([col, items], idx) of entries" :key="col">
      <a-divider v-if="idx > 0" />

      <xom-pro-setting-group :title="titleParser(col)">
        <template v-for="item of items" :key="item.field">
          <xom-pro-setting-item
            :type="item.type"
            :col="item.col"
            :field="item.field"
            :label="item.label"
          />
        </template>
      </xom-pro-setting-group>
    </template>
  </div>
</template>
