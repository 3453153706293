import type { RouteRecordRaw } from 'vue-router';

/**
 * TODO: 首页路由配置，需要围绕首页的用途做一个完整的产品设计
 */
// export const home: RouteRecordRaw = {
//   path: '/',
//   name: 'XomHomePage',
//   component: () => import('@/pages/home/index.vue'),
// };

/**
 * 旧平台首页路径重定向，防止因用户收藏的首页为旧平台首页，进入系统后被重定向到 `/exception/err_not_found` 页面
 */
export const deprecated: RouteRecordRaw = {
  path: '/bench:rest(/?.*)?',
  name: 'XomOldBench',
  meta: { page: 'page' },
  redirect: '/exception/err_csrss_removed',
};

/**
 * 异常缺省页路由配置
 */
export const exception: RouteRecordRaw = {
  path: '/exception:slash(/?)/:code?',
  name: 'XomExceptionPage',
  meta: { page: 'page' },
  component: () => import('@/pages/exception/index.vue'),
};

export const unidentified: RouteRecordRaw = {
  path: '/:unidentified(.*)',
  name: 'XomUnidentifiedInterceptor',
  meta: { page: 'page' },
  redirect: '/exception/err_not_found',
};
