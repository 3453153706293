import type { RouteRecordRaw } from 'vue-router';

import { XomProRouterView } from '@ome/components';

// todo: migrating preferences pages here

export const asyncRoutes: RouteRecordRaw[] = [
  {
    path: '/member',
    redirect: '/member/message',
    name: 'XomUserCenter',
    meta: { page: 'manage', title: '用户中心', safe: true },
    component: () => import('@/pages/manages/index.vue'),
    children: [
      {
        name: 'XomMessage',
        path: '/member/message',
        meta: { title: '消息中心', order: 10, icon: 'MessageOutlined', safe: true },
        component: XomProRouterView,
        redirect: '/member/message/platform',
        children: [
          {
            name: 'MemberMessagePlatform',
            path: '/member/message/platform',
            meta: { title: '平台消息', order: 10, safe: true },
            component: () => import('@/modules/member/message/platform/index.vue'),
          },
          {
            name: 'MemberMessageBusiness',
            path: '/member/message/business',
            meta: { title: '业务消息', order: 20, safe: true },
            component: () => import('@/modules/member/message/business/index.vue'),
          },
        ],
      },
    ],
  },
];
