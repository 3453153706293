import type { XSdkEnvAlias } from '@hlx/sdk';
import { SsoSDK } from '@hlx/sdk';
import { ENV_NAME } from '@ome/bases';

const sdk = new SsoSDK({
  type: 'redirect',
  env: ENV_NAME as XSdkEnvAlias,
});

export function useSdk() {
  return sdk;
}
