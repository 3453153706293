<script setup lang="ts">
import { useNotifyStore } from '@/storage/notify';
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { handleHtmlClick, html2text, parseDate } from '@/utils';
import { fetchNotifyDetail } from '@/apis/notify';
import type { NotifyType, VoNotifyDetail } from '@/apis/notify/typings';
import type { ListLikeEventData } from '@hlx/uis';

const emits = defineEmits<{ (evt: 'close', visible?: boolean): void; (evt: 'ok'): void }>();

const notify = useNotifyStore();
const router = useRouter();
const msgVisible = ref(false);
const msgType = ref<NotifyType>('BUSINESS');
const msgDetail = ref<VoNotifyDetail>(null);

async function showDetail(record: VoNotifyDetail) {
  emits('close');
  msgType.value = record.eventBusinessType;
  const res = await fetchNotifyDetail(record.eventUserMessageId);
  notify.setUnreadNum(res.data?.unConfirmCount);
  msgDetail.value = res.data;
  msgVisible.value = true;
}

function messageItem(_: MouseEvent, { record }: ListLikeEventData) {
  showDetail(record as VoNotifyDetail);
}

function gotoMessageCenter() {
  emits('close');
  router.push({ name: 'MemberMessagePlatform' });
}
</script>

<template>
  <div class="xom-notify">
    <section>
      <x-list
        item-type="simple"
        :data-source="notify.notifyList"
        :field-names="{
          title: 'eventName',
          subtitle: 'eventContent',
        }"
        @click="messageItem"
      >
        <template #title="{ record }">
          <a @click.stop="showDetail(record as VoNotifyDetail)">{{ record.eventName }}</a>
        </template>
        <!-- <template #avatar="{ record }">
          <a-avatar v-if="record.avatar" src="https://joeschmoe.io/api/v1/random" />
        </template> -->
        <template #subtitle="{ record }">
          <article :title="html2text(record.eventContent)">
            <div @click="(e) => handleHtmlClick(e, record.app)" v-html="record.eventContent" />
          </article>
          <text>
            <span v-if="record.app?.name">[{{ record.app.name }}]</span>
            {{ parseDate(record.publishTime, true) }}
          </text>
        </template>
      </x-list>
    </section>
    <footer>
      <a-button type="link" @click="gotoMessageCenter">前往消息中心</a-button>
    </footer>
    <xom-message-modal v-model:open="msgVisible" :type="msgType" :msg="msgDetail" />
  </div>
</template>

<style scoped lang="scss">
@use '@ome/bases/styles/vars';

$xom-notify-footer-height: 46px;
$xom-notify-header-height: 46px;

.xom-notify {
  width: 300px;

  :deep(.#{vars.$ant-prefix}-list-item-meta) {
    align-items: center;
    margin-block-end: 4px;
  }

  :deep(.#{vars.$ant-prefix}-list-item-meta-content .#{vars.$ant-prefix}-list-item-meta-title) {
    margin-block-end: 8px;
  }

  :deep(
    .#{vars.$ant-prefix}-list-item-meta-content .#{vars.$ant-prefix}-list-item-meta-description
  ) {
    font-size: 12px;
    article {
      display: -webkit-box;
      width: 100%;
      margin: 0 0 6px;
      overflow: hidden;
      -webkit-line-clamp: 3;
      white-space: wrap;
      -webkit-box-orient: vertical;
    }
  }

  header {
    width: 100%;
    padding: 0 0 10px;
    font-size: 1rem;
    font-weight: bold;
  }
  section {
    // height: calc(100% - $xom-notify-footer-height - $xom-notify-header-height);
    height: calc(100% - 46px - 46px);

    // overflow-y: scroll;
    border-top: solid 1px var(--xom-color-border-secondary);
    border-bottom: solid 1px var(--xom-color-border-secondary);
  }
  footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: $xom-notify-footer-height;
  }
}
</style>
