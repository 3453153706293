import type { ProMenusPathFormatter, ProThemeType } from '../interfaces';
import type { InjectionKey } from 'vue';

export interface ProMenuContext {
  submenuTheme?: ProThemeType;
  popupClass?: string;
  direction?: 'horizontal' | 'inline' | 'vertical';
  ignoreChildren?: boolean;
  menusPathFormatter?: ProMenusPathFormatter;
  externalIcon?: any;
  hideExternalIcon?: boolean;
}

export const PRO_MENUS_CTX_TOKEN: InjectionKey<ProMenuContext> = Symbol('__xom_pro_menus_ctx');
