import { xhr } from '@/utils';
import { API_SERVICE_AUTH } from '@ome/bases';

/**
 * 修改用户密码
 *
 * @param params - 请求参数
 *
 * @param params.oldPassword - 旧密码
 *
 * @param params.clearPassword - 新密码
 */
export function patchUserPassword(params: { oldPassword: string; clearPassword: string }) {
  return xhr.patch(`${API_SERVICE_AUTH}/users/password`, null, { params });
}

/**
 * 发送短信验证码
 */
export async function getSmsCode(params: { username: string; password: string }) {
  const { data } = await xhr.post<{ telephone: string }>(`${API_SERVICE_AUTH}/smscode`, params, {
    withoutToken: true,
  });
  return data;
}
