import type { AxiosError } from 'axios';
import type { VoProfiles, VoSiteProfile } from '@/apis/profile';
import { isArray, omit, pick } from 'radash';
import { computed, reactive, toRefs } from 'vue';
import { defineStore } from 'pinia';
import { DEFAULT_TITLE_TEMPLATE, IS_JYH_PROD, IS_ZJ_PROD, STORE_PROFILE } from '@ome/bases';
import { isEmpty } from '@hlx/use';
import { isXomError, XomError } from '@/utils/error';
import { getProfiles, getFeaturesByDomain, patchMySite } from '@/apis/profile';
import { usePermitStore } from './permits';
import { useFavicon, useTitle } from '@vueuse/core';
import defaultHlxLogoPure from '@ome/assets/logo/logo-hlx-pure.png';
import defaultZjLogoPure from '@ome/assets/logo/logo-zj-pure.png';
import defaultJyhLogoPure from '@ome/assets/logo/logo-jyh-pure.png';
import type { RouteLocationNormalized } from 'vue-router';

let logoPure = defaultHlxLogoPure;
if (IS_ZJ_PROD) {
  logoPure = defaultZjLogoPure;
} else if (IS_JYH_PROD) {
  logoPure = defaultJyhLogoPure;
}

export const useProfileStore = defineStore(
  'profile',
  () => {
    const favicon = useFavicon();
    const permits = usePermitStore();
    const titleSetter = useTitle();

    const states = reactive<VoProfiles>({
      id: null,
      defaultPwd: true,
      account: null,
      telephone: null,
      email: null,
      name: null,
      avatar: null,
      description: null,
      type: null,
      gender: null,
      status: null,
      site: null,
      org: null,
      roles: null,
      assetWhitelist: null,
      preference: null,
      passwordResetType: null,
      bornAppId: null,
    });

    const enabled = computed(() => states.status === 'ENABLE');
    const root = computed(() => states.type === 'SYSTEM');
    const admins = computed(() => states.type === 'SUPER');
    const authed = computed(() => states.status === 'ENABLE' && !!states.site?.id);
    const nickname = computed(() =>
      typeof states.name === 'string' ? states.name.trim() || states.account : states.account,
    );

    const key2ActiveRuleMap = computed(() =>
      permits.systems.reduce(
        (map, cur) => (map.set(cur.key, cur.activeRule), map),
        new Map<string, string>(),
      ),
    );
    const path2LabelMap = computed(() =>
      permits.modules.reduce((map, cur) => {
        const activeRule = key2ActiveRuleMap.value.get(cur.system.key);
        if (activeRule) {
          map.set(`${activeRule}${cur.path}`, cur.label);
        }
        return map;
      }, new Map<string, string>()),
    );

    const setFavicon = () => {
      const iconUrl = states.site?.logoPure;
      if (!iconUrl) {
        favicon.value = logoPure;
        return;
      }

      const iconLoader = document.createElement('img');
      iconLoader.addEventListener('load', () => {
        favicon.value = iconUrl;
        iconLoader.remove();
      });
      iconLoader.addEventListener('error', () => {
        favicon.value = logoPure;
        iconLoader.remove();
      });
      iconLoader.src = iconUrl;
    };

    const setTitle = (currentRoute: RouteLocationNormalized) => {
      const routeLabel = path2LabelMap.value.get(currentRoute.path);
      const title = routeLabel || (IS_ZJ_PROD ? '工作台' : '融媒体中台');
      const template = (states.site?.titleTemplate as unknown) || DEFAULT_TITLE_TEMPLATE;
      if (!template || typeof template !== 'string' || !/{\S*}/.test(template)) {
        return;
      }

      const [, defaultTitle] = template.match(/{([^$]*)}/) || [];
      const newly = typeof title === 'string' && !isEmpty(title) ? title : defaultTitle || '';
      titleSetter.value = template.replace(/{.*}/, newly);
    };

    const confSite = async () => {
      try {
        const infos = await getFeaturesByDomain();
        const truly = isArray(infos) ? findAndEnsureSite(infos) : infos;
        if (truly) {
          states.site = {
            ...states.site,
            ...pick(truly, ['logoPure', 'logoText', 'titleTemplate']),
          };
        }
      } catch (error) {
        console.error(error);
      } finally {
        setFavicon();
      }
    };

    const reload = async () => {
      let infos: VoProfiles;

      try {
        infos = await getProfiles();
      } catch (error) {
        clear();
        if (isXomError(error)) {
          throw error;
        }
        throw new XomError({ code: 'err_profile_load' });
      }

      if (!infos.id || !infos.site?.id || !infos.org?.id || !infos.account || !infos.type) {
        clear();
        throw new XomError({ code: 'err_invalid_profile' });
      }
      if (infos.type === 'NORMAL' && !infos.roles?.length) {
        clear();
        throw new XomError({ code: 'err_invalid_role' });
      }
      if (!infos.status || infos.status !== 'ENABLE') {
        clear();
        throw new XomError({ code: 'err_profile_locked' });
      }

      const omitKeys: (keyof VoSiteProfile)[] = ['logoPure', 'logoText'];
      const site = omit(infos.site, omitKeys);
      Object.assign(states, { ...infos, site, bornAppId: infos.bornAppId || null });
      setFavicon();
    };

    const toggleSite = async (site: BaseRelates) => {
      try {
        await patchMySite(site.id);
      } catch (error) {
        throw new XomError(error as AxiosError);
      }

      // TODO regenerate authed routes
      await reload();

      permits.clear();
      await permits.reload();
    };

    const validAsset = (url: string) => {
      return Array.isArray(states.assetWhitelist) && states.assetWhitelist.includes(url);
    };

    const clear = () => {
      localStorage.removeItem(STORE_PROFILE);
      // this.$reset();
    };

    return {
      ...toRefs(states),
      authed,
      enabled,
      admins,
      root,
      nickname,
      setFavicon,
      setTitle,
      confSite,
      reload,
      toggleSite,
      validAsset,
      clear,
    };
  },
  {
    persist: {
      key: STORE_PROFILE,
    },
  },
);

export function findAndEnsureSite<T extends VoSiteProfile>(infos: T[]) {
  const isTruly = (info: T) => {
    return info.id && info.name && info.cmsDomain && info.cmsDomain.includes(location.host);
  };
  return infos.find(isTruly);
}
