import { useTokenStore } from '@/storage/tokens';
import { useProfileStore } from '@/storage/profile';

/**
 * 将用户令牌、用户配置信息等信息通过固定规则附加到 URL 链接上
 *
 * @param pattern - 匹配规则
 */
export function parseStatefulLink(pattern: string) {
  const tokens = useTokenStore();
  const profile = useProfileStore();

  return pattern.replace(/<((tokens|profile)(\.\w+)+)>/g, (_, paths_: string) => {
    if (!paths_) {
      return '';
    }

    const [from_, ...subs_] = paths_.split('.');
    const __ = resolvePaths({ tokens, profile }[from_], subs_);
    return __ ? encodeURIComponent(__) : '';
  });
}

function resolvePaths(obj: unknown, paths: string[]): string | undefined {
  if (!isTrulyObj(obj)) {
    return obj ? obj.toString() : undefined;
  }

  if (!paths.length) {
    return obj.toString();
  }

  const [first, ...rest] = paths;
  return first in obj ? resolvePaths(obj[first], rest) : undefined;
}

function isTrulyObj(obj: unknown): obj is Record<string, unknown> {
  return !!obj && typeof obj === 'object';
}
