import type { DateEasyOpt } from '@hlx/uis';
import { markRaw } from 'vue';
import dayjs from 'dayjs';
import type { VoNotifyDetail } from '@/apis/notify/typings';
import { useProfileStore } from '@/storage/profile';
import { useConfirm } from '@ome/bases';
import { XUsePreview } from '@hlx/uis';

export const timePresets = markRaw<DateEasyOpt[]>([
  { label: '过去24小时', value: dayjs().add(-1, 'd') },
  { label: '过去7天', value: dayjs().add(-7, 'd') },
  { label: '过去30天', value: dayjs().add(-30, 'd') },
]);

export const timeRangePresets = markRaw<DateEasyOpt[]>([
  { label: '过去24小时', value: [dayjs().add(-1, 'd'), dayjs()] },
  { label: '7天', value: [dayjs().add(-7, 'd'), dayjs()] },
  { label: '30天', value: [dayjs().add(-30, 'd'), dayjs()] },
]);

const order = ['今天', '昨天', '近7天', '近30天', '更早'];
/** 把数组按今天，昨天，近七天等进行分组 */
export const groupByTime = (arr: any[], attr = 'publishTime') => {
  const todayStart = dayjs().startOf('day');

  return arr
    .reduce((acc, item) => {
      // 处理无效日期
      const itemDate = dayjs(Number(item[attr]));
      if (!itemDate.isValid()) return acc;

      // 计算时间差（精确到天）
      const diffDays = todayStart.diff(itemDate, 'day', true);

      // 智能判断时间段
      let title;
      if (itemDate.isAfter(todayStart)) {
        title = '今天';
      } else if (diffDays < 1) {
        // 处理跨日临界值
        title = '昨天';
      } else if (diffDays <= 7) {
        title = '近7天';
      } else if (diffDays <= 30) {
        title = '近30天';
      } else {
        title = '更早';
      }

      // 优化分组查找性能
      const targetGroup = acc.find((g: { title: string }) => g.title === title);
      targetGroup ? targetGroup.data.push(item) : acc.push({ title, data: [item] });

      return acc;
    }, [])
    .sort(
      (a: { title: string }, b: { title: string }) =>
        order.indexOf(a.title) - order.indexOf(b.title),
    );
};

export const mergeGroupByTitle = (target: any[], arr: any[]) => {
  // 合并所有分组
  const allGroups = [...target, ...arr];

  // 创建合并容器（使用Map提升性能）
  const groupMap = new Map();

  // 遍历合并逻辑
  for (const group of allGroups) {
    const existing = groupMap.get(group.title);

    if (existing) {
      // 合并数据并立即排序
      existing.data = [...existing.data, ...group.data].sort(
        (a, b) => dayjs(b.publishTime).valueOf() - dayjs(a.publishTime).valueOf(),
      );
    } else {
      // 深度拷贝避免污染原数据
      groupMap.set(group.title, {
        title: group.title,
        data: [...group.data].sort(
          (a, b) => dayjs(b.publishTime).valueOf() - dayjs(a.publishTime).valueOf(),
        ),
      });
    }
  }

  // 按标准顺序重组
  return order
    .map((title) => groupMap.get(title) || { title, data: [] })
    .filter((g) => g.data.length > 0); // 过滤空分组
};

export const confirmReadTool = (arr: VoNotifyDetail[], id: string) => {
  const target = arr.find((item) => item.eventUserMessageId === id);
  if (!target) return arr;
  target.confirm = true;
};

export const html2text = (html: string) => {
  return htmlToPlaintext(html).replace(/&#10;|&#13;|&nbsp;/g, '');
};

/**
 * 将指定富文本字符串转换为纯文本字符串
 */
export function htmlToPlaintext(html: string, options?: { trim?: boolean }) {
  return html.replace(/(<[^>]*>)*([^<]*)(<\/[^>]*>)*/g, (_input, _start, text) =>
    // FIXME: Maybe should convert escape entries to characters
    options?.trim ? text.trim() : text,
  );
}

function isCurrentSite(site: BaseRelates) {
  const profile = useProfileStore();

  function getCurrentSite() {
    return profile.site
      ? {
          value: profile.site.id,
          label: profile.site.name + (profile.bornAppId === profile.site.id ? '(归属)' : ''),
        }
      : undefined;
  }
  const currentSite = getCurrentSite();
  return currentSite.value === site?.id;
}

/**
 * 将指定富文本字符串转换为纯文本字符串
 */
export async function handleHtmlClick(event: Event, site: BaseRelates) {
  event.preventDefault();

  // 关键：通过 closest 查找最近的 <a> 标签
  const link = (event.target as any).closest('a');
  if (!link || !site) {
    console.error(site ? '未知链接' : '未查询到站点');
    return;
  }

  // 获取链接地址
  const href = link.getAttribute('href');

  // 执行自定义操作（如弹窗确认）
  if (isCurrentSite(site)) {
    window.location.href = href;
  } else {
    const profile = useProfileStore();
    useConfirm('confirm', {
      title: '切换站点',
      width: 'sm',
      content: `是否前往 ${site.name} 站点处理该消息？`,
      onOk: async () => {
        await profile.toggleSite(site);
        window.location.href = href;
      },
    });
  }
}

/** 获取html中所有img标签的url */
export function getHtmlImg(html: string) {
  if (!html) return;
  // 将HTML字符串转换为DOM元素
  const tempDiv = document.createElement('div');
  tempDiv.innerHTML = html;
  // 获取所有的img标签
  const imgElements = tempDiv.querySelectorAll('img');

  // 提取所有img标签的src属性
  const imgSrcs = [...imgElements].map((img) => img.src);

  return imgSrcs;
}

/** 处理html中所有img标签的url的点击事件 */
export async function handleHtmlImg(event: Event, html: string) {
  // 关键：通过 closest 查找最近的 <a> 标签
  const img = (event.target as any).closest('img');
  if (!img || !html) {
    return;
  }

  const urls = getHtmlImg(html);

  // 获取链接地址
  const href = img.getAttribute('src');
  XUsePreview({
    dataSource: urls,
    start: urls.indexOf(href),
  });
}
