import type { Rule } from 'ant-design-vue/es/form';
import {
  isCamelCase,
  isEmail,
  isEmpty,
  isKebabCase,
  isMacroCase,
  isMobile,
  isPascalCase,
  isPassword,
} from '@hlx/use';
import { validatePasswordChar } from '@ome/bases';

export type FormValidFn = keyof typeof FORM_VALID_RE | null;

export const FORM_VALID_RE = {
  camel: { fn: isCamelCase, msg: `首字母小写的驼峰型字母字符串` },
  pascal: { fn: isPascalCase, msg: `首字母大写的驼峰型字母字符串` },
  kebab: { fn: isKebabCase, msg: `"-"字符连接的小写字符串` },
  macro: { fn: isMacroCase, msg: `"_"字符连接的大写字符串` },
  url: { fn: isUrl, msg: `HTTP、HTTPS等网络地址` },
  domain: { fn: isDomain, msg: `域名，不含协议前缀和路径/端口后缀` },
  domainUrl: { fn: isDomainUrl, msg: `HTTP、HTTPS等非IP+端口的网络地址` },
  path: { fn: isPath, msg: `以"/"开头的路径地址` },
  mobile: { fn: isMobile, msg: `无效的手机号码` },
  password: { fn: isPassword, msg: `8-16位数字+字母+字符组合（!#$%&*,.?@）` },
  email: { fn: isEmail, msg: `无效的邮箱地址` },
};

export function isPath(value: string) {
  return /^(\/[\w-]+\/?)+$/.test(value);
}

export function isUrl(value: string) {
  return /^((https?:)?(\/\/))(\S+)$/.test(value);
}

export function isDomain(value: string) {
  return /^([\dA-Za-z]+\.)+(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[A-Za-z]{2})$/.test(
    value,
  );
}

export function isDomainUrl(value: string) {
  if (!isUrl(value) || value.includes('localhost')) {
    return false;
  }
  return !/^((ht{2}ps?:)?\/{2})((?:\d+\.){3}\d+)(:\d+)?/.test(value);
}

export function useFormRule(
  type: FormValidFn,
  required?: boolean,
  trigger: Rule['trigger'] = 'change',
) {
  return {
    required,
    trigger,
    validator: (_: Rule, value: string | number) => {
      if (isEmpty(value)) {
        return required ? Promise.reject(`必须项，请输入`) : Promise.resolve();
      }

      const RE = type ? FORM_VALID_RE[type] : null;
      if (RE && !RE.fn?.(value.toString())) {
        return Promise.reject(RE.msg ?? `校验失败，请核对输入`);
      }
      if (type === 'password' && !validatePasswordChar(value.toString())) {
        return Promise.reject(`不能包含3个及以上键盘连续字符或连续3位及以上相同字母或数字`);
      }

      return Promise.resolve();
    },
  };
}
