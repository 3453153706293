<script setup lang="ts">
import { computed, reactive, ref } from 'vue';
import { useProfileStore } from '@/storage/profile';
import { usePermitStore } from '@/storage/permits';
import { A_MODAL_WIDTHS } from '@ome/bases';

defineOptions({ name: 'XomSiteSwitchModal' });

const open = defineModel<boolean>('open', { default: false });

type SiteOption = {
  label: string;
  value: string;
};

const profile = useProfileStore();
const permits = usePermitStore();

function getCurrentSite() {
  return profile.site
    ? {
        value: profile.site.id,
        label: profile.site.name + (profile.bornAppId === profile.site.id ? '(归属)' : ''),
      }
    : undefined;
}

const antSelectRef = ref<any>();

const states = reactive({
  current: undefined as SiteOption | undefined,
  togging: false,
});

const preselect = computed(
  () =>
    permits.sites?.reduce<{ label: string; value: string }[]>((prev, s: BaseRelates) => {
      let name = s.name;
      if (profile.bornAppId === s.id) {
        name += '(归属)';
      }
      return s.id === states.current?.value ? prev : [...prev, { label: name, value: s.id }];
    }, []) ?? [],
);
const permitSite = computed({
  get: () => states.current ?? getCurrentSite(),
  set: (val: SiteOption | undefined) => (states.current = val),
});

function filter(input: string, option: SiteOption) {
  return option.label.toLowerCase().includes(input.toLowerCase().trim());
}

function restore() {
  states.current = getCurrentSite();
}

async function toggleSite() {
  try {
    if (!states.current) {
      return;
    }

    states.togging = true;
    const { label: name, value: id } = states.current;
    await profile.toggleSite({ name, id });
    open.value = false;
    window.location.href = '/';
  } finally {
    states.togging = false;
  }
}
</script>

<template>
  <a-modal
    v-model:open="open"
    centered
    :width="A_MODAL_WIDTHS['xs']"
    :confirm-loading="states.togging"
    :ok-button-props="{ disabled: !states.current }"
    title="站点切换"
    @ok="toggleSite"
    @cancel="restore"
  >
    <a-select
      ref="antSelectRef"
      v-model:value="permitSite"
      :options="preselect"
      :filter-option="filter"
      :disabled="states.togging"
      show-search
      label-in-value
      style="width: 100%"
    >
      <template #suffixIcon>
        <search-outlined class="site-mark-search" />
      </template>
    </a-select>
  </a-modal>
</template>
