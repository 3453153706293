<script setup lang="ts">
import type { FormInstance } from 'ant-design-vue';
import type { Rule } from 'ant-design-vue/es/form';
import { reactive, ref, computed, createVNode } from 'vue';
import { rsaEncrypt, useConfirm, useModal, validatePasswordChar } from '@ome/bases';
import { isEmpty, isPassword } from '@hlx/use';
import { patchUserPassword } from '@/apis/profile';
import { reqRsaPublicKey } from '@/apis/service';
import { signout } from '@/utils/signin';
import result from '../xom-pwd-result/index.vue';

defineOptions({ name: 'XomPwdReset' });

const props = defineProps<{
  visible: boolean;
  isFirstTime: boolean;
  isShowTip: boolean;
  isHideClose?: boolean;
}>();
const emits = defineEmits<{ (evt: 'ok'): void; (evt: 'update:visible', val: boolean): void }>();

const antFormRef = ref<FormInstance>();
const states = reactive({
  submitting: false,
});
const echos = reactive({
  old: '',
  password: '',
  confirm: '',
});

const ownVisible = computed({
  get: () => props.visible,
  set: (val) => emits('update:visible', val),
});

const rules = reactive<Record<keyof typeof echos, Rule[]>>({
  old: [
    {
      required: true,
      trigger: ['blur', 'change'],
      validator: (_, val: unknown) => {
        if (typeof val !== 'string' || isEmpty(val)) {
          return Promise.reject(`请输入旧密码`);
        }
        return Promise.resolve();
      },
    },
  ],
  password: [
    {
      required: true,
      trigger: ['blur', 'change'],
      validator: (_, val: unknown) => {
        if (typeof val !== 'string' || isEmpty(val)) {
          return Promise.reject(`请输入新密码`);
        }
        if (!isPassword(val)) {
          return Promise.reject(`8-16位数字+字母+字符组合 (!#$%&*,.?@)`);
        }
        if (!validatePasswordChar(val.toString())) {
          return Promise.reject(`不能包含3个及以上键盘连续字符或连续3位及以上相同字母或数字`);
        }
        return Promise.resolve();
      },
    },
  ],
  confirm: [
    {
      required: true,
      trigger: ['blur', 'change'],
      validator: (_, val: unknown) => {
        if (typeof val !== 'string' || isEmpty(val)) {
          return Promise.reject(`请确认新密码`);
        }
        if (val !== echos.password) {
          return Promise.reject(`两次输入不一致，请重新检查`);
        }
        return Promise.resolve();
      },
    },
  ],
});

async function remindAgain() {
  useConfirm('success', {
    icon: null,
    title: undefined,
    content: createVNode(result, { value: echos.password, onSuccess: () => signout() }),
    bodyStyle: { textAlign: 'center' },
    class: 'pwd-reset-success-tip',
    okText: '好的，去重新登录!',
    okButtonProps: { type: 'primary' },
    closable: false,
    maskClosable: false,
    keyboard: false,
    footer: null,
    onOk: () => emits('ok'),
  });
}

async function submit() {
  try {
    states.submitting = true;
    await antFormRef.value?.validate();
    await patchUserPassword({
      oldPassword: await rsaEncrypt(echos.old, reqRsaPublicKey),
      clearPassword: await rsaEncrypt(echos.password, reqRsaPublicKey),
    });

    ownVisible.value = false;
    await remindAgain();
  } finally {
    states.submitting = false;
  }
}
</script>

<template>
  <a-modal
    v-model:open="ownVisible"
    :title="'重设密码'"
    :confirm-loading="states.submitting"
    :ok-button-props="{ disabled: states.submitting }"
    :cancel-button-props="{ disabled: states.submitting }"
    v-bind="
      useModal({
        width: 'xs',
        closable: false,
        // cancelText: props.isHideClose ? '' : props.isShowTip ? '等会儿再说...' : '取消',
      })
    "
    @ok="submit"
  >
    <template #footer>
      <a-button v-if="!isHideClose" @click="ownVisible = false">{{
        props.isShowTip ? '等会儿再说...' : '取消'
      }}</a-button
      ><a-button type="primary" @click="submit">确定</a-button>
    </template>
    <p v-if="props.isShowTip" class="text-center text-error">
      {{
        props.isFirstTime ? '您是首次(或密码重置后)登录' : '您的密码已过期'
      }}，为了您的账户安全，请修改密码！
    </p>

    <a-form ref="antFormRef" :model="echos" :rules="rules">
      <a-form-item label="旧密码" name="old">
        <a-input-password v-model:value="echos.old" placeholder="请输入旧密码" />
      </a-form-item>

      <a-form-item label="新密码" name="password">
        <a-input-password v-model:value="echos.password" placeholder="请输入新密码" />
      </a-form-item>

      <a-form-item label="请确认" name="confirm">
        <a-input-password v-model:value="echos.confirm" placeholder="请确认新密码" />
      </a-form-item>
    </a-form>
  </a-modal>
</template>
