<script setup lang="ts">
import type { ProLayoutEmits, ProLayoutProps, ProLayoutSlots } from '../interfaces';
import { computed, h } from 'vue';
import { default as locale } from 'ant-design-vue/es/locale/zh_CN';
import { BEM_ANT_NS, BEM_XOM_NS } from '@ome/bases';
import { bem } from '@hlx/use';
import { useTokensInjector, useLayoutStates, useLayoutStateProvider } from '../composables';
import { XomProMenuDrawer } from '../pro-menu';
import { XomProSettingDrawer } from '../pro-setting';
import { XomProTabs } from '../pro-tabs';
import XomProLayoutSider from './sider.vue';
import XomProLayoutHeader from './header.vue';
import XomProLayoutBody from './body.vue';
import XomProLayoutFooter from './footer.vue';

defineOptions({ name: 'XomProLayout', inheritAttrs: false });

const menuDrawerOpen = defineModel<boolean>('menuDrawerOpen');
const settingDrawerOpen = defineModel<boolean>('settingDrawerOpen');
const props = withDefaults(defineProps<ProLayoutProps>(), {
  antConfig: {
    locale,
    prefixCls: BEM_ANT_NS,
    componentSize: 'middle',
  } as any,
  cssToken: true,

  title: '华龙芯',
  copyright: false,
  icp: false,

  unsettable: false,
  storable: true,
  watermark: `华龙芯`,
  headerHeight: 52,
  tabsHeight: 46,
  footerHeight: 52,
  sideWidth: 240,
  sideCollapsedWidth: 68,
  bodyFixedWidth: 1280,
  menusAutoExternal: true,
  menusSplitRoot: 'header',
});
const emits = defineEmits<ProLayoutEmits>();
defineSlots<ProLayoutSlots>();

const ns = bem('pro-layout', BEM_XOM_NS);

const states = useLayoutStates(props, emits);

const { isDark, isMobile, page, effective, antConfigs, toggleSettings } = states;

const sideCollapsed = computed({
  get: () => effective.sideCollapsed,
  set: (val: boolean) => toggleSettings('sideCollapsed', val),
});

// const slotsProps = computed(() => ({
//   isDark: isDark.value,
//   isMobile: isMobile.value,
//   isPad: isPad.value,
//   isDesktop: isDesktop.value,
//   isDarkHead: isDarkHead.value,
//   isDarkSide: isDarkSide.value,
//   sideCollapsed: sideCollapsed.value,
//   page,
// }));

function removeSkeletons() {
  console.info('mounted hooks running');
}

useTokensInjector(props, isDark);

useLayoutStateProvider(props, states);
</script>

<template>
  <a-config-provider v-bind="antConfigs" @vue:mounted="removeSkeletons">
    <a-layout
      :class="[ns.b(), ns.m('root'), ns.is('fixed-header', effective.fixedHead)]"
      v-bind="$attrs"
    >
      <component
        :is="h(XomProLayoutSider, null, $slots)"
        v-if="effective.showSide"
        v-model:collapsed="sideCollapsed"
      />

      <a-layout :class="[ns.b(), ns.m('inner'), ns.is('fixed-header', effective.fixedHead)]">
        <component :is="h(XomProLayoutHeader, null, $slots)" v-if="effective.showHead" />

        <component :is="h(XomProTabs, null, $slots)" v-if="effective.showTabs" />

        <component :is="h(XomProLayoutBody, null, $slots)" />

        <component :is="h(XomProLayoutFooter, null, $slots)" v-if="effective.showFoot" />

        <!-- Note: Dont let the following component in root -->

        <component
          :is="h(XomProMenuDrawer, null, $slots)"
          v-if="isMobile && page === 'manage'"
          v-model:open="menuDrawerOpen"
        />

        <component
          :is="h(XomProSettingDrawer, null, $slots)"
          v-if="unsettable !== true && page === 'manage'"
          v-model:open="settingDrawerOpen"
          :before-close="beforeSettingsDrawerClose"
          :after-close="afterSettingsDrawerClosed"
        />
      </a-layout>
    </a-layout>
  </a-config-provider>
</template>

<style scoped lang="scss">
@use '@ome/bases/styles/vars';
@use '@hlx/spec/mixins';

@include mixins.b(pro-layout) {
  @include mixins.m(root) {
    height: 100%;
    overflow-y: auto;
  }

  @include mixins.m(inner) {
    // overflow-y: auto;

    // @include mixins.when(fixed-header) {
    //  overflow-y: inherit;
    // }
  }
}
</style>
