import { reactive } from 'vue';
import { useBreakpoints } from '@vueuse/core';

/**
 * 预设的响应式尺寸节点及其别名
 */
export const MEDIA_QUERY_BREAKPOINTS = {
  xl: 1600,
  lg: 1199,
  md: 991,
  sm: 767,
  xs: 575,
};

export function useQueryBreakpoints() {
  /**
   * 节点响应操作实例
   */
  const breakpoints = reactive(useBreakpoints(MEDIA_QUERY_BREAKPOINTS));

  /**
   * 移动端设备标记
   */
  const isMobile = breakpoints.smaller('sm');

  /**
   * 平板设备标记
   */
  const isPad = breakpoints.between('sm', 'md');

  /**
   * 桌面设备标记
   */
  const isDesktop = breakpoints.greater('md');

  /**
   * 大屏设备标记
   */
  const isLarge = breakpoints.greater('lg');

  return { breakpoints, isMobile, isPad, isDesktop, isLarge };
}
