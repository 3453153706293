<script setup lang="ts">
import type { ProRouteRaw, ProSettableOption } from '@ome/components';
import type { VoSystems } from '@/apis/permits';
import { XomProLayout } from '@ome/components';
import { markRaw, onBeforeMount, onBeforeUpdate, ref } from 'vue';
import { useTokenStore } from '@/storage/tokens';
import { useProfileStore } from '@/storage/profile';
import { usePermitStore } from '@/storage/permits';
import { useNavStore } from '@/storage/nav';
import { useRouter } from 'vue-router';
import { parseStatefulLink } from '@/utils';
import { IS_DEV, IS_PROD } from '@ome/bases';

defineOptions({ name: 'XomMainRoot' });

const router = useRouter();

const navs = useNavStore();
const tokens = useTokenStore();
const profile = useProfileStore();
const permits = usePermitStore();

const devModel = ref<VoSystems>();
const devModalOpen = ref(false);

const invariableSettings = markRaw<ProSettableOption>({
  showFoot: false,
  menusSplit: false,
  showWatermark: false,
});

if (IS_PROD) {
  Object.assign(invariableSettings, {
    // theme: 'light',
    // body: 'fluid',
    // compact: false,
    // adaptive: false,
    // fixedSide: true,
  });
}

/**
 * Make sure tokens authorized.
 */
async function ensureAuthorized() {
  if (tokens.authed) {
    return;
  }
  if (tokens.flushable) {
    return await tokens.flush();
  }
  await tokens.deauth({ originalUrl: location.origin });
}

async function rmvDevEntry(path: string) {
  permits.rmvDevEntry(path);
  await permits.reload();
  router.go(0);
}

function openDevModal(raw: ProRouteRaw) {
  const _raw = permits.systems.find((s_) => s_.activeRule === raw.path);
  if (!_raw) {
    return;
  }
  devModalOpen.value = true;
  const { key, activeRule, entry: path, label: name, type: systemType, devEntry } = _raw;
  devModel.value = { key, activeRule, path: devEntry || path, name, nodeName: name, systemType };
}

onBeforeMount(async () => {
  await ensureAuthorized();
  await profile.confSite();
});

onBeforeUpdate(ensureAuthorized);
</script>

<template>
  <x-config-provider :access="permits.features">
    <xom-pro-layout
      :title="profile.site?.name"
      :logo-pure="profile.site?.logoPure"
      :logo-text="profile.site?.logoText"
      :menus-data="navs.menus"
      :menus-path-formatter="parseStatefulLink"
      :default-setting="profile.preference"
      :unsettable="invariableSettings as any"
    >
      <template #header-after>
        <xom-head-actions />
      </template>

      <template #menu-title="menuRaw">
        {{ menuRaw.meta?.title }}
        <!--  Todo menuRaw.meta缺少type字段 -->
        <edit-outlined
          v-if="menuRaw.meta?.devEntry && IS_DEV"
          style="position: relative; z-index: 2; margin-left: 0.5em"
          @click.prevent.stop="() => openDevModal(menuRaw)"
        />

        <close-outlined
          v-if="menuRaw.meta?.devEntry && IS_DEV"
          style="position: relative; z-index: 2"
          @click.prevent.stop="() => rmvDevEntry(menuRaw.path)"
        />
      </template>

      <template #setting-drawer-footer>
        <xom-profile-saver />
      </template>

      <router-view class="xom-apps-main" />

      <xom-system-form-modal v-model:open="devModalOpen" :dto="devModel" type="develop" />
    </xom-pro-layout>
  </x-config-provider>
</template>
