import type { VoMicroServices } from './typings';
import { xhr } from '@/utils';
import { API_SERVICE_AUTH, API_SERVICE_SYS } from '@ome/bases';

export * from './typings';

export async function getMicroServices() {
  const { data } = await xhr.get<VoXObject<VoMicroServices[]>>(`${API_SERVICE_SYS}/service/list`);
  return data.data;
}

/**
 * 获取加密公钥
 */
export async function reqRsaPublicKey() {
  const { data } = await xhr.get<string>(`${API_SERVICE_AUTH}/rsa_public_key`, {
    withoutToken: true,
  });
  return data;
}
