<script setup lang="ts">
import type { ProActionsSlots, ProActionsProps } from '../interfaces';
import { h } from 'vue';
import { bem } from '@hlx/use';
import { BEM_XOM_NS } from '@ome/bases';
import XomProActionItem from './item.vue';

defineOptions({ name: 'XomProActions' });
defineProps<ProActionsProps>();
defineSlots<ProActionsSlots>();

const ns = bem('pro-actions', BEM_XOM_NS);
</script>

<template>
  <div :class="[ns.b()]">
    <slot>
      <template v-if="items?.length">
        <template v-for="(item, idx) of items" :key="idx">
          <component :is="h(XomProActionItem, item, $slots)" />
        </template>
      </template>
    </slot>
  </div>
</template>

<style scoped lang="scss">
@use '@ome/bases/styles/vars';
@use '@hlx/spec/mixins';

@include mixins.b(pro-actions) {
  display: flex;
  gap: var(--xom-margin-xs);
  align-items: center;
}
</style>
