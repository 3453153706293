import type { ProSettableOption, ProPageMotion, ProColorOption } from '../interfaces';

export const LOADABLE_IMG_ACCEPTS = ['svg', 'png', 'jpe?g', 'bmp', 'webp', 'ico'];

export const ROUTE_MOTIONS_PRESET: ProPageMotion[] = [
  'none',
  'xom-route-motion-slide-up',
  'xom-route-motion-slide-down',
  'xom-route-motion-slide-left',
  'xom-route-motion-slide-right',
  'xom-route-motion-zoom-in',
  'xom-route-motion-zoom-out',
  'xom-route-motion-fade',
];

export const THEME_COLOR_DEFAULT = '#1677ff';
export const THEME_COLORS_PRESET: ProColorOption[] = [
  { label: '玄青', value: '#374151' },
  { label: '天蓝', value: '#0ea5e9' },
  { label: '拂晓', value: '#1890ff' },
  { label: '科技(默认)', value: THEME_COLOR_DEFAULT },
  { label: '极客蓝', value: '#2f54eb' },
  { label: '酱紫', value: '#722ed1' },
  { label: '丁香', value: '#a855f7' },
  { label: '樱红', value: '#d946ef' },
  { label: '品红', value: '#eb2f96' },
  { label: '薄暮', value: '#f5222d' },
  { label: '火山', value: '#fa541c' },
  { label: '橘红', value: '#f97316' },
  { label: '日暮', value: '#faad14' },
  { label: '青柠', value: '#93c90c' },
  { label: '机关绿', value: '#4fbb15' },
  { label: '竹青', value: '#22c55e' },
  { label: '明青', value: '#13c2c2' },
];

export const SETTABLE_KEYS: (keyof ProSettableOption)[] = [
  'theme',
  'colorPrimary',
  'layout',
  'body',
  'adaptive',
  'compact',
  'colorInvert',
  'fixedHead',
  'fixedFoot',
  'fixedTabs',
  'fixedSide',
  'sideCollapsed',
  'fixedSideTrigger',
  'menusSplit',
  'menusAccordion',
  'keepAlive',
  'motion',
  'showWatermark',
  'showHead',
  'showFoot',
  'showTabs',
  'showSide',
  'colorGray',
  'colorWeak',
];

export const DEFAULT_SETTINGS: ProSettableOption = {
  theme: 'auto',
  colorPrimary: THEME_COLOR_DEFAULT,
  layout: 'side',
  body: 'fluid',
  adaptive: false,
  compact: false,
  colorInvert: true,
  fixedHead: true,
  fixedFoot: true,
  fixedTabs: true,
  fixedSide: true,
  sideCollapsed: false,
  fixedSideTrigger: false,
  menusSplit: false,
  menusAccordion: true,
  keepAlive: false,
  motion: 'xom-route-motion-fade',
  showWatermark: false,
  showHead: true,
  showFoot: true,
  showTabs: true,
  showSide: true,
  colorGray: false,
  colorWeak: false,
};

export const SELECTABLE_ITEM_OPTIONS = [
  { type: 'theme', value: 'light' as const, label: '亮色风格' },
  { type: 'theme', value: 'auto' as const, label: '跟随系统' },
  { type: 'theme', value: 'dark' as const, label: '暗色风格' },

  { type: 'layout', value: 'side' as const, label: '侧边栏布局模式' },
  { type: 'layout', value: 'top' as const, label: '顶栏布局模式' },
  { type: 'layout', value: 'mix' as const, label: '混合布局模式' },

  { type: 'motion', value: 'none', label: '无过渡' },
  { type: 'motion', value: 'xom-route-motion-slide-up', label: '底部进入' },
  { type: 'motion', value: 'xom-route-motion-slide-down', label: '顶部进入' },
  { type: 'motion', value: 'xom-route-motion-slide-left', label: '右侧进入' },
  { type: 'motion', value: 'xom-route-motion-slide-right', label: '左侧进入' },
  { type: 'motion', value: 'xom-route-motion-zoom-in', label: '放大' },
  { type: 'motion', value: 'xom-route-motion-zoom-out', label: '缩小' },
  { type: 'motion', value: 'xom-route-motion-fade', label: '淡入淡出' },
];
