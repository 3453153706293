<script setup lang="ts">
import type { ProFootSlots } from '../interfaces';
import { bem } from '@hlx/use';
import { BEM_XOM_NS } from '@ome/bases';
import { useLayoutState } from '../composables';
import XomLayoutCopyright from './copyright.vue';

defineOptions({ name: 'XomProLayoutFooter' });
defineSlots<ProFootSlots>();

const { isDark, effective, copyright, icp } = useLayoutState();
const ns = bem('layout-footer', BEM_XOM_NS);
</script>

<template>
  <a-layout-footer :class="[ns.b(), ns.is('dark', isDark), ns.is('fixed', effective.fixedFoot)]">
    <div :class="[ns.e('content')]">
      <slot name="footer"> layout footer here </slot>
    </div>

    <ul :class="ns.e('links')" />

    <div v-if="copyright !== false && icp !== false" :class="ns.e('copyright')">
      <slot name="copyright">
        <xom-layout-copyright />
      </slot>
      <slot name="icp" />
    </div>
  </a-layout-footer>
</template>

<style scoped lang="scss">
@use '@ome/bases/styles/vars';
@use '@hlx/spec/mixins';

@include mixins.b(layout-footer) {
  --height: calc(var(--xom-layout-footer-height) - var(--xom-padding-xs) * 2);

  padding: var(--xom-padding);
  background-color: transparent;

  @include mixins.e(links) {
    margin: 0;
    list-style-type: none;
  }

  @include mixins.e(content) {
    height: var(--height);
  }

  @include mixins.when(fixed) {
    display: flex;
    flex-flow: column;
    height: var(--xom-layout-footer-height);
    padding: var(--xom-padding-xs) var(--xom-padding);
    line-height: var(--height);
    background-color: var(--xom-color-bg-container);
    box-shadow: 0 -1px 4px rgba(0, 21, 41, 8%);
  }

  @include mixins.when(dark) {
    @include mixins.when(fixed) {
      box-shadow: 0 -3px 8px 0 rgba(13, 13, 13, 75%);
    }
  }
}
</style>
