import type { VoPermitProfile } from './typings';
import { API_SERVICE_SYS } from '@ome/bases';
import { xhr } from '@/utils';

/** 拉取用户权限列表 */
export async function getMyPermits() {
  // const config: AxiosRequestConfig = { tooltip: undefined };
  const { data: sites } = await xhr.get<BaseRelates[]>(`${API_SERVICE_SYS}/user/site`);
  const { data } = await xhr.get<VoPermitProfile>(`${API_SERVICE_SYS}/user/permits/v2`);
  return { ...data, sites };
}

/**
 * 用户当前站点切换
 *
 * @param appId - 目标站点ID
 */
export function patchMySite(appId: string): Promise<void> {
  return xhr.patch(`${API_SERVICE_SYS}/user/site/${appId}`);
}
