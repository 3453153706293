<script setup lang="ts">
import { useModal } from '@ome/bases';
import type { VoNotifyDetail } from '@/apis/notify/typings';
import { handleHtmlClick, parseDate } from '@/utils';

defineOptions({ name: 'XomMessageBusinessModal' });

const open = defineModel<boolean>('open', { default: false });
defineProps<{ msg: VoNotifyDetail }>();
</script>

<template>
  <a-modal v-model:open="open" v-bind="useModal({ width: 'xs', onOk: () => (open = false) })">
    <template #title>
      <p>{{ msg?.eventName ?? `消息通知` }}</p>
    </template>
    <p class="business-text-muted">
      <span v-if="msg.app?.name">[{{ msg.app.name }}]</span>
      {{ parseDate(msg.publishTime, true) }}
    </p>
    <div @click="(e) => handleHtmlClick(e, msg.app)" v-html="msg.eventContent" />
  </a-modal>
</template>

<style scoped lang="scss">
@use '@ome/bases/styles/vars';

.business-modal {
  display: flex;
  align-items: center;
  justify-content: space-between;
  p {
    margin: 0;
  }
}
.business-text-muted {
  color: var(--xom-color-text-quaternary);
}
</style>
