import type { NotifyEventType, NotifyType, QoNotify, VoNotifyDetail } from './typings';
import { xhr } from '@/utils';
import { API_NOTIFY, API_RETRIEVAL } from '@ome/bases';

/**
 * 查询未读通知列表
 */
export async function fetchUnreadList() {
  const { data } = await xhr.get<VoXList<VoNotifyDetail>>(
    `${API_NOTIFY}/eventUserMessage/unreadList`,
  );
  return data;
}

/**
 * 查询用户通知列表
 */
export async function fetchNotifyList(params: QoNotify) {
  const { data } = await xhr.get<VoXList<VoNotifyDetail>>(API_NOTIFY + '/eventUserMessage/list', {
    params,
  });
  return data;
}

/**
 * 查询业务类型
 */
export async function fetchEventType() {
  const { data } = await xhr.get<VoXObject<NotifyEventType[]>>(
    `${API_NOTIFY}/notify/eventTypeList`,
  );
  return data;
}

/**
 * 查询消息详情
 *
 * @param id - 站点ID
 */
export async function fetchNotifyDetail(id: string) {
  const { data } = await xhr.get<VoXObject<VoNotifyDetail>>(`${API_NOTIFY}/eventUserMessage/${id}`);
  return data;
}

/**
 * 确认消息
 *
 * @param id - 站点ID
 */
export async function confirmNotify(id: string) {
  const { data } = await xhr.post<number>(`${API_NOTIFY}/notify/${id}/confirm`);
  return data;
}

/**
 * 查询消息列表滚动加载
 */
export async function fetchNoticeScroll(params: QoNotify, type: NotifyType) {
  params.eventBusinessType = type;
  const { data } = await xhr.post<VoXList<VoNotifyDetail>>(
    API_RETRIEVAL + '/event_user_message/query',
    params,
  );
  return data;
}
