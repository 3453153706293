import type {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  CreateAxiosDefaults,
  InternalAxiosRequestConfig,
} from 'axios';
import { AxiosError, default as Axios } from 'axios';
import { TIPS_KEY_API_DISCONNECT } from '../const';
import { useMessage } from './antd';

export function createXhr(options: CreateAxiosDefaults = {}): AxiosInstance {
  const { tokenKey, getTokens, processor, statusMap, ...axiosOptions } = options;

  const Xhr = Axios.create({ withCredentials: false, ...axiosOptions });
  const _statusMap: Map<number, string> = new Map([
    [400, '接口请求参数错误，请联系系统管理员！'],
    [401, '您的登录已过期，请重新登录！'],
    [403, '拒绝访问，您没有相应权限！'],
    [404, '接口地址错误，请联系系统管理员！'],
    [405, '接口Method不受支持，请联系系统管理员！'],
    [406, '接口请求头异常，请联系系统管理员！'],
    [408, '接口请求超时，请稍后再试！'],
    [500, '服务器接口异常，请稍后再试！'],
    ...(statusMap ?? []),
  ]);

  /**
   * merge response message
   */
  const mergeMessages = (code: string, ...messages: (string | null | undefined)[]) => {
    for (const msg of messages) {
      if (typeof msg === 'string' && !/^\s*$/.test(msg)) {
        return `[${code ? code + ': ' : ''}]${msg}`;
      }
    }
    return null;
  };

  const getProcessor = (config: AxiosRequestConfig) => {
    const { processor: pro_ } = config;
    if (typeof pro_ === 'boolean') {
      return pro_ ? processor : null;
    }
    return pro_ || processor;
  };

  /**
   * show tooltip messages
   */
  const handleTips = (msg?: string | null, config?: AxiosRequestConfig) => {
    const _custom = config?.tooltip;

    if (!msg || _custom === false) {
      return;
    }

    if (typeof _custom === 'function') {
      return _custom(msg);
    }

    // TODO: 如果是共享给子系统的，应该统一针对子系统封装一个 unique key，不是所有子系统都会很自觉的自己传递 key
    const key =
      typeof _custom === 'number' || typeof _custom === 'string'
        ? _custom
        : config?.url || TIPS_KEY_API_DISCONNECT;

    return useMessage('error', { content: msg, key });
  };

  /**
   * ensure tokens value
   */
  const ensureToken = async (config: InternalAxiosRequestConfig, error?: AxiosError) => {
    const headerKey = tokenKey ?? 'Authorization';
    const { sysAccessToken, sysRefreshToken } = getTokens?.() || {};

    if (sysAccessToken && !error) {
      Object.assign(config.headers, { [headerKey]: sysAccessToken });
      return;
    }

    config._refreshed = true;
    if (typeof config.refreshToken === 'function' && sysRefreshToken) {
      const { sysAccessToken } = (await config.refreshToken()) ?? {};
      if (sysAccessToken) {
        Object.assign(config.headers, { [headerKey]: sysAccessToken });
        return;
      }
    }

    throw (
      error || new AxiosError(`can't parse or flush access token, check config pls.`, '400', config)
    );
  };

  /**
   * request fulfill interceptor
   */
  const onReqsReady = async (config: InternalAxiosRequestConfig) => {
    getProcessor(config)?.start();

    // insure tokens
    if (!config.withoutToken) {
      await ensureToken(config);
    }

    return config;
  };

  /**
   * request reject interceptor
   */
  const onReqsReject = (error: AxiosError) => {
    getProcessor(error.config)?.done(true);
    return error;
  };

  /**
   * response fulfill interceptor
   */
  const onRespFulfill = (resp: AxiosResponse) => {
    if (resp.config.withoutXStandard) {
      getProcessor(resp.config)?.done();
      return resp;
    }

    let respCode: string = '';
    if (resp.status >= 200 && resp.status < 400) {
      respCode = resp.data.code?.toString() || resp.data.status?.toString() || '';

      if (!respCode || !/^\d+$/.test(respCode) || respCode === '200' || respCode === '00000') {
        getProcessor(resp.config)?.done();
        return resp;
      }
    }

    const { baseURL = '', url = '' } = resp.config;
    const { msg, message } = resp.data;
    const mergedMsg =
      mergeMessages(respCode, msg, message) ??
      `There is an unknown error inside the api "${
        baseURL + url.replace(/^\/?/, '/')
      }" you accessed...`;
    handleTips(mergedMsg, resp.config);
    return Promise.reject(mergedMsg);
  };

  /**
   * response reject interceptor
   */
  const onRespReject = async (error: AxiosError) => {
    getProcessor(error.config)?.done(true);

    const { response, config } = error;
    if (!response) {
      if (!config.skipError) {
        handleTips('无法连接服务器！');
      }

      throw error;
    }

    const { status } = response;
    const { autoRefreshToken } = getTokens?.() || {};
    if (status === 401) {
      if (autoRefreshToken) {
        if (typeof config.refreshToken === 'function') config.refreshToken?.();
      } else if (!config._refreshed) {
        await ensureToken(config, error);
        return Xhr(config);
      }
    } else {
      const dataMsg = (response.data as any).message;
      if (!config.skipError) {
        handleTips(
          /[\u4E00-\u9FA5]+/g.test(dataMsg)
            ? dataMsg
            : _statusMap.get(status) || _statusMap.get(500),
          config,
        );
      }
      throw error;
    }
  };

  Xhr.interceptors.request.use(onReqsReady, onReqsReject);
  Xhr.interceptors.response.use(onRespFulfill, onRespReject);

  Object.defineProperty(Xhr, 'statusMap', { value: _statusMap, writable: false });
  Object.defineProperty(Xhr, 'baseURL', { value: axiosOptions.baseURL, writable: false });

  return Xhr;
}
