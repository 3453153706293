<script setup lang="ts">
import type { ProMenuProps, ProMenuSlots } from '../interfaces';
import { computed, h, provide, ref } from 'vue';
import { isArray, pick } from 'radash';
import { bem } from '@hlx/use';
import { BEM_XOM_NS } from '@ome/bases';
import { useLayoutState } from '../composables';
import { PRO_MENUS_CTX_TOKEN } from './context';
import XomProMenuItem from './item.vue';
import { toReactive } from '@vueuse/core';

defineOptions({ name: 'XomProMenu' });

const props = withDefaults(defineProps<ProMenuProps>(), {
  direction: 'inline',
});

defineSlots<ProMenuSlots>();

const ns = bem('pro-menu', BEM_XOM_NS);

const { isDark, activePaths, effective } = useLayoutState();

const _openedStaging = ref([]);

const theme = computed(() => props.theme || (isDark.value ? 'dark' : 'light'));

const isCollapsed = computed(() => {
  if (props.direction !== 'inline') {
    return;
  }
  return !!props.collapsed;
});

const _selectedMenus = computed(() =>
  isArray(props.selectedMenus) ? props.selectedMenus : activePaths.value,
);

const _openMenus = computed(() =>
  props.direction === 'inline' && effective.menusAccordion ? _openedStaging.value : undefined,
);

const ctxStates = computed(() => ({
  // Note: 气泡中的子菜单主题仅遵循 isDark 配置，侧边栏/顶栏颜色反转时，不应该反转气泡中显示的子菜单
  submenuTheme: isDark.value ? ('dark' as const) : ('light' as const),
  ...pick(props, [
    'direction',
    'popupClass',
    'ignoreChildren',
    'menusPathFormatter',
    'externalIcon',
    'hideExternalIcon',
  ]),
}));

function stagingAccordionMenus(keys: (string | number)[]) {
  if (props.direction !== 'inline' || !effective.menusAccordion) {
    return;
  }
  _openedStaging.value = keys.slice(-1);
}

provide(PRO_MENUS_CTX_TOKEN, toReactive(ctxStates));
</script>

<template>
  <a-menu
    :selected-keys="_selectedMenus"
    :open-keys="_openMenus"
    :class="[ns.b(), ns.when(theme), ns.when(direction || 'inline')]"
    :mode="direction"
    :theme="theme"
    :collapsed="isCollapsed"
    @update:open-keys="stagingAccordionMenus"
  >
    <template v-for="(item, idx) in items" :key="item.path || idx">
      <slot v-if="!item.meta?.hideInMenu" name="menu-item" v-bind="item">
        <component :is="h(XomProMenuItem, item, $slots)" />
      </slot>
    </template>
  </a-menu>
</template>

<style scoped lang="scss">
@use '@ome/bases/styles/vars';
@use '@hlx/spec/mixins';

@include mixins.b(pro-menu) {
  background-color: transparent;

  @include mixins.when(inline) {
    border-inline-end: 0 none;
  }

  @include mixins.when(horizontal) {
    :deep(.#{vars.$ant-prefix}-menu-item-selected) {
      text-shadow: 0 0 10px color(from var(--xom-color-primary) srgb r g b/60%);
    }
  }

  :deep(.#{vars.$ant-prefix}-menu-sub.#{vars.$ant-prefix}-menu-inline) {
    background-color: var(--xom-color-bg-container);
  }
  :deep(.#{vars.$ant-prefix}-menu-sub > .#{vars.$ant-prefix}-menu) {
    background-color: var(--xom-color-bg-container);
  }
  :deep(.external-marker) {
    margin-left: 1em;
    font-size: 0.85em;
    color: var(--xom-color-text-secondary);
  }
}
</style>
