import { createRouter, createWebHistory } from 'vue-router';
import { deprecated, exception } from './bases';
import { useRouterGuard, useAfterRouterGuard } from './guard';
import { asyncRoutes } from './modules';
import ProgressBar from '@/components/xom-progress-bar';

const dynamics: (() => void)[] = [];

const router = createRouter({
  strict: true,
  linkActiveClass: 'is-active',
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [exception, ...asyncRoutes, deprecated],
});

router.beforeEach((to, from) => {
  ProgressBar.start();
  return useRouterGuard(router, to, from, dynamics);
});
router.afterEach((to) => {
  ProgressBar.done(true);
  useAfterRouterGuard(to);
});

export { router };
