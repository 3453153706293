<script setup lang="ts">
import type { ProActionItemProps, ProActionsSlots, ProRouteRaw } from '../interfaces';
import { h } from 'vue';
import XomProActionInner from './inner-item.vue';
import XomProMenu from '../pro-menu/menu.vue';
import { useLayoutState } from '../composables';

defineOptions({ name: 'XomProActionItem', inheritAttrs: false });

const props = defineProps<ProActionItemProps>();
const emits = defineEmits<{
  (name: 'click', evt: MouseEvent): void;
  (name: 'click-menu', menu: { item: ProRouteRaw; key?: string }): void;
}>();
defineSlots<ProActionsSlots>();

const { activePaths } = useLayoutState();

function clickHook(evt: MouseEvent) {
  if (!props.disabled) {
    emits('click', evt);
  }
}
</script>

<template>
  <a-dropdown
    v-if="$slots.dropdown"
    :trigger="dropdownTrigger"
    :disabled="disabled"
    :overlay-style="{ backgroundColor: 'var(--xom-color-bg-container)' }"
  >
    <component :is="h(XomProActionInner, $props, $slots)" @click="clickHook" />

    <template #overlay>
      <slot name="dropdown" v-bind="$props" />
    </template>
  </a-dropdown>

  <a-dropdown
    v-else-if="dropdownMenus?.length"
    :trigger="dropdownTrigger"
    :disabled="disabled"
    :overlay-style="{ backgroundColor: 'var(--xom-color-bg-container)' }"
  >
    <component :is="h(XomProActionInner, $props, $slots)" @click="clickHook" />

    <template #overlay>
      <xom-pro-menu
        :items="dropdownMenus"
        :selected-menus="activePaths"
        @click="(info: any) => $emit('click-menu', info)"
      />
    </template>
  </a-dropdown>

  <component :is="h(XomProActionInner, $props, $slots)" v-else @click="clickHook" />
</template>
