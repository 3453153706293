<script setup lang="ts">
import type { ProBodySlots, ProWatermarkOptions } from '../interfaces';
import { useLayoutState } from '../composables';
import { bem } from '@hlx/use';
import { BEM_XOM_NS } from '@ome/bases';
import { computed } from 'vue';
import XomLayoutCopyright from './copyright.vue';

defineOptions({ name: 'XomProLayoutBody' });
defineSlots<ProBodySlots>();

const ns = bem('layout-body', BEM_XOM_NS);
const { effective, watermark, title, copyright, icp } = useLayoutState();

const watermark_ = computed<ProWatermarkOptions>(() => {
  const _ = watermark.value;
  if (!_) {
    return;
  }
  return typeof _ === 'object' ? _ : { content: typeof _ === 'string' ? _ : title.value };
});

const isFixedBody = computed(() => effective.body === 'fixed' && effective.layout !== 'mix');
</script>

<template>
  <a-layout-content :class="[ns.b(), ns.is('fixed', isFixedBody)]">
    <div :class="ns.e('content')">
      <a-watermark
        v-if="effective.showWatermark && watermark_"
        :class="ns.e('watermark')"
        v-bind="watermark_"
      >
        <slot />
      </a-watermark>

      <slot v-else />

      <template v-if="effective.fixedFoot && copyright !== false && icp !== false">
        <slot name="copyright">
          <xom-layout-copyright />
        </slot>
        <slot name="icp" />
      </template>
    </div>
  </a-layout-content>
</template>

<style scoped lang="scss">
@use '@ome/bases/styles/vars';
@use '@hlx/spec/mixins';

@include mixins.b(layout-body) {
  display: flex;
  flex-direction: column;
  align-items: center;

  @include mixins.e(watermark) {
    display: flex;
    flex: auto;
    flex-flow: column;
    height: 100%;
    min-height: 0;
  }

  @include mixins.e('content') {
    flex: 1 1 auto;
    width: 100%;
    min-height: 0;
  }

  @include mixins.when(fixed) {
    @include mixins.e('content') {
      max-width: var(--xom-layout-body-fixed-width);
    }
  }
}
</style>
