import type { VoFilthyProfiles, VoProfiles } from './typings';
import { pick } from 'radash';

export function cleanupFilthyProfiles(vo: VoFilthyProfiles): VoProfiles {
  const { assetWhite: assetWhitelist, configVoV2, userDetailVoV2 } = vo;

  const site = pick(configVoV2, [
    'id',
    'name',
    'nickName',
    'cmsDomain',
    'titleTemplate',
    'logoPure',
    'logoText',

    // site features
    'im',
    'vms',
    'app',
    'sign',
    'keywordLib',
    'syncChannelProperties',
    'isMediaPlayback',
    'scriptStatusCache',
    'figureCaption',
  ]);

  const member = pick(userDetailVoV2, [
    'id',
    'telephone',
    'email',
    'status',
    'name',
    'avatar',
    'defaultPwd',
    'description',
    'passwordResetType',
    'bornAppId',
  ]);

  const org = userDetailVoV2.org ? pick(userDetailVoV2.org, ['id', 'name']) : null;
  const roles =
    userDetailVoV2.roles
      ?.map((r_) => {
        return r_ && typeof r_ === 'object' && 'id' in r_ ? pick(r_, ['id', 'name']) : null;
      })
      .filter((r_) => !!r_) || [];

  let preference: Record<string, any> = null;
  try {
    if (vo.preference?.preferenceValue) {
      preference = JSON.parse(vo.preference.preferenceValue);
    }
  } catch {
    preference = {};
  }

  return {
    ...member,
    account: userDetailVoV2.loginName,
    type: ['NORMAL', 'SUPER', 'SYSTEM'].includes(userDetailVoV2.type) ? userDetailVoV2.type : null,
    gender: userDetailVoV2.userGenderType || 'MALE',
    org,
    roles,
    site,
    preference,
    assetWhitelist,
  };
}
