/**
 * 默认的异常提示消息
 */
export const XOM_ERRORS_DEFAULT_MSG = '页面走丢了，请等待一小会刷新重试！';

/**
 * 每个错误代码对应的异常提示消息
 */
export const XOM_ERRORS_MSG_MAP = new Map<XomErrorCode, string>([
  ['err_econnaborted', '网络/接口请求被中断！'],
  ['err_etimedout', '网络通信超时！'],
  ['err_network', '网络链接异常！'],

  ['err_bad_request', '和服务器通信出错了，请尝试刷新页面重试！'],
  ['err_unauthorized', '您的登录已过期，请重新登录！'],
  ['err_forbidden', '您没有此页面的访问权限，请联系管理员！'],
  ['err_not_found', XOM_ERRORS_DEFAULT_MSG],
  ['err_internal_server', '服务器内部错误，请联系管理员！'],
  ['err_not_implemented', '请求不受支持，请联系管理员！'],
  ['err_bad_gateway', '接收到一个无效响应，请联系管理员！'],
  ['err_service_unavailable', '服务器失去响应，请等待一小会后刷新重试！'],
  ['err_gateway_timeout', '链接超时，请等待一小会后刷新重试！'],

  // 用户、权限等配置数据层面
  ['err_invalid_token', '您的登录授权令牌无效，请联系管理员！'],
  ['err_profile_load', '无法加载用户、权限、站点等信息，服务器似乎出现了某些问题，请联系管理员！'],
  ['err_profile_locked', '您的账号已被锁定，请联系管理员！'],
  ['err_invalid_profile', '您的账号信息异常，请联系管理员！'],
  ['err_invalid_role', '您的账号角色异常，请联系管理员！'],
  ['err_empty_permission', '您的账号没有授予任何权限，请联系管理员！'],
  ['err_invalid_permission', '您的的账号权限配置异常，请联系管理员！'],

  // 子系统加载、路由调度层面
  ['err_csrss_load', '子系统内部错误，加载失败！'],
  ['err_csrss_removed', '该页面已移除，若您从收藏夹进入，请更新收藏夹链接！'],

  // 其它未定义错误码的异常
  ['err_unknown', '未知异常，请联系管理员！'],
]);

export const XOM_HTTP_CODE_MAPS = new Map<string, XomErrorCode>([
  ['400', 'err_bad_request'],
  ['401', 'err_unauthorized'],
  ['403', 'err_forbidden'],
  ['404', 'err_not_found'],
  ['500', 'err_internal_server'],
  ['501', 'err_not_implemented'],
  ['502', 'err_bad_gateway'],
  ['503', 'err_service_unavailable'],
  ['504', 'err_gateway_timeout'],
]);
