<script setup lang="ts">
import type { ProSideSlots } from '../interfaces';
import { computed, h } from 'vue';
import { bem } from '@hlx/use';
import { BEM_XOM_NS } from '@ome/bases';
import { useLayoutState } from '../composables';
import { XomProBrand } from '../pro-brand';
import { XomProMenu } from '../pro-menu';

defineOptions({ name: 'XomProLayoutSider' });

const collapsed = defineModel<boolean>('collapsed');
defineSlots<ProSideSlots>();

const {
  isMobile,
  isDarkSide,
  sideWidth,
  sideCollapsedWidth,
  menusSplitRoot,
  rootMenus,
  restMenus,
  effective,
  menusPathFormatter,
} = useLayoutState();

const ns = bem('layout-sider', BEM_XOM_NS);
const theme = computed(() => {
  return isDarkSide.value ? 'dark' : 'light';
});
const withoutBrand = computed(
  () =>
    isMobile.value ||
    effective.layout === 'side' ||
    (effective.layout === 'mix' && effective.showHead === false),
);
const klass = computed(() => {
  return withoutBrand.value ? [ns.when('without-brand')] : [];
});
const isFloat = computed(() => !!isMobile.value || !!effective.fixedSideTrigger);
</script>

<template>
  <div
    v-if="effective.fixedSide"
    :class="[ns.b(), ns.when('placeholder-only'), ns.is('collapsed', collapsed), ...klass]"
  />

  <a-layout-sider
    v-model:collapsed="collapsed"
    :class="[
      ns.b(),
      ns.m(`layout-${effective.layout}`),
      ns.is('fixed', effective.fixedSide),
      ns.when(theme),
      ...klass,
    ]"
    :theme="theme"
    :trigger="null"
    :width="sideWidth"
    :collapsed-width="sideCollapsedWidth"
    collapsible
  >
    <component :is="h(XomProBrand, null, $slots)" v-if="effective.layout === 'side'" />

    <slot name="sider-top" />

    <x-scrollbar :wrap-class="ns.e('content')">
      <component
        :is="h(XomProMenu, null, $slots)"
        v-if="menusSplitRoot === 'sider' || effective.layout !== 'top'"
        :class="ns.e('menus')"
        :direction="'inline'"
        :menus-path-formatter="menusPathFormatter"
        :items="menusSplitRoot === 'sider' ? rootMenus : restMenus"
        :theme="theme"
        :ignore-children="menusSplitRoot === 'sider'"
        :collapsed="collapsed"
      />
    </x-scrollbar>

    <slot name="sider-bottom" />

    <slot name="sider-trigger" :float="isFloat">
      <div :class="[ns.e('trigger'), ns.is('float', isFloat)]">
        <a-divider v-if="!isFloat" />

        <a-button v-if="isFloat" shape="circle" @click="collapsed = !collapsed">
          <template #icon>
            <right-outlined v-if="collapsed" />
            <left-outlined v-else />
          </template>
        </a-button>

        <a-button v-else type="text" size="large" @click="collapsed = !collapsed">
          <template #icon>
            <menu-unfold-outlined v-if="collapsed" />
            <menu-fold-outlined v-else />
          </template>
        </a-button>
      </div>
    </slot>
  </a-layout-sider>
</template>

<style scoped lang="scss">
@use '@ome/bases/styles/vars';
@use '@hlx/spec/mixins';

@include mixins.b(layout-sider) {
  padding-top: calc(var(--xom-layout-header-height) + 1px);
  background-color: var(--xom-color-bg-container);
  border-inline-end: solid 1px var(--xom-color-border-secondary);
  transition:
    // background-color var(--xom-motion-duration-slow) ease 0s,
    background-color 0s,
    min-width var(--xom-motion-duration-slow) ease 0s,
    max-width var(--xom-motion-duration-slow) cubic-bezier(0.645, 0.045, 0.355, 1) 0s;

  :deep(.#{vars.$ant-prefix}-layout-sider-children) {
    display: flex;
    flex-direction: column;
  }

  @include mixins.e(content) {
    flex: auto;
    min-height: 0;
    overflow: hidden;
  }

  @include mixins.e(trigger) {
    /* position: absolute;
    bottom: 0;
    left: 0;
    width: 100%; */
    padding: 0 var(--xom-padding-xxs) calc(var(--xom-padding-xxs) + 2px);

    :deep(.#{vars.$ant-prefix}-btn) {
      width: 100%;
    }

    :deep(.#{vars.$ant-prefix}-divider) {
      margin-top: 0;
      margin-bottom: calc(var(--xom-padding-xxs) + 1px);
    }

    @include mixins.when(float) {
      padding: 0;
      text-align: right;

      :deep(.#{vars.$ant-prefix}-btn) {
        position: absolute;
        top: calc(var(--xom-layout-header-height) + 20px);
        right: -14px;
        width: auto;
        padding: 0;
        border-color: var(--xom-color-border);
        box-shadow: 0 0 8px 0 rgba(29, 35, 41, 15%);
      }
    }
  }

  @include mixins.when(placeholder-only) {
    width: var(--xom-layout-side-width);
    min-width: var(--xom-layout-side-width);
    max-width: var(--xom-layout-side-width);

    @include mixins.when(collapsed) {
      width: var(--xom-layout-side-collapsed-width);
      min-width: var(--xom-layout-side-collapsed-width);
      max-width: var(--xom-layout-side-collapsed-width);
    }
  }

  @include mixins.when(without-brand) {
    padding-top: 0;
  }

  @include mixins.when(fixed) {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 17;
    height: 100%;
    border-inline-end-color: transparent;
    box-shadow:
      1px 0 2px 0 rgba(0, 0, 0, 3%),
      1px 0 6px -1px rgba(0, 0, 0, 2%),
      2px 0 4px 0 rgba(0, 0, 0, 2%);
  }

  @include mixins.when(dark) {
    background-color: #161618;
    box-shadow: 0 2px 8px 0 rgba(13, 13, 13, 75%);

    @include mixins.e(trigger) {
      :deep(.#{vars.$ant-prefix}-btn) {
        color: var(--xom-color-text-secondary);
        background-color: var(--xom-color-bg-container);

        &:hover {
          color: var(--xom-color-text);
        }
      }

      :deep(.#{vars.$ant-prefix}-divider) {
        border-color: var(--xom-color-border-secondary);
      }
    }
  }
}
</style>
