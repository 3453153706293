import type { LocationQuery } from 'vue-router';
import type { XSdkSsoTokens } from '@hlx/sdk';
import { useRouter } from 'vue-router';
import { isAccessToken, isAutoRefresh, isSystemToken } from '@ome/bases';

/**
 * 校验目标路由路径是否是一个白名单路径
 *
 * @param path - 路径URL
 */
export function isWhitePath(path: string) {
  return /^\/(oauth|redirect)/i.test(path);
}

/**
 * 校验目标路由路径是否是一个异常页面路径
 *
 * @param path - 路径URL
 */
export function isExceptionPath(path: string) {
  return /^\/exception/i.test(path);
}

/**
 * 按参数依次连接路径
 *
 * @param paths - 路径数组
 */
export function joinPaths<P>(...paths: P[]) {
  if (!paths.length) {
    return '/';
  }
  return paths.reduce((prev, curr) => {
    if (typeof curr !== 'string' || /^\s*$/.test(curr)) {
      return prev;
    }
    return prev + curr.replace(/^\/?/, '/');
  }, '');
}

/**
 * 从路由地址的 Query 中解析 Token
 *
 * @param query - query 数据
 *
 * @returns 解析之后的 token 信息，数据类型为 Object，对应的各属性作用如下:
 *
 * - `has`: 指示 query 中包含 token 信息
 * - `valid`: 指示 token 是否有效
 * - `tokens`: 解析后的令牌信息
 * - `rest`: 除 token 外的其它附加信息
 */
export function parseQueriesToken(query: LocationQuery) {
  const { auth_access, auth_refresh, auto_refresh, auth_expires, auth_scope, ...rest } = query;
  const has = !!auth_access && !!auth_refresh;
  const valid = isAccessToken(auth_access) && isSystemToken(auth_refresh);

  const tokens: XSdkSsoTokens = {
    autoRefreshToken: isAutoRefresh(auto_refresh),
    sysAccessToken: auth_access?.toString() || '',
    sysRefreshToken: auth_refresh?.toString() || '',
    expiresIn: auth_expires ? Number(auth_expires) : 0,
    // scope: typeof auth_scope === 'string' ? auth_scope : '',
  };

  return { has, valid, tokens, rest };
}

/**
 * 登录页面跳转
 *
 * @param type - 跳转方式，替换当前页/登录页跳转
 */
export function gotoSignin(type: 'replace' | 'push' = 'replace') {
  const router = useRouter();
  if (!router) {
    window.location.href = '/oauth/signin';
    return;
  }
  const { query, params } = router.currentRoute.value;
  router[type]({ path: '/oauth/signin', query, params } as any);
}

/**
 * 异常页跳转
 *
 * @param code - 错误代码
 */
export function gotoException(code: XomErrorCode) {
  const router = useRouter();
  if (!router) {
    window.location.href = `/exception/${code}`;
    return;
  }
  router.push({ name: 'XomExceptionPage', params: { code } });
}
