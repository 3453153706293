import type { ProCtxStates, ProLayoutProps, ProSettingsStates } from '../interfaces';
import { toRefs } from 'vue';
import { createInjectionState, reactivePick } from '@vueuse/core';
import { useRouteStates } from './use-route-states';

function layoutStateGenerator(props: ProLayoutProps, states: ProSettingsStates): ProCtxStates {
  const consumersStates = reactivePick(
    props,
    'title',
    'logoPure',
    'logoText',
    'copyright',
    'icp',
    'socialLinks',
    'watermark',

    'colorPreset',
    'sideWidth',
    'sideCollapsedWidth',
    'menusSplitRoot',
    'headerHeight',
    'bodyFixedWidth',
    'footerHeight',
  );

  const menus = useRouteStates(props, states.router);
  return {
    ...toRefs(consumersStates),
    ...states,
    ...menus,
    menusPathFormatter: props.menusPathFormatter,
  };
}

const [useLayoutStateProvider, useLayoutStateInject] = createInjectionState(layoutStateGenerator, {
  injectionKey: 'XomLayoutContext',
});

const useLayoutState = () => useLayoutStateInject()!;

export { useLayoutStateProvider, useLayoutState };
