<script setup lang="ts">
import type { ProBrandProps, ProBrandSlots } from '../interfaces';
import { computed, type CSSProperties, ref } from 'vue';
import { bem } from '@hlx/use';
import { BEM_XOM_NS, IS_JYH_PROD, IS_ZJ_PROD } from '@ome/bases';
import { useLayoutState } from '../composables';
// import defaultLogoPure from '@ome/assets/logo/logo-hlw-pure.svg?raw';
import defaultHlxLogoPure from '@ome/assets/logo/logo-hlx-pure.png';
import defaultHlxLogoText from '@ome/assets/logo/logo-hlx-text.svg?raw';

import defaultZjLogoPure from '@ome/assets/logo/logo-zj-pure.png';
import defaultZjLogoText from '@ome/assets/logo/logo-zj-text.png';

import defaultJyhLogoPure from '@ome/assets/logo/logo-jyh-pure.png';
import defaultJyhLogoText from '@ome/assets/logo/logo-jyh-text.png';

defineOptions({ name: 'XomProBrand' });

const props = withDefaults(defineProps<ProBrandProps>(), {
  direction: 'horizontal',
  size: 'middle',
  homePath: '/',
});
defineSlots<ProBrandSlots>();

const { effective, logoPure, logoText } = useLayoutState();

let defaultLogoPure = defaultHlxLogoPure;
let defaultLogoText = defaultHlxLogoText;

if (IS_ZJ_PROD) {
  defaultLogoPure = defaultZjLogoPure;
  defaultLogoText = defaultZjLogoText;
} else if (IS_JYH_PROD) {
  defaultLogoPure = defaultJyhLogoPure;
  defaultLogoText = defaultJyhLogoText;
}

const ns = bem('pro-brand', BEM_XOM_NS);
const src = ref(props.logoPure || logoPure.value || defaultLogoPure);
const txt = ref(props.logoText || logoText.value || defaultLogoText);

const collapsed = computed(() => {
  if (props.direction !== 'horizontal') {
    return false;
  }
  return !!props.collapsed || (effective.layout === 'side' && effective.sideCollapsed);
});

const styles = computed<CSSProperties>(() => {
  if (collapsed.value) {
    return typeof props.collapsedWidth === 'number' ? { width: `${props.collapsedWidth}px` } : {};
  } else {
    return typeof props.horizontalWidth === 'number' ? { width: `${props.horizontalWidth}px` } : {};
  }
});

// function isSvgUrl(source: string) {
//   return source.endsWith('.svg');
// }

function isSvgSrc(source: unknown) {
  return typeof source === 'string' && source.startsWith('<svg');
}
</script>

<template>
  <component
    :is="homePath === false ? 'span' : 'a'"
    :class="[
      ns.b(),
      ns.when(size),
      ns.when(effective.layout),
      ns.when(direction),
      ns.is('collapsed', collapsed),
    ]"
    :style="styles"
    :href="typeof homePath === 'string' ? homePath || '/' : '/'"
  >
    <span :class="ns.e('image')">
      <slot name="brand-logo">
        <span
          v-if="isSvgSrc(src)"
          :innerHTML="src"
          style="display: block; width: 100%; height: 100%"
        />
        <img v-else :src="src" :alt="title" @error="src = defaultLogoPure" />
      </slot>
    </span>

    <transition name="brand-slide">
      <span v-show="!collapsed" :class="ns.e('title')">
        <slot name="brand-title">
          <span
            v-if="isSvgSrc(txt)"
            :innerHTML="txt"
            style="display: block; width: 100%; height: 100%"
          />
          <img v-else-if="txt" :src="txt" :alt="title" @error="txt = defaultLogoText" />
          <template v-else>{{ title }}</template>
        </slot>
      </span>
    </transition>
  </component>
</template>

<style scoped lang="scss">
@use 'sass:map';
@use '@ome/bases/styles/vars';
@use '@hlx/spec/mixins';

$hr-sizes: (
  small: calc(var(--xom-layout-header-height) - 8px),
  middle: var(--xom-layout-header-height),
  large: calc(var(--xom-layout-header-height) + 12px),
);
$vr-sizes: (
  small: 68px,
  middle: 74px,
  large: 86px,
);

.brand-slide {
  &-enter-active,
  &-leave-active {
    width: inherit;
    transition:
      opacity 0.3s,
      transform 0.3s;
  }

  &-enter-from,
  &-leave-to {
    width: 0;
    opacity: 0;
  }
}

@include mixins.b(pro-brand) {
  & {
    display: flex;
    gap: var(--xom-padding);
    align-items: center;
    justify-content: flex-start;
    width: var(--xom-layout-side-width);
    color: var(--xom-color-text-secondary);
    border-bottom: solid 1px transparent;
    transition: all var(--xom-motion-duration-slow);
  }

  &:hover {
    color: var(--xom-color-text);
  }

  @include mixins.e(image) {
    display: block;

    > img,
    > :deep(img) {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: contain;
      background: none;
      border: 0 none;
    }

    svg,
    :deep(svg) {
      width: 100%;
      height: 100%;
    }
  }

  @include mixins.e(title) {
    display: block;
    flex: auto;
    min-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 18px;
    font-weight: bolder;
    white-space: nowrap;

    > img,
    > :deep(img) {
      display: block;
      max-width: 100%;
      height: 100%;
      object-fit: contain;
      background: none;
      border: 0 none;
    }

    svg,
    :deep(svg) {
      height: 100%;
      fill: currentcolor;
    }
  }

  @include mixins.when(horizontal) {
    --height: #{map.get($hr-sizes, middle)};
    --padding-block: var(--xom-padding-xs);
    --padding-inline: var(--xom-padding-lg);
    --brand-size: calc(var(--height) - var(--padding-block) * 2);
    --padding-block-title: var(--xom-padding-xxs);
    --padding-inline-collapsed: calc(
      (var(--xom-layout-side-collapsed-width) - var(--brand-size)) / 2
    );

    height: calc(var(--height) + 1px);
    padding: var(--padding-block) var(--padding-inline);
    line-height: var(--brand-size);

    @include mixins.e(image) {
      width: var(--brand-size);
      min-width: var(--brand-size);
      max-width: var(--brand-size);
      height: var(--brand-size);
    }

    @include mixins.e(title) {
      height: var(--brand-size);
      padding-block: var(--padding-block-title);
      line-height: calc(var(--brand-size) - var(--padding-block-title) * 2);
    }

    @include mixins.when(side) {
      @include mixins.when(collapsed) {
        width: var(--xom-layout-side-collapsed-width);
        padding-inline: var(--padding-inline-collapsed);
      }
    }

    @each $k, $v in $hr-sizes {
      @include mixins.when($k) {
        --height: #{$v};
      }
    }
  }

  @include mixins.when(vertical) {
    --width: #{map.get($vr-sizes, middle)};
    flex-flow: column;
    gap: var(--xom-padding-xs);
    align-items: center;
    width: var(--width);

    @include mixins.e(image) {
      width: var(--width);
      min-width: var(--width);
      max-width: var(--width);
      height: var(--width);
    }

    @include mixins.e(title) {
      max-width: 100%;
    }

    @each $k, $v in $vr-sizes {
      @include mixins.when($k) {
        --width: #{$v};
      }
    }
  }
}
</style>
